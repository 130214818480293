import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import Footer from '../component/Footer';
export default class Cmsdevelopment extends Component {
    render() {
        return (
            <>

                {/* page-head start */}
                <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
                    {/* page-head-bg */}
                    <div className="page-head-bg web-dsgn-head" style={{ backgroundImage: 'url(assets/images/backgrounds/E-commercebanner.webp)' }} />
                    {/* flex-min-height-inner start */}
                    <div className="flex-min-height-inner">
                        {/* container start */}
                        <div className="container top-bottom-padding-120">
                            <h2 className="overlay-loading2 medium-title red-color">E-Commerce</h2>
                            <h3 className="large-title-bold text-color-4">
                                <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">DEVELOPMENT</span><br />
                            </h3>
                            <p className="d-flex-wrap top-margin-20 text-color-4">
                                <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
                                <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
                                <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
                            </p>
                        </div>{/* container end */}
                    </div>{/* flex-min-height-inner end */}
                    {/* scroll-btn start */}
                    <a href="#down" className="scroll-btn pointer-large">
                        <div className="scroll-arrow-box">
                            <span className="scroll-arrow" />
                        </div>
                        <div className="scroll-btn-flip-box">
                            <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
                        </div>
                    </a>{/* scroll-btn end */}
                </section>{/* page-head end */}
                {/* flex-min-height-box start */}
                <section id="down" className="dark-bg-1 flex-min-height-box">
                    {/* flex-min-height-inner start */}
                    <div className="flex-min-height-inner">
                        {/* container start */}
                        <div className="container small top-bottom-padding-60">
                            {/* flex-container start */}
                            <div data-animation-container className="flex-container">
                                {/* column start */}
                                <div className="twelve-columns text-center">
                                    <h1 className="large-title text-height-12">
                                        <span data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="Stunning E-Commerce Solutions that">Stunning E-Commerce Solutions that</span><br />
                                        <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Upgrade Your Business Profit">Upgrade Your Business Profit</span>
                                    </h1>
                                </div>{/* column end */}
                                {/* column start */}
                                <div className="12-columns">
                                    <div className="content-left-margin-20">
                                        <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay03 text-center" data-animation="fade-anim">
                                            Improve Your BusinesInvision Solutions offers the ideal web based business
                                            arrangements in Canada for all your business needs. Our point by point
                                            arrangements give different deals channels and ensure that you can rapidly grow your present business. Regardless of whether you really want a B2B or B2C site, our accomplished designers can make completely streamlined arrangements
                                            that assist you with pursuing more deals with the least effort.s Profit</p>
                                    </div>
                                </div>{/* column end */}
                            </div>{/* flex-container end */}
                        </div>{/* container end */}
                    </div>{/* flex-min-height-inner end */}
                </section>{/* flex-min-height-box end */}

                {/* section start */}
                <section className="light-bg-1 bottom-padding-0 top-padding-120" data-midnight="black">
                    {/* bottom-padding-90 start */}
                    <div className="bottom-padding-90">
                        {/* portfolio-content start */}
                        <div className="portfolio-content flex-min-height-box">
                            {/* portfolio-content-inner start */}
                            <div className="portfolio-content-inner flex-min-height-inner">
                                {/* flex-container start */}
                                <div className="flex-container container small">
                                    {/* column start */}
                                    <div data-animation-container className="six-columns ">
                                        <div className="content-right-margin-60">
                                            <h3 className="title-style text-color-1">
                                                <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01" data-animation="overlay-anim2">SHOPIFY</span><br />
                                            </h3>
                                            <h5 className="small-title text-color-0">
                                                Invision offers cutting edge Shopify advancement in Canada and coordination administrations to arrangement smooth internet business stores with an enhanced interaction for most extreme deals usefulness and customers commitment. Our UI/UX master designersare knowledgeable in conveying great responsive, and versatile viable Shopify plans the run flawlessly on every significant program.</h5>
                                            <div data-animation-child className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05" data-animation="fade-anim">
                                            </div>
                                        </div>
                                    </div>{/* column end */}
                                    {/* column start */}
                                    <div className="six-columns top-padding-60">
                                        <a href="#" className=" portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link">
                                            <div className="portfolio-mb portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2" data-animation="overlay-anim2" style={{ backgroundImage: 'url(assets/images/backgrounds/web-design/shopify.webp)' }} />
                                        </a>
                                    </div>{/* column end */}
                                </div>{/* flex-container end */}
                            </div>{/* portfolio-content-inner end */}
                        </div>{/* portfolio-content end */}
                    </div>{/* bottom-padding-90 end */}
                    {/* bottom-padding-90 start */}
                    <div className="bottom-padding-90">
                        {/* portfolio-content start */}
                        <div className="portfolio-content flex-min-height-box">
                            {/* portfolio-content-inner start */}
                            <div className="portfolio-content-inner flex-min-height-inner">
                                {/* flex-container start */}
                                <div className="flex-container reverse container small">
                                    {/* column start */}
                                    <div className="six-columns top-padding-60">
                                        <a href="#" className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link">
                                            <div className="portfolio-mb portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2 pset" data-animation="overlay-anim2" style={{ backgroundImage:  'url(assets/images/backgrounds/web-design/woo-commerce.webp)' }} />
                                        </a>
                                    </div>{/* column end */}
                                    {/* column start */}
                                    <div data-animation-container className="six-columns">
                                        <div className="content-left-margin-40">
                                            <h3 className="title-style text-color-1">
                                                <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01" data-animation="overlay-anim2">WOO-COMMERCE</span><br />
                                            </h3>
                                            <h5 className="small-title text-color-0">
                                                We have a specialist group of Woocommerce engineers in Canada with long stretches of generous involvement with conveying Woocommerce driven plan, advancement, and installment handling work processes. Our work processes are outfitted towards conveying Woocommerce projects on schedule, on-financial plan, and with the quality that is guaranteed.</h5>
                                            <div data-animation-child className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05" data-animation="fade-anim">
                                            </div>
                                        </div>
                                    </div>{/* column end */}
                                </div>{/* flex-container end */}
                            </div>{/* portfolio-content-inner end */}
                        </div>{/* portfolio-content end */}
                    </div>{/* bottom-padding-90 end */}
                    {/* bottom-padding-90 start */}
                    <div>
                        {/* portfolio-content start */}
                        <div className="portfolio-content flex-min-height-box">
                            {/* portfolio-content-inner start */}
                            <div className="portfolio-content-inner flex-min-height-inner">
                                {/* flex-container start */}
                                <div className="flex-container container small">
                                    {/* column start */}
                                    <div data-animation-container className="six-columns">
                                        <div className="content-right-margin-40">
                                            <h3 className="title-style text-color-1">
                                                <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01" data-animation="overlay-anim2">MAGENTO</span><br />
                                            </h3>
                                            <h5 className="small-title text-color-0">
                                            Our advancement skill incorporates all center spaces of Magento enveloping turn of events, plan, relocation, and redesigns alongside Magento custom expansion improvement. Our long periods of involvement make us your optimal likely accomplice to create and keep up with your Magento based online business stages for greatest throughput.</h5>
                                            <div data-animation-child className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05" data-animation="fade-anim">
                                            </div>
                                        </div>
                                    </div>{/* column end */}
                                    {/* column start */}
                                    <div className="six-columns top-padding-60">
                                        <a href="ecommerce.php" className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link">
                                            <div className="portfolio-mb portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2" data-animation="overlay-anim2" style={{ backgroundImage: 'url(assets/images/backgrounds/web-design/magento.webp)' }} />
                                        </a>
                                    </div>{/* column end */}
                                </div>{/* flex-container end */}
                            </div>{/* portfolio-content-inner end */}
                        </div>{/* portfolio-content end */}
                    </div>{/* bottom-padding-90 end */}
                </section>{/* section end */}
                {/* <Footer/> */}

            </>
        )
    }
}
