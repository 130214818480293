import React, { Component } from 'react'
import ClientLogo from '../component/ClientLogo'

export default class UiUxds extends Component {
  render() {
    return (
      <>
        {/* page-head start */}
        <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
          {/* page-head-bg */}
          <div className=" page-head-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/uiux.webp)' }} />
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* container start */}
            <div className="container top-bottom-padding-120">
              <h2 className="overlay-loading2 medium-title red-color">UI AND UX DESIGN</h2>
              <p className="d-flex-wrap top-margin-20 text-color-5-ars">
                <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
              </p>
            </div>{/* container end */}
          </div>{/* flex-min-height-inner end */}
          {/* scroll-btn start */}
          <a href="#down" className="scroll-btn pointer-large">
            <div className="scroll-arrow-box">
              <span className="scroll-arrow" />
            </div>
            <div className="scroll-btn-flip-box">
              <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
            </div>
          </a>{/* scroll-btn end */}
        </section>{/* page-head end */}
        {/* flex-min-height-box start */}
        <section id="down" className="dark-bg-1 flex-min-height-box">
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* container start */}
            <div className="container small top-bottom-padding-120">
              {/* flex-container start */}
              <div data-animation-container className="flex-container">
             {/* column start */}
             <div className="twelve-columns text-center">
                  <h2 className="large-title text-height-12">
                    <span data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="UI AND UX DESIGN SERVICES">UI AND UX DESIGN SERVICES</span><br />
                    <div className="content-right-margin-20">
                      <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">Need to assemble your item with a group that builds up an unmistakable plan process, fulfills time constraints, and conveys a right on the money outcome? Go to Intellectsoft's UI and UX administrations. Our configuration group is a little plan studio inside an enormous programming organization that will assist you with building a drawing in item effectively and rapidly.</p>
                    </div>
                  </h2> <br/>
                  <h2 className="large-title text-height-12">
                    <span data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="BENEFITS OF OUR UI AND UX SERVICES">BENEFITS OF OUR UI AND UX SERVICES</span><br />
                    <div className="content-right-margin-20">
                      <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">When you choose our services, you receive everything you need to fully realise your product idea, from Fortune 500 client expertise to design guidelines that will help us produce your product faster and without sacrificing quality.</p>
                    </div>
                  </h2>
                  
                </div>{/* column end */}   
                
              </div>{/* flex-container end */}
            </div>{/* container end */}
          </div>{/* flex-min-height-inner end */}
        </section>{/* flex-min-height-box end */}
        {/* section start */}
        {/* section end */}
        {/* section start */}
        <ClientLogo/>
      </>

    )
  }
}
