import React, { Component } from 'react'
class XamarinApp extends Component{
    constructor(props){
        super (props)

}
render(){
    return (

    <>
<div>
  <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
  {/*?php include("head.php") ?*/}
  {/*?php include("nav.php") ?*/}
  {/* animsition-overlay start */}
  <main className="animsition-overlay" data-animsition-overlay={true} id="up">
    {/* page-head start */}
    <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
      {/* page-head-bg */}
      <div className="page-head-bg" style={{backgroundImage: 'url(assets/images/backgrounds/xhamrin.webp)'}} />
      <div className="bg-overlay" />
      {/* flex-min-height-inner start */}
      <div className="flex-min-height-inner">
        {/* container start */}
        <div className="container top-bottom-padding-120">
          <h2 className="overlay-loading2 medium-title red-color">Xamirin</h2>
          <h3 className="large-title-bold text-color-4">
            <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">DEVELOPMENT</span><br />
          </h3>
          <p className="d-flex-wrap top-margin-20 text-color-4">
            <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
            <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
            <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
          </p>
        </div>{/* container end */}
      </div>{/* flex-min-height-inner end */}
      {/* scroll-btn start */}
      <a href="#down" className="scroll-btn pointer-large">
        <div className="scroll-arrow-box">
          <span className="scroll-arrow" />
        </div>
        <div className="scroll-btn-flip-box">
          <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
        </div>
      </a>{/* scroll-btn end */}
    </section>{/* page-head end */}
    {/* flex-min-height-box start */}
    <section id="down" className="light-bg-1" data-midnight="black">
      {/* container start */}
      <div className="container bottom-padding-60 top-padding-60">
        {/* text-center start */}
        <div data-animation-container className="text-center">
          <p className="medium-title text-color-0">Custom Developed Xamarin Apps Designed</p>
          <h2 data-animation-child className="large-title text-height-10 text-color-1 overlay-anim-box2" data-animation="overlay-anim2">Around Your Unique Needs...
          </h2><br />
          <p data-animation-child className="fade-anim-box tr-delay02 text-color-1 top-margin-5" data-animation="fade-anim">If you want to enjoy the best cross-platform app products, one of the best options to use is to go for Xamarin-based apps. Quantilyticss excels at preparing hybrid apps that use the Xamarin tools for development. Our focus is on preparing end to end app solutions that are fully compatible for use on Android, iOS, Windows platforms. Our professionals understand the potential of Xamarin and create stellar mobile apps that provide answers to all your business problems.</p>
        </div>{/* text-center end */}
        {/* flex-container start */}
        <div className="flex-container response-999 top-bottom-margin-30">
          {/* column start */}
          <div className="four-columns">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2 top-bottom-padding-30">
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20">
                <h1 className="medium-title red-color">XAMARIN</h1>
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Why Choose Us?</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">Quantilyticss offers the ideal Xamarin mobile app development benefits that you are looking for. Our developers are fully capable of using the shared C# code of this platform and prepare apps for any platform of your choice. Your code and other elements in Xamarin remain available for reuse because it offers the common language development implementation.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          {/* column start */}
          <div className="eight-columns">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large light-bg-2 d-block top-bottom-padding-30">
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Why Choose Xamarin?</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">Xamarin is a great set of tools to create hybrid apps for multiple platforms. It allows developers to produce native apps with elegant user interfaces. Whether used on MacOS or Windows, it shares code elements allowing developers to use a singular app development solution. It provides the facility of C# code, which can be productively employed to work on all platforms, providing a true capacity for hybrid app development.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
        </div>{/* flex-container end */}
        {/* flex-container start */}
        <div className="flex-container response-999 top-padding-60">
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                <img className="hover-img" src="assets/images/backgrounds/and.webp" alt="Digital products" style={{width: 200}} />
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">ANDROID</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">A custom Android App is powerful and can tackle all your problems. Our team can develop a tailor-made Android app for you which is efficient, runs fast, and is compatible with a variety of devices with the Android operating system.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                <img className="hover-img" src="assets/images/backgrounds/apple.webp" alt="Digital products" style={{width: 200}} />
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className=" text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">iOS</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">iOS is a strong mobile platform. Let us create the ideal iOS app for you that will deliver extraordinary performance, all the while ensuring that the users enjoy an awesome UI/UX.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                <img className="hover-img" src="assets/images/backgrounds/all-aa.webp" alt="Digital products" style={{width: 200}} />
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">CROSS-PLATFORM</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">If you are looking to lower your development costs, use our cross-platform app development services to prepare hybrid apps that work smartly on all mobile platforms.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          <div className="twelve-columns text-center top-bottom-padding-30">
            <img src="./assets/images/backgrounds/Android%20Mob.webp" style={{margin: 'auto', width: '70%'}} alt />
            <p className="small-title">Not sure which platform you like? Let our experts understand your app idea and then suggest the ideal path to a successfully developed custom app. We ensure that you can achieve the best customized solution with the help from our top industry experts.</p>
          </div>
        </div>{/* flex-container end */}
      </div>{/* container end */}
    </section>{/* section end */}
    <section className="dark-bg-1">
      <div className="top-bottom-padding-30 text-center">
        <p data-animation-child className="medium-title text-color-4">TURNING COMPLEX IDEAS INTO</p>
        <br />
        <h3 data-animation-child className="large-title-bold text-color-4 ">Simple, Graceful &amp; Functional Mobile App Solutions</h3><br />
      </div>
      <div className="container small bottom-padding-120">
        <div className="comments-form-box">
          <form className="comments-form">
            <div className="flex-container">
              <div className="four-columns">
                <input type="text" className="comment-form-control pointer-small" placeholder="Your Name" />
              </div>
              <div className="four-columns">
                <input type="email" className="comment-form-control pointer-small" placeholder="Your Email" />
              </div>
              <div className="four-columns">
                <input type="text" className="comment-form-control pointer-small" placeholder="Phone" />
              </div>
              <div className="twelve-columns">
                <textarea className="comment-form-control pointer-small" placeholder="Comment" defaultValue={""} />
              </div>
            </div>
            <button className="arrow-btn-box pointer-large">
              <span className="arrow-btn">Submit Information</span>
            </button>
          </form>
        </div>{/* comments-form-box end */}
      </div>
    </section>
  </main>{/* animsition-overlay end */}
  {/* footer start */}
  {/*?php include("footer.php") ?*/}
</div>
</>)}}
export default XamarinApp