import React, { Component } from 'react'
export default class Portfolio extends Component {
  render() {
    return (
      <>
        {/* page-head start */}
        <section id="up" className="page-head flex-min-height-box dark-bg-1">
          {/* page-head-bg */}
          <div className="page-head-bg overlay-loading2" style={{ backgroundImage: 'url(assets/images/backgrounds/Portfolio.jpg)' }} />
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* flex-container start */}
            <div className="container top-bottom-padding-120 flex-container response-999">
              {/* column start */}
              <div className="six-columns six-offset">
                <div className="content-left-margin-40">
                  <h2 className="large-title-bold">
                    <span className="load-title-fill tr-delay03" data-text="new approach">new approach</span><br />
                    <span className="load-title-fill tr-delay04" data-text="to design">to design</span><br />
                    <span className="load-title-fill tr-delay05" data-text="& marketing">&amp; marketing</span>
                  </h2>
                </div>
              </div>{/* column end */}
            </div>{/* flex-container end */}
          </div>{/* flex-min-height-inner end */}
          {/* scroll-btn start */}
          <a href="#down" className="scroll-btn pointer-large">
            <div className="scroll-arrow-box">
              <span className="scroll-arrow" />
            </div>
            <div className="scroll-btn-flip-box">
              <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
            </div>
          </a>{/* scroll-btn end */}
        </section>{/* page-head end */}
        {/* dark-bg-2 start */}
        <section id="down" className="dark-bg-2 top-bottom-padding-120">
          {/* container start */}
          <div className="container">
            <div className="text-center">
              <h2 className="large-title text-height-10 title-fill" data-animation="title-fill-anim" data-text="Recent Works">Recent Works</h2><br />
            </div>
            {/* filter-buttons start */}
            <div className="filter-buttons top-padding-90">
              <button className="filter-button-box pointer-small active" data-filter="*">
                <span className="filter-button-flip" data-text="All">All</span>
              </button>
              <button className="filter-button-box pointer-small" data-filter=".websites">
                <span className="filter-button-flip" data-text="Websites">Websites</span>
              </button>
              <button className="filter-button-box pointer-small" data-filter=".mobile">
                <span className="filter-button-flip" data-text="Mobile App">Mobile App</span>
              </button>
              <button className="filter-button-box pointer-small" data-filter=".branding">
                <span className="filter-button-flip" data-text="LOGO">LOGO</span>
              </button>
              <button className="filter-button-box pointer-small" data-filter=".vfx">
                <span className="filter-button-flip" data-text="VFX / 2D Animations">VFX / 2D Animations</span>
              </button>
              <button className="filter-button-box pointer-small" data-filter=".seo">
                <span className="filter-button-flip" data-text="Seo">Seo</span>
              </button>
            </div>
            {/* filter-buttons end */}
            {/* works start */}
            <div className="works">
              {/* grid-web start */}
              <a href="/AmnayaWeb" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Amnaya.png " aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">AMNAYA</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Plant Management And Information Sharing Network </span><br />
                    </h3>
                  </div>
                </div>
              </a>{/* grid-item end */}
              {/* grid-web start */}
              {/* <a href="/Ylearning" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/familyhandbook.webp" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Y-LEARNING</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Learning Based Platform</span><br />
                    </h3>
                  </div>
                </div>
              </a> */}
              {/* grid-item end */}

              {/* grid-web start */}
              <a href="/ClearCost" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Cleat-cost-logo.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">CLEAR COST</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Clear Pricing Health Is Working To Improve The Accountability Of Our Healthcare
                        System And Lower Overall Costs For Consumers</span><br />
                    </h3>
                  </div>
                </div>
              </a>{/* grid-item end */}
              {/* grid-web start */}
              <a href="/DoDeliver" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/DD-Logo.5-02.png" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay"> DO DELIVER</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Do Delivers Offers Its Customers A Range Of Cost-EffecTve Shipping Solu�ons
                        Within Pakistan.</span><br />
                    </h3>
                  </div>
                </div>
              </a>{/* grid-item end */}
              {/* grid-web start */}
              <a href="/DjBooking" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/djbooking.webp" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay"> DJ BOOKING</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">It Is The Easy-To-Use Online Booking System Designed For Both You And Your
                        Mobile DJ Clients.</span><br />
                    </h3>
                  </div>
                </div>
              </a>{/* grid-item end */}
              {/* grid-web start */}
              <a href="/House" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Untitled-12 (1)-01.png" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">HOUSE RUN FOR ME</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">We Will Give You The Security Of
                        Knowing That Things Are Being Handled By An Expert.</span><br />
                    </h3>
                  </div>
                </div>
              </a>{/* grid-item end */}
              {/* grid-web start */}
              <a href="/Editute" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Ai-01.png" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">EDITUTE</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Editude Is An Online Learning Platform That Focuses On Several Subjects.</span><br />
                    </h3>
                  </div>
                </div>
              </a>{/* grid-item end */}
              {/* grid-web start */}
              <a href="/Church" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Church.webp" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">CHURCH MANAGEMENT SYSTEM</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Goldms Enables Churches And Religious Organizatons </span><br />
                    </h3>
                  </div>
                </div>
              </a>{/* grid-item end */}
              {/* grid-item start */}
              <a href="/360App" className="animsition-link grid-item mobile">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/mobapp/360-Degree.jpg" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">360 APP</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">An E-Commerce Mobile App</span><br />
                    </h3>
                  </div>
                </div>
              </a>{/* grid-item end */}
              {/* grid-item start */}
              {/* <a href="/ATeamTransport" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/web-p/2.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Ateam Transport</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">medical transportation services</span><br />
                    </h3>
                  </div>
                </div>
              </a> */}
              {/* grid-item end */}
              {/* grid-APP start */}
              <a href="/Americren" className="animsition-link grid-item mobile">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/mobapp/Americren-Logo.jpg " alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">AMERICREN APP</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01"> The American Cinsumer Real-Estate App</span><br />
                    </h3>
                  </div>
                </div>
              </a>{/* grid-item end */}
              {/* grid-APP start */}
              <a href="/Cyptogram" className="animsition-link grid-item mobile">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/mobapp/cryptoo.webp" alt="" />
                  <div className="works-content">
                    <h3 className="  red-color work-title-overlay">CRYPTOGRAM APP</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Crypto Currencies Trading & Exchange Traders App</span><br />
                    </h3>
                  </div>
                </div>
              </a>{/* grid-item end */}
              {/* grid-item start */}
              {/* <a href="/AutoClub" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/web-p/3.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">AutoClub</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Vehicle Management System</span><br />
                    </h3>
                  </div>
                </div>
              </a> */}
              {/* grid-item end */}
              {/* grid-APP start */}
              <a href="/Ezigo" className="animsition-link grid-item mobile">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/mobapp/ezigoo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">EZIGO APP</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Car Services App That Connects People To  Rides
                      </span><br />
                    </h3>
                  </div>
                </div>
              </a>{/* grid-item end */}
              {/* grid-item start */}
              {/* <a href="/Cornic" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/web-p/4.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Cornic</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Online Sports Betting platform
                      </span><br />
                    </h3>
                  </div>
                </div>
              </a> */}
              {/* grid-item end */}
              {/* grid-item start */}
              <a href="/DriveMentor" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/logo1-011.png" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">DRIVE MENTOR</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Learn Defensive Driving At Drive Mentor's Driving School </span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-item start */}
              <a href="/Pokemon" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Pokemon.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">POKEMON</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Lorem Ipsum Is Simply Dummy Text Of The Printing And type Settinng Industry.
                      </span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-item start */}
              <a href="/Restaurant" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/restaurant.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">RESTAURANT</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Online Restaurant Management System" Is A Web Application.
                      </span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-item start */}
              <a href="/SpreadSheet" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Ss logo_192x192.png" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">SPREAD SHEET</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Import Data From Excel, For Easy Onboarding.
                      </span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-item start */}
              <a href="/LegitChecker" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/legitchecker.jpg" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">LEGIT CHECKER</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Discover Best Click Fraud Protection.
                      </span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-item start */}
              <a href="/Sweatfury" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/sweatfurry.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">SWEAT FURRY</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Sweat Furry Is Online Fitness App Designed For Real People
                      </span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-item start */}
              <a href="Vserve/" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Vserve.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">V-SERVE</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Build Everlasting Customer Relationships With The World’s Favourite CRM
                        Software.
                      </span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-item start */}
              {/* <a href="/Saloon" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/saloon.jpg" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">SALOON</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Our Saloon Front Has All The Features You Need
                      </span><br />
                    </h3>
                  </div>
                </div>
              </a> */}
              {/* grid-item end */}
              {/* grid-web start */}
              <a href="/Family" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/familyhandbook.webp" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Family</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Our Family Handbook Services Come With Certified Developers Who Can Build Your
                        Retirement Planning Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* 5 GEAI Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/5th-gear-marketing.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">5 GEAI</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Marketing Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* 5 GEAI End */}
              {/*  A+A Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/A+A.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">A+A</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">A+A Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* A+A End */}
              {/*  Academic Expert Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Academic-Expert.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Academic Expert</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Academic Expert Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* Academic Expert End */}
              {/*  AHMC DENTAL STUDIO  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/AHMC-DENTAL-STUDIO.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">AHMC DENTAL STUDIO</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">AHMC DENTAL STUDIO Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* AHMC DENTAL STUDIO  End */}
              {/* Airport Valet  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/AirportValet.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Airport Valet</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Airport Valet Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* Airport Valet End */}
              {/* Aquila Businesst  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/aquilabusinesst.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Aquila Businesst</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Aquila Businesst Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* Aquila Businesst End */}
              {/* Banquest Place  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/banquest-place.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Banquest Place</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Banquest Place Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* Banquest Place End */}
              {/* Bet Honey  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/bet-honey.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Bet Honey</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Bet Honey Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* Bet Honey End */}
              {/* Bid Point  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/bid-point.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Bid Point</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Bid Point Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* Bid Point End */}
              {/* Black Bay Financial  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Black-Bay-financial.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Black Bay Financial</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Black Bay Financial Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* Black Bay Financial End */}
              {/* Black Label  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Black-label.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Black Label</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Black Label Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* Black Label End */}
              {/* Bolibol  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Bolibol.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Bolibol</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Bolibol Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* Bolibol End */}
              {/* Book keeping  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Bookkeeping.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Book keeping</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Book keeping Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* Book keeping End */}
              {/* Boxfleet  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Boxfleet-logo.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Boxfleet</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Boxfleet Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* Boxfleet End */}
              {/* Burgerism  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Burgerism-Logo.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Burgerism</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Burgerism Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* Burgerism End */}
              {/* Buzinesswriting  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Buzinesswriting.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Buzinesswriting</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Buzinesswriting Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* Buzinesswriting End */}
              {/* Canda Entreprise  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/canda-entreprise.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Canda Entreprise</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Canda Entreprise Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* Canda Entreprise End */}
              {/* Career Jobs  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Career-Jobs.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Career Jobs</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Career Jobs Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* Career Jobs End */}
              {/* Carling Technologies  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Carling-Technologies.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Carling Technologies</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Carling Technologies Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* Carling Technologies End */}
              {/*  Climb Marketing  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/climb-marketing.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Climb Marketing</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Climb Marketing Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/*  Climb Marketing End */}
              {/*  Connect savvy  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Connect-savvy.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Connect Savvy</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Connect Savvy Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/*   Connect savvy End */}
              {/*   Content Token Generator  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Content-Token-Generator.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Connect Savvy</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Content Token Generator Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/*   Content Token Generator End */}
              {/*   Cornic  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Cornic-Logo.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Cornic</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Cornic Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/*   Cornic End */}
              {/*   Databloc  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Databloc.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Databloc</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Databloc Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/*   Databloc End */}
              {/*   Delish  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Delish-Logo.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Delish</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Delish Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/*   Delish End */}
              {/*   Delish  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Delux-auto.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Delux Auto</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Delux Auto Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/*   Delish End */}
              {/*   Digital Learning  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/Digital-learning.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Digital Learning</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Digital Learning Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/*   Digital Learning End */}
              {/*   Dilara  Start */}
              <a href="#" className="animsition-link grid-item websites">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/website/dilara.jpg" aaa alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Dilara </h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">Dilara  Project</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/*   Dilara End */}
              {/* grid-APP start */}
              <a href="/DzireApp" className="animsition-link grid-item mobile">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/mobapp/Dziree.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">DZIRE APP</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay01">D’Zire is a platform for digital services.</span><br />
                    </h3>
                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-APP start */}
              <a href="/Shaigan" className="animsition-link grid-item mobile">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/mobapp/WhatsApp Image 2021-02-25 at 3.25.27 PM.jpeg" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">SHAIGAN APP</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay03">Shaigan Sehat</span>
                    </h3>
                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-item start */}
              <a href="assets/video/vfx1.webm" className="popup-youtube grid-item vfx">
                <div className="work_item pointer-large hover-box hidden-box">
                  <video style={{ width: '100%' }} muted autoPlay source src="./assets/video/vfx1.webm" type="video.webm" />

                  <div className="works-content">
                    <span className="small-title-oswald red-color work-title-overlay">Vfx / 2D Anmations</span>
                  </div>
                </div>
              </a>
              <a href="/OPDApp" className="animsition-link grid-item mobile">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/mobapp/Opd 2-05.jpg" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">OPD APP</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay03">OPD Is More Than An App For Finding Doctor</span>
                    </h3>
                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-APP start */}
              <a href="/AmnayaApp" className="animsition-link grid-item mobile">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/mobapp/amnayaa.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">AMNAYA APP</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay03">Plant Management And Information Sharing Network</span>
                    </h3>
                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* Chat It start */}
              <a href="#" className="animsition-link grid-item mobile">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/mobapp/Chat-It.jpg" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">Chat It</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay03">Chat It</span>
                    </h3>
                  </div>
                </div>
              </a>
              {/* Chat It end */}
              {/* grid-item start */}
              <a href="./assets/video/vfx2.webm" className="popup-youtube grid-item vfx">
                <div className="work_item pointer-large hover-box hidden-box">
                  <video style={{ width: '100%' }} muted autoPlay source src="assets/video/vfx2.webm" type="video.webm" />
                  <div className="works-content">
                    <span className="small-title-oswald red-color work-title-overlay">Vfx / 2D Anmations</span>
                  </div>
                </div>
              </a>
              <a href="/Soplush" className="animsition-link grid-item mobile">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/mobapp/soplushh.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">SOPLUSH BEAUTY APP </h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay03">Beautcian Appointment Booker</span>
                    </h3>
                  </div>
                </div>
              </a>
              <a href="/SafetyWorkerApp" className="animsition-link grid-item mobile">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/mobapp/Workersafety.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">WORKER SAFETY APP</h3>
                    <h3 className=" text-color-4">
                      <span className="work-title-overlay work-title-delay03"> An Online App For  Safety Hazards</span>
                    </h3></div>
                </div>
              </a>
              <a href="assets/video/vfx3.webm" className="popup-youtube grid-item vfx">
                <div className="work_item pointer-large hover-box hidden-box">
                  <video style={{ width: '100%' }} muted autoPlay source src="assets/video/vfx3.webm" type="video.webm" />
                  <div className="works-content">
                    <span className="small-title-oswald red-color work-title-overlay">Vfx / 2D Anmations</span>
                  </div>
                </div>
              </a>
              <a href="assets/video/vfx4.webm" className="popup-youtube grid-item vfx">
                <div className="work_item pointer-large hover-box hidden-box">
                  <video style={{ width: '100%' }} muted autoPlay source src="assets/video/vfx4.webm" type="video.webm" />

                  <div className="works-content">
                    <span className="small-title-oswald red-color work-title-overlay">Vfx / 2D Anmations</span>
                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-item start */}
              <a href="assets/video/vfx5.webm" className="popup-youtube grid-item vfx">
                <div className="work_item pointer-large hover-box hidden-box">
                  <video style={{ width: '100%' }} muted autoPlay src="assets/video/vfx5.webm" type="video.webm" />
                  <div className="works-content">
                    <span className="small-title-oswald red-color work-title-overlay">Vfx / 2D Anmations</span>
                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-APP start */}
              <a href="/360App" className="animsition-link grid-item branding">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/logos/360logo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">QUANTILYTICS</h3>

                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-item start */}
              <a href="/Americren" className="animsition-link grid-item branding">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/logos/americrenlogo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">QUANTILYTICS</h3>

                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-item start */}
              <a href="/AmnayaApp" className="animsition-link grid-item branding">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/logos/amnayalogo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">QUANTILYTICS</h3>

                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-item start */}
              <a href="assets/video/vfx9.webm" className="popup-youtube grid-item vfx">
                <div className="work_item pointer-large hover-box hidden-box">
                  <video style={{ width: '100%' }} muted autoPlay source src="./assets/video/vfx9.webm" type="video.webm" />
                  <div className="works-content">
                    <span className="small-title-oswald red-color work-title-overlay">Vfx / 2D Anmations</span>
                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-item start */}
              <a href="/ATeamTransport" className="animsition-link grid-item branding">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/logos/ateamlogo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">QUANTILYTICS</h3>

                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-item start */}
              <a href="/Cornic" className="animsition-link grid-item branding">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/logos/corniclogo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">QUANTILYTICS</h3>

                  </div>
                </div>
              </a>
              <a href="/DriveMentor" className="animsition-link grid-item branding">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/logos/drivelogo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">QUANTILYTICS</h3>

                  </div>
                </div>
              </a>
              {/* grid-item end */}
              <a href="/Ezigo" className="animsition-link grid-item branding">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/logos/ezigologo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">QUANTILYTICS</h3>

                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-item start */}
              <a href="assets/video/vfx8.webm" className="popup-youtube grid-item vfx">
                <div className="work_item pointer-large hover-box hidden-box">
                  <video style={{ width: '100%' }} muted autoPlay source src="./assets/video/vfx8.webm" type="video.webm" />
                  <div className="works-content">
                    <span className="small-title-oswald red-color work-title-overlay">Vfx / 2D Anmations</span>
                  </div>
                </div>
              </a>
              {/* grid-item end */}
              <a href="/JeepsWeb" className="animsition-link grid-item branding">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/logos/jeepslogo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">QUANTILYTICS</h3>

                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-item end */}
              <a href="/JKS" className="animsition-link grid-item branding">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/logos/jkslogo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">QUANTILYTICS</h3>

                  </div>
                </div>
              </a>
              {/* grid-item end */}
              <a href="legalland.php" className="animsition-link grid-item branding">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/logos/legallogo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">QUANTILYTICS</h3>

                  </div>
                </div>
              </a>
              {/* grid-item end */}
              {/* grid-item start */}
              <a href="assets/video/vfx11.webm" className="popup-youtube grid-item vfx">
                <div className="work_item pointer-large hover-box hidden-box">
                  <video style={{ width: '100%' }} muted autoPlay source src="./assets/video/vfx11.webm" type="video.webm" />

                  <div className="works-content">
                    <span className="small-title-oswald red-color work-title-overlay">Vfx / 2D Anmations</span>
                  </div>
                </div>
              </a>
              {/* grid-item end */}
              <a href="likeheartedtribe.php" className="animsition-link grid-item branding">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/logos/likelogo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">QUANTILYTICS</h3>

                  </div>
                </div>
              </a>
              {/* grid-item end */}
              <a href="/OPDApp" className="animsition-link grid-item branding">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/logos/opdlogo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">QUANTILYTICS</h3>

                  </div>
                </div>
              </a>
              {/* grid-item end */}
              <a href="/PslSoftBall" className="animsition-link grid-item branding">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/logos/psllogo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">QUANTILYTICS</h3>

                  </div>
                </div>
              </a>
              {/* grid-item end */}
              <a href="/SafetyWorkerApp" className="animsition-link grid-item branding">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/logos/safetylogo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">QUANTILYTICS</h3>

                  </div>
                </div>
              </a>
              <a href="/Spring-Living" className="animsition-link grid-item branding">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/logos/springlogo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">QUANTILYTICS</h3>

                  </div>
                </div>
              </a>
              {/* grid-item end */}
              <a href="/TekkerTv" className="animsition-link grid-item branding">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/logos/tekkerlogo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">QUANTILYTICS</h3>

                  </div>
                </div>
              </a>
              {/* grid-item end */}
              <a href="/WeMalta" className="animsition-link grid-item branding">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/project/logos/wemaltalogo.webp" alt="" />
                  <div className="works-content">
                    <h3 className=" red-color work-title-overlay">QUANTILYTICS</h3>

                  </div>
                </div>
              </a>
              {/*seo*/}
              <a href='/Seo' className="grid-item seo" onclick=" window.open('assets/pdf/AiQSEO.pdf'); return true;">
                <div className="work_item pointer-large hover-box hidden-box">
                  <img className="hover-img" src="assets/images/seo/resz.webp" alt="" />
                  <div className="works-content">
                    <span className="small-title-oswald red-color work-title-overlay">QUANTILYTICS</span>
                  </div>
                </div>
              </a>
            </div>{/* works end */}
          </div>{/* container end */}
        </section>{/* dark-bg-2 end */}
      </>

    )
  }
}
