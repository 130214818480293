import React, { Component } from 'react'
import ClientLogo from '../component/ClientLogo'

export default class LogoDesign extends Component {
  render() {
    return (
      <>
        {/* page-head start */}
        <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
          {/* page-head-bg */}
          <div className=" page-head-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/Logodesigning.jpg)' }} />
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* container start */}
            <div className="container top-bottom-padding-120">
              <h2 className="overlay-loading2 medium-title red-color">LOGO</h2>
              <h3 className="large-title-bold text-color-4">
                <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">DESIGNING</span><br />
              </h3>
              <p className="d-flex-wrap top-margin-20 text-color-4">
                <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
              </p>
            </div>{/* container end */}
          </div>{/* flex-min-height-inner end */}
          {/* scroll-btn start */}
          <a href="#down" className="scroll-btn pointer-large">
            <div className="scroll-arrow-box">
              <span className="scroll-arrow" />
            </div>
            <div className="scroll-btn-flip-box">
              <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
            </div>
          </a>{/* scroll-btn end */}
        </section>{/* page-head end */}
        {/* flex-min-height-box start */}
        <section id="down" className="dark-bg-1 flex-min-height-box">
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* container start */}
            <div className="container small top-bottom-padding-120">
              {/* flex-container start */}
              <div data-animation-container className="flex-container">
                {/* column start */}
                <div className="twelve-columns text-center">
                  <h2 className="large-title text-height-12">
                    <span data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="Good Design Brings Good Business">Good Design Brings Good Business</span><br />
                    <div className="content-right-margin-20">
                      <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">The logo is the face of your Brand. We have the most talented and experienced logo designers, who approach every logo design project with an inspired dedication with a complete thought process. Being a focus based quality branding agency in UK, we have been designing logos and brand identities for many reputed companies and that’s something we are good at.</p>
                    </div>
                  </h2>
                  <h2 className="large-title text-height-20">
                    <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Logo Designing Services">Logo Designing Services</span>
                    <div className="content-right-margin-20">
                      <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">Web improvement is the work engaged with building up a site for the Internet (World Wide Web) or an intranet (a private network).[1] Web advancement can go from building up a basic single static page of plain content to complex online web applications (web applications), electronic organizations, and informal organization administrations. A progressively far reaching rundown of errands to which web improvement generally alludes, may incorporate web building, website architecture, web content advancement, customer contact, customer side/server-side scripting, web server and system security arrangement, and web based business improvement. </p>
                    </div>
                  </h2>
                </div>{/* column end */}
                {/* column start */}

              </div>{/* flex-container end */}
            </div>{/* container end */}
          </div>{/* flex-min-height-inner end */}
        </section>{/* flex-min-height-box end */}
        {/* section start */}
        <section className="light-bg-1 bottom-padding-30 top-padding-120" data-midnight="black">
          {/* bottom-padding-90 start */}
          <div className="bottom-padding-90">
            {/* portfolio-content start */}
            <div className="portfolio-content flex-min-height-box">
              {/* portfolio-content-inner start */}
              <div className="portfolio-content-inner flex-min-height-inner">
                {/* flex-container start */}
                <div className="flex-container container small">
                  {/* column start */}
                  <div data-animation-container className="six-columns">
                    <div className="content-right-margin-40">
                      <h3 className="title-style text-color-1">
                        <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01" data-animation="overlay-anim2">Print Design</span><br />
                        {/* <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02" data-animation="overlay-anim2">Development</span><br /> */}
                      </h3>
                      <h6 className="small-title text-color-0 text-center">Your printed marketing material can make a significant impact on your company’s image. We provide quality print design services to make sure that your business cards, letterheads, envelopes, brochures, and other marketing materials
                        stand out in every sales meeting, face-to-face encounters and direct mailings. </h6>
                      <div data-animation-child className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05" data-animation="fade-anim">
                        {/* <a href="customsoftwaredevelopment.php" className="arrow-btn pointer-large animsition-link">Read more</a> */}
                      </div>
                    </div>
                  </div>{/* column end */}
                  {/* column start */}
                  <div className="six-columns top-padding-60">
                    <a href="customsoftwaredevelopment.php" className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link">
                      <div className="portfolio-mb portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2 text-center" data-animation="overlay-anim2" style={{ backgroundImage: 'url(assets/images/backgrounds/logo-1.jpg)' }} />
                    </a>
                  </div>{/* column end */}
                </div>{/* flex-container end */}
              </div>{/* portfolio-content-inner end */}
            </div>{/* portfolio-content end */}
          </div>{/* bottom-padding-90 end */}
          {/* bottom-padding-90 start */}
          <div className="bottom-padding-90">
            {/* portfolio-content start */}
            <div className="portfolio-content flex-min-height-box">
              {/* portfolio-content-inner start */}
              <div className="portfolio-content-inner flex-min-height-inner">
                {/* flex-container start */}
                <div className="flex-container reverse container small">
                  {/* column start */}
                  <div className="six-columns top-padding-60">
                    <a href="enterprisesoftware.php" className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link">
                      <div className="portfolio-mb portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2" data-animation="overlay-anim2" style={{ backgroundImage: 'url(assets/images/backgrounds/logo-2.jpg)' }} />
                    </a>
                  </div>{/* column end */}
                  {/* column start */}
                  <div data-animation-container className="six-columns">
                    <div className="content-left-margin-40">
                      <h3 className="title-style text-color-1">
                        <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 text-center" data-animation="overlay-anim2">Brand &amp; Stationery Design</span><br />
                        {/* <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02" data-animation="overlay-anim2">Software</span><br /> */}
                      </h3>
                      <h6 className="small-title text-color-0 text-center">We offer a complete range of quality brand identity design services, stationery design services, company rebranding services, brand guidelines services, and branding consultation. Defining your brand’s identity is key in marketing, therefore our dedicated team ensures that they build out your brand visually.
                        Buzz Interactive is a creative brand agency you will ever need to stand out your brand image.</h6>
                      <div data-animation-child className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05" data-animation="fade-anim">
                        {/* <a href="enterprisesoftware.php" className="arrow-btn pointer-large animsition-link">Read more</a> */}
                      </div>
                    </div>
                  </div>{/* column end */}
                </div>{/* flex-container end */}
              </div>{/* portfolio-content-inner end */}
            </div>{/* portfolio-content end */}
          </div>{/* bottom-padding-90 end */}
        </section>{/* section end */}
        {/* section start */}
        <ClientLogo></ClientLogo>
      </>

    )
  }
}
