import React, {Component} from 'react'
class IosMobileApp extends Component{
    constructor (props){
        super(props)
}
render (){
    return(
        <>

<div>
  <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
  {/*?php include("head.php") ?*/}
  <style dangerouslySetInnerHTML={{__html: "\n    .client-count {\n        width: 179px;\n        border: 2px solid #00a8e8;\n        text-align: center;\n        border-radius: 6px;\n        padding: 5px 0;\n        color: #00a8e8;\n        font-weight: bold;\n        font-size: 22px;\n    }\n    #expert-ios {\n        padding: 100px 0 50px 0;\n        background: linear-gradient(to right, #f2f2f2 50%, #ffffff 50%);\n    }\n" }} />
  {/*?php include("nav.php") ?*/}
  {/* animsition-overlay start */}
  <main className="animsition-overlay" data-animsition-overlay={true} id="up">
    {/* page-head start */}
    <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
      {/* page-head-bg */}
      <div className="page-head-bg" style={{backgroundImage: 'url(assets/images/backgrounds/nikolay-tarashchenko-1qFHFkKk8OA-unsplash.webp)'}} />
      <div className="bg-overlay" />
      {/* flex-min-height-inner start */}
      <div className="flex-min-height-inner">
        {/* container start */}
        <div className="container top-bottom-padding-120">
          <h2 className="overlay-loading2 medium-title red-color">Custom iOS App</h2>
          <h3 className="large-title-bold text-color-5-ars">
            <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">DEVELOPMENT Services</span><br />
          </h3>
          <p className="d-flex-wrap top-margin-20 text-color-5-ars">
            <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
            <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
            <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
          </p>
        </div>{/* container end */}
      </div>{/* flex-min-height-inner end */}
      {/* scroll-btn start */}
      <a href="#down" className="scroll-btn pointer-large">
        <div className="scroll-arrow-box">
          <span className="scroll-arrow" />
        </div>
        <div className="scroll-btn-flip-box">
          <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
        </div>
      </a>{/* scroll-btn end */}
    </section>{/* page-head end */}
    {/* flex-min-height-box start */}
    <section id="down" className="light-bg-1" data-midnight="black">
      {/* container start */}
      <section id="expert-ios">
        <div className="flex-container container bottom-padding-60 top-padding-60">
          <div className="six-columns">
            {/* text-center start */}
            <div data-animation-container className>
              <h3 className="text-color-0">Custom Developed iOS Mobile App</h3>
              <h1 data-animation-child className=" text-height-10 text-color-1 overlay-anim-box2" data-animation="overlay-anim2">Designed Around Your Unique Needs</h1><br />
              <p data-animation-child className="fade-anim-box tr-delay02 text-color-1 " data-animation="fade-anim">hnhtechsolutions designs elegant iOS apps and develops them according to the best industry practices. They are designed to augment your business functionality, and allow your firm to expand its current operational performance. Contact our business analysts for all your iOS mobile app development queries and you will learn that our team is fully capable of understanding your elicit requirements and produce the intended solution.</p>
            </div>{/* text-center end */}
            <div data-animation-container className="top-margin-30">
              <h2 data-animation-child className=" text-height-10 text-color-1 overlay-anim-box2" data-animation="overlay-anim2">Designed Around Your Unique Needs</h2><br />
              <p data-animation-child className="fade-anim-box tr-delay02 text-color-1 s" data-animation="fade-anim">hnhtechsolutions is a smart choice that you can make for developing iOS mobile apps. Whether you want functionality for a specific iOS device or are looking for a general solution, we can deliver you superior quality app, by using state-of-the-art app design and development tools. Get timely delivery in a cost-effective manner and ensure that you do not lose a fortune when ordering an iOS app.</p>
            </div>{/* text-center end */}
            <p className="client-count">100+ Clients</p>
          </div>
          <div className="six-columns">
            {/* text-center start */}
            <div data-animation-container className="text-center" style={{padding: 10}}>
              <img src="assets/images/backgrounds/ios-expert3.webp" alt style={{margin: 'auto', width: '70%'}} />
            </div>{/* text-center end */}
          </div>
        </div>
      </section>
    </section>{/* section end */}
    <section className="dark-bg-1">
      <div className="top-bottom-padding-30 text-center">
        <h2 data-animation-child className="medium-title text-color-4">TURNING COMPLEX IDEAS INTO</h2>
        <br />
        <h3 data-animation-child className="large-title-bold text-color-4 ">Simple, Graceful &amp; Functional Mobile App Solutions</h3><br />
      </div>
      <div className="container small bottom-padding-120">
        <div className="comments-form-box">
          <form className="comments-form">
            <div className="flex-container">
              <div className="four-columns">
                <input type="text" className="comment-form-control pointer-small" placeholder="Your Name" />
              </div>
              <div className="four-columns">
                <input type="email" className="comment-form-control pointer-small" placeholder="Your Email" />
              </div>
              <div className="four-columns">
                <input type="text" className="comment-form-control pointer-small" placeholder="Phone" />
              </div>
              <div className="twelve-columns">
                <textarea className="comment-form-control pointer-small" placeholder="Comment" defaultValue={""} />
              </div>
            </div>
            <button className="arrow-btn-box pointer-large">
              <span className="arrow-btn">Submit Information</span>
            </button>
          </form>
        </div>{/* comments-form-box end */}
      </div>
    </section>
  </main>{/* animsition-overlay end */}
  {/* footer start */}
  {/*?php include("footer.php") ?*/}
</div>
</>
    )}
  }
  export default IosMobileApp