// import '../public/assets/css/custom.css'
import React, { Component, useEffect, useState } from "react";
import Footer from "../src/component/Footer";
import Home from "../src/pages/Home";
import Web from "../src/pages/Web";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Loader from "./component/Loader";
import Navbar from "./component/Navbar";
import AppDev from "./pages/AppDev";
import WebDev from "./pages/WebDev";
import Hosting from "./pages/Hosting";
import ContentWriting from "./pages/ContentWriting";
import Account from "./pages/Account";
import BlockChain from "./pages/BlockChain";
import LogoDesign from "./pages/LogoDesign";
import Digital from "./pages/Digital";
import Effective from "./pages/Effective";
import DDev from "./pages/DDev";
import Seo from "./pages/Seo";
import Vfx from "./pages/Vfx";
// import Solution from './pages/Solution';
import CaseStudy from "./pages/companycareer";
import Portfolio from "./pages/Portfolio";
import Clienttele from "./pages/Clienttele";
import UiUxds from "./pages/UiUxds";
import Contact from "../src/pages/contact";
import CompanyAbout from "../src/pages/companyabout";
import CompanyTeam from "../src/pages/companyteam";
import Cornic from "./pages/cornic";
import App360 from "./pages/360app";
import Americren from "./pages/americren";
import AmnayaApp from "./pages/amnayaapp";
import Amnayaweb from "./pages/amnayaweb";
import ATeamTransport from "./pages/ateamtransport";
import Attendancemanagementsystem from "./pages/attendancemanagementsystem";
import Cyptogram from "./pages/CyptoGram";
import DriveMentor from "./pages/DriveMentor";
import EastwestFurniture from "./pages/EastWestFurniture";
import WeMalta from "./pages/WeMalta";
import Ezigo from "./pages/Ezigo";
import Hemid from "./pages/Hemid";
import InventoryManagement from "./pages/InventoryManagement";
import JeepsApp from "./pages/JeepsApp";
import DzireApp from "./pages/DzireApp";
import Shaigan from "./pages/Shaigan";
import Soplush from "./pages/Soplush";
import Farmer from "./pages/Farmer";
import JeepsWeb from "./pages/JeepsWeb";
import JKS from "./pages/JKS";
import Klinvesto from "./pages/Klinvesto";
import LegalLand from "./pages/LegalLand";
import LikeHeartedTribe from "./pages/LikeHeartedTribe";
import OPDApp from "./pages/OPDApp";
import Photography from "./pages/Photography";
import PslSoftBall from "./pages/PslSoftBall.js";
import SafetyWorkerApp from "./pages/SafetyWorkerApp.js";
import SpringLiving from "./pages/Spring-Living.js";
import Stately from "./pages/Stately";
import StormBrown from "./pages/StormBrown.js";
import TekkerTv from "./pages/TekkerTv.js";
import TradeDay from "./pages/TradeDay.js";
import AutoClub from "./pages/AutoClub.js";
import AndroidApp from "./pages/AndroidApp.js";
import Ionic from "./pages/Ionic.js";
import IosMobileApp from "./pages/IosMobileApp.js";
import MobileAppDevelopment from "./pages/MobileAppDevelopment.js";
import XamarinApp from "./pages/XamarinApp.js";
import ConnectSavvy from "./pages/ConnectSavvy.js";
import Bob from "./pages/Bob.js";
import BidPoint from "./pages/BidPoint.js";
import Balloning from "./pages/Balloning";
import AllSafeDriver from "./pages/AllSafeDriver.js";
import GetaPexman from "./pages/GetaPexman.js";
import GoDiva from "./pages/GoDiva.js";
import Legacy from "./pages/Legacy";
import Hidraulicos from "./pages/Hidraulicos";
import Kadi from "./pages/Kadi";
import Kl from "./pages/Kl";
import LiveManager from "./pages/LiveManager";
import MultiRestaurant from "./pages/MultiRestaurant";
import MyFaminc from "./pages/MyFaminc";
import Quantilytics from "./pages/Quantilytics";
import ReadyFile from "./pages/ReadyFile";
import Shoppix from "./pages/Shoppix";
import Sicomas from "./pages/Sicomas";
import SixFigure from "./pages/SixFigure";
import Stockiest from "./pages/Stockiest";
import Sweatfury from "./pages/Sweatfury";
import TimerSheet from "./pages/TimerSheet";
import Virtualtriage from "./pages/TimerSheet";
import WebMock from "./pages/WebMock";
import Zayzaman from "./pages/Zayzaman";
import LazyLoad from "react-lazyload";
import Cmsdevelopment from "./pages/Cmsdevelopment";
import Ecommerce from "./pages/Ecommerce";
import Customsoftweredev from "./pages/Customsoftweredev";
import WebDS from "./pages/WebDS";
import Enterprises from "./pages/Enterprises";
import Reactnative from "./pages/Reactnative";
import CompanyCareer from "./pages/companycareer";
import Ylearning from "./pages/Ylearning";
import Editute from "./pages/Editute";
import ClearCost from "./pages/ClearCost";
import Vserve from "./pages/Vserve";
import DjBooking from "./pages/DjBooking";
import Pokemon from "./pages/Pokemon";
import Family from "./pages/Family";
import DoDeliver from "./pages/DoDeliver";
import SpreadSheet from "./pages/SpreadSheet";
import LegitChecker from "./pages/LegitChecker";
import Church from "./pages/Church";
import House from "./pages/House";
import Saloon from "./pages/Saloon";
import Restaurant from "./pages/Restaurant";
import Frontend from "./pages/Frontend";
import "./App.css";
// import TawkTo from "tawkto-react";
import TawkTo from "tawkto-react";



const tawkToPropertyId = "5d2346a222d70e36c2a4b1b9";
const tawkToKey = "default";

export default function App() {
  useEffect(() => {
    const tawk  = new TawkTo(tawkToPropertyId, tawkToKey);
    console.log(tawk);
    tawk.onBeforeLoad((abc) => console.log("onbeforeload", abc)) ;
  }, []);

  return (
    <>
      <Router>
        <Loader />
        <Navbar />
      </Router>
      <Router>
        <Switch>
          <LazyLoad>
            <main className="animsition-overlay" data-animsition-overlay="true">
              <Route exact={true} path="/">
                <Home />
              </Route>
              <Route exact={true} path="/web-design">
                <Web />
              </Route>
              <Route exact={true} path="/app-development">
                <AppDev></AppDev>
              </Route>
              <Route exact={true} path="/web-development">
                <WebDev></WebDev>
              </Route>
              <Route exact={true} path="/hosting">
                <Hosting></Hosting>
              </Route>
              <Route exact={true} path="/contentwriting">
                <ContentWriting></ContentWriting>
              </Route>
              <Route exact={true} path="/accounting">
                <Account></Account>
              </Route>
              <Route exact={true} path="/blockchaindevelopment">
                <BlockChain></BlockChain>
              </Route>
              <Route exact={true} path="/logodesigning">
                <LogoDesign></LogoDesign>
              </Route>
              <Route exact={true} path="/digitalmarketing">
                <Digital></Digital>
              </Route>
              <Route exact={true} path="/effectivesolution">
                <Effective></Effective>
              </Route>
              <Route exact={true} path="/digital-development">
                <DDev></DDev>
              </Route>
              <Route exact={true} path="/seo">
                <Seo></Seo>
              </Route>
              <Route exact={true} path="/vfx">
                <Vfx></Vfx>
              </Route>
              <Route exact={true} path="/Cmsdevelopment">
                <Cmsdevelopment></Cmsdevelopment>
              </Route>
              <Route exact={true} path="/DjBooking">
                <DjBooking></DjBooking>
              </Route>
              <Route exact={true} path="/Family">
                <Family></Family>
              </Route>
              <Route exact={true} path="/DoDeliver">
                <DoDeliver></DoDeliver>
              </Route>
              <Route exact={true} path="/SpreadSheet">
                <SpreadSheet></SpreadSheet>
              </Route>
              <Route exact={true} path="/LegitChecker">
                <LegitChecker></LegitChecker>
              </Route>
              <Route exact={true} path="/Pokemon">
                <Pokemon></Pokemon>
              </Route>
              <Route exact={true} path="/House">
                <House></House>
              </Route>
              <Route exact={true} path="/Church">
                <Church></Church>
              </Route>
              <Route exact={true} path="/Restaurant">
                <Restaurant></Restaurant>
              </Route>
              {/* <Route exact={true} path="/Saloon">
                <Saloon></Saloon>
              </Route> */}
              <Route exact={true} path="/Customsoftweredev">
                <Customsoftweredev></Customsoftweredev>
              </Route>
              <Route exact={true} path="/ClearCost">
                <ClearCost></ClearCost>
              </Route>
              <Route exact={true} path="/Vserve">
                <Vserve></Vserve>
              </Route>
              <Route exact={true} path="/CaseStudy">
                <CaseStudy></CaseStudy>
              </Route>
              <Route exact={true} path="/portfolio">
                <Portfolio></Portfolio>
              </Route>

              <Route exact={true} path="/Clienttele">
                <Clienttele></Clienttele>
              </Route>
              <Route exact={true} path="/Ylearning">
                <Ylearning></Ylearning>
              </Route>
              <Route exact={true} path="/Editute">
                <Editute></Editute>
              </Route>

              <Route exact={true} path="/UiUxds">
                <UiUxds></UiUxds>
              </Route>
              <Route exact={true} path="/Contact">
                <Contact></Contact>
              </Route>

              {/* <Route
                exact={true}
                path="/Contact"
                name="Contact"
                <Contact/>
                // render={(props) => <Contact {...props} />}
              /> */}

              <Route exact={true} path="/CompanyAbout">
                <CompanyAbout></CompanyAbout>
              </Route>

              <Route exact={true} path="/CompanyTeam">
                <CompanyTeam></CompanyTeam>
              </Route>

              <Route exact={true} path="/CompanyCareer">
                <CompanyCareer></CompanyCareer>
              </Route>

              <Route exact={true} path="/Ecommerce">
                <Ecommerce></Ecommerce>
              </Route>

              <Route exact={true} path="/WebDS">
                <WebDS></WebDS>
              </Route>
              <Route exact={true} path="/Reactnative">
                <Reactnative ></Reactnative>
              </Route>
              <Route exact={true} path="/Frontend">
                <Frontend ></Frontend>
              </Route>

              <Route exact={true} path="/Enterprises">
                <Enterprises></Enterprises>
              </Route>

              <Route exact={true} path="/cornic">
                <Cornic></Cornic>
              </Route>
              <Route exact={true} path="/360app">
                <App360></App360>
              </Route>
              <Route exact={true} path="/Americren">
                <Americren></Americren>
              </Route>

              <Route exact={true} path="/AmnayaApp">
                <AmnayaApp></AmnayaApp>
              </Route>

              <Route exact={true} path="/AmnayaWeb">
                <Amnayaweb></Amnayaweb>
              </Route>
              <Route exact={true} path="/Soplush">
                <Soplush></Soplush>
              </Route>
              <Route exact={true} path="/ATeamTransport">
                <ATeamTransport></ATeamTransport>
              </Route>
              <Route exact={true} path="/AttendanceManagementSystem">
                <Attendancemanagementsystem></Attendancemanagementsystem>
              </Route>
              <Route exact={true} path="/Cyptogram">
                <Cyptogram></Cyptogram>
              </Route>
              <Route exact={true} path="/Drivementor">
                <DriveMentor></DriveMentor>
              </Route>
              <Route exact={true} path="/EastWestFurniture">
                <EastwestFurniture></EastwestFurniture>
              </Route>
              <Route exact path="/WeMalta">
                <WeMalta></WeMalta>
              </Route>
              <Route exact={true} path="/Ezigo">
                <Ezigo></Ezigo>
              </Route>

              <Route exact={true} path="/Hemid">
                <Hemid></Hemid>
              </Route>

              <Route exact={true} path="/AutoClub">
                <AutoClub></AutoClub>
              </Route>

              <Route exact={true} path="/InventoryManagement">
                <InventoryManagement></InventoryManagement>
              </Route>

              <Route exact={true} path="/JeepsApp">
                <JeepsApp></JeepsApp>
              </Route>
              
              <Route exact={true} path="/DzireApp">
                <DzireApp></DzireApp>
              </Route>
              <Route exact={true} path="/Shaigan">
                <Shaigan></Shaigan>
              </Route>

              <Route exact={true} path="/Farmer">
                <Farmer></Farmer>
              </Route>

              <Route exact={true} path="/JeepsWeb">
                <JeepsWeb></JeepsWeb>
              </Route>

              <Route exact={true} path="/JKS">
                <JKS></JKS>
              </Route>

              <Route exact={true} path="/Klinvesto">
                <Klinvesto></Klinvesto>
              </Route>

              <Route exact={true} path="/LegalLand">
                <LegalLand></LegalLand>
              </Route>

              <Route exact={true} path="/LikeHeartedTribe">
                <LikeHeartedTribe></LikeHeartedTribe>
              </Route>

              <Route exact={true} path="/OPDApp">
                <OPDApp></OPDApp>
              </Route>

              <Route exact={true} path="/Photography">
                <Photography></Photography>
              </Route>

              <Route exact={true} path="/PSLSoftBall">
                <PslSoftBall></PslSoftBall>
              </Route>

              <Route exact={true} path="/SafetyWorkerApp">
                <SafetyWorkerApp></SafetyWorkerApp>
              </Route>

              <Route exact={true} path="/Spring-Living">
                <SpringLiving></SpringLiving>
              </Route>

              <Route exact={true} path="/Stately">
                <Stately></Stately>
              </Route>

              <Route exact={true} path="/StormBrown">
                <StormBrown></StormBrown>
              </Route>

              <Route exact={true} path="/TekkerTv">
                <TekkerTv></TekkerTv>
              </Route>
              <Route exact={true} path="/TradeDay">
                <TradeDay></TradeDay>
              </Route>

              <Route exact={true} path="/AndroidApp">
                <AndroidApp></AndroidApp>
              </Route>

              <Route exact={true} path="/Ionic">
                <Ionic></Ionic>
              </Route>

              <Route exact={true} path="/IosMobileApp">
                <IosMobileApp></IosMobileApp>
              </Route>

              <Route exact={true} path="/MobileAppDevelopment">
                <MobileAppDevelopment></MobileAppDevelopment>
              </Route>

              <Route exact={true} path="/XamarinApp">
                <XamarinApp></XamarinApp>
              </Route>

              <Route exact={true} path="/ConnectSavvy">
                <ConnectSavvy></ConnectSavvy>
              </Route>

              <Route exact={true} path="/Bob">
                <Bob></Bob>
              </Route>

              <Route exact={true} path="/digitalLms">
                <digitalLms></digitalLms>
              </Route>

              <Route exact={true} path="/BidPoint">
                <BidPoint></BidPoint>
              </Route>

              <Route exact={true} path="/Balloning">
                <Balloning></Balloning>
              </Route>

              <Route exact={true} path="/AllSafeDriver">
                <AllSafeDriver></AllSafeDriver>
              </Route>

              <Route exact={true} path="/GetaPexman">
                <GetaPexman></GetaPexman>
              </Route>

              <Route exact={true} path="/GoDiva">
                <GoDiva></GoDiva>
              </Route>

              <Route exact={true} path="/Hidraulicos">
                <Hidraulicos></Hidraulicos>
              </Route>

              <Route exact={true} path="/Legacy">
                <Legacy></Legacy>
              </Route>

              <Route exact={true} path="/Kadi">
                <Kadi></Kadi>
              </Route>

              <Route exact={true} path="/Kl">
                <Kl></Kl>
              </Route>

              <Route exact={true} path="/MyFaminc">
                <MyFaminc></MyFaminc>
              </Route>

              <Route exact={true} path="/LiveManager">
                <LiveManager></LiveManager>
              </Route>

              <Route exact={true} path="/MultiRestaurant">
                <MultiRestaurant></MultiRestaurant>
              </Route>

              <Route exact={true} path="/Quantilytics">
                <Quantilytics></Quantilytics>
              </Route>

              <Route exact={true} path="/ReadyFile">
                <ReadyFile></ReadyFile>
              </Route>

              <Route exact={true} path="/Shoppix">
                <Shoppix></Shoppix>
              </Route>

              <Route exact={true} path="/Sicomas">
                <Sicomas></Sicomas>
              </Route>

              <Route exact={true} path="/SixFigure">
                <SixFigure></SixFigure>
              </Route>

              <Route exact={true} path="/Stockiest">
                <Stockiest></Stockiest>
              </Route>

              <Route exact={true} path="/Sweatfury">
                <Sweatfury></Sweatfury>
              </Route>

              <Route exact={true} path="/TimerSheet">
                <TimerSheet></TimerSheet>
              </Route>

              <Route exact={true} path="/Virtualtriage">
                <Virtualtriage></Virtualtriage>
              </Route>

              <Route exact={true} path="/WebMock">
                <WebMock></WebMock>
              </Route>

              <Route exact={true} path="/Zayzaman">
                <Zayzaman></Zayzaman>
              </Route>
            </main>
          </LazyLoad>
        </Switch>
        <Footer />
      </Router>
      <a
        href="https://api.whatsapp.com/send/?phone=+4915216652405&text=Hi&app_absent=0"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fab fa-whatsapp whatsapp-icon"></i>
      </a>
    </>
  );
}
