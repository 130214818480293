import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { FaInstagram , FaFacebookSquare, FaLinkedin} from "react-icons/fa";
export default function Footer() {
  const element = <FontAwesomeIcon icon={faPhoneAlt} />
  return (
    <>
            {/* <ReactWhatsapp className='scrollbar-hidden' style={{ position: 'relative', fontSize: '60px', padding: "10px" }} number="+1(647)660-1619" message="Hi" >
                    <i class="fab fa-whatsapp" style={{ color: '#e7edf0', backgroundColor: 'green', borderRadius: "60%", lineHeight: "0.86" }} aria-hidden="true"></i>
                </ReactWhatsapp> */}
      {/* footer start */}
      <footer className="footer dark-bg-1">

        {/* flex-container start */}
        <div className="flex-container container top-bottom-padding-90">
          {/* column start */}
          <div className="three-columns bottom-padding-60">
            <div className="content-right-margin-10 footer-center-mobile">
              <img className="footer-logo" src="../../assets/images/logo/qlogo.png" alt="logo" />
            </div>
          </div>{/* column end */}
          {/* column start */}
          <div className="three-columns bottom-padding-60">
            <div className="content-left-right-margin-10">
              <ul className="footer-menu text-color-4">
                <li><a className="pointer-large animsition-link small-title hover-color" href="/">Home</a></li>
                <li><a className="pointer-large animsition-link small-title hover-color" href="/Platform">Platform</a></li>
                <li><a className="pointer-large animsition-link small-title hover-color" href="/CaseStudy">Case study</a></li>
                <li><a className="pointer-large animsition-link small-title hover-color" href="/Portfolio">Portfolio
                  </a>
                </li>
                <li><a className="pointer-large animsition-link small-title hover-color" href="/Clientele">Clientele</a>
                </li>
                <li><a className="pointer-large animsition-link small-title hover-color" href="/Contacts">Contact</a>
                </li>
              </ul>
            </div>
          </div>{/* column end */}
          {/* column start */}
          <div className="three-columns bottom-padding-60">
            <div className="content-left-right-margin-10 footer-center-mobile">
              <ul className="footer-information text-color-4">
                <li className=" hover-color"><i className="far fa-envelope" /><a href="mailto:info@hnhtechsolutions.com" className="xsmall-title-oswald pointer-small">info@hnhtechsolutions.com</a>
                </li> 
                <li className=" hover-color"><i className="fas fa-mobile-alt" /><a href="tel:UK +44-7429-125487"  className="small-title pointer-small">UK +44-7429-125487</a>
                </li>
                <li className=" hover-color"><i className="fas fa-mobile-alt" /><a href="tel:+1-(647)-660-1619"  className="small-title pointer-small">US +1-(516)-738-3453</a>
                </li>
                <li className=" hover-color"><i className="fas fa-mobile-alt" /><a href="tel:++4915216652404"  className="small-title pointer-small">GER +4915216652405</a>
                </li>
                <li className=" hover-color"><i className="fas fa-mobile-alt" /><a href="tel:+92-332-307-2025" className="small-title pointer-small">PAK +92-332-307-2025</a>
                </li>
              </ul>
            </div>
          </div>{/* column end */}
          {/* new */}
          {/* column start */}
          <div className="three-columns bottom-padding-60">
            <div className="content-left-margin-10">
              <ul className="footer-social">
                <li>
                <FaInstagram style={{color:"white",fontSize:'15px',marginRight:"10px",fontWeight:"900"}} /> 
                  <div className="flip-btn-box">
                    <a href="https://www.instagram.com/hnhtechsolutions/" target="_blank"  className="flip-btn pointer-small" data-text="Instagram" > Instagram</a>
                  </div>
                </li>
                <li>
                <FaFacebookSquare style={{color:"white",fontSize:'15px',marginRight:"10px",fontWeight:"900"}} /> 
                  <div className="flip-btn-box">
                    <a href="https://www.facebook.com/hnhtechsolutions/" target="_blank"  className="flip-btn pointer-small" data-text="Facebook">Facebook</a>
                  </div>
                </li>
                <li>
                <FaLinkedin style={{color:"white",fontSize:'15px',marginRight:"10px",fontWeight:"900"}} />
                  <div className="flip-btn-box">
                    <a href="https://www.linkedin.com/company/hnh-tech-solutions/" target="_blank"  className="flip-btn pointer-small" data-text="Linkedin">Linkedin</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>{/* column end */}
          {/* column start */}
          <div className="twelve-columns">

            <p className="text-color-4 footer-copyright">© Copyright 2020 Quantilytics</p>
            
          </div>{/* column end */}
        </div>{/* flex-container end */}
      </footer>
      {/* <a href="#" className="call-back-btn pointer-small" id="myBtn" data-toggle="modal" data-target="#myModal">
    <img src="../../assets/images/backgrounds/communications.webp" className width="100%" />
  </a> */}
      {/* <div id="myModal" className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
          <h4 className="modal-title" id="myModalLabel">Call Back</h4>
        </div>
        <div className="modal-body">
          <form action="send_email.php" method="post">
            <div className="form-group">
              <label>Name:</label>
              <input type="text" className="form-control" name="name" id="name" defaultValue required />
              <label>Phone Number:</label>
              <input type="number" className="form-control" name="phonenumber" id="phonenumber" defaultValue required />
            </div>
            <button className="button-dsgn-custom pt-2 pb-2 pl-3 pr-3" type="submit">Submit</button>
          </form>
        </div>
        {/*<div class="modal-footer">*/}
      {/*  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>*/}
      {/*  <button type="button" class="btn btn-primary">Save changes</button>*/}
      {/*</div>
      </div> /.modal-content 
    </div>{/* /.modal-dialog 
  </div> */}
      {/* Modal */}
      {/* <div id="myModal" className="modal"> */}
      {/* Modal content */}
      {/* <div className="modal-content">
      <form action="send_email.php" method="post">
        <div className="form-group">
          <label>Name:</label>
          <input type="text" className="form-control" name="name" id="name" defaultValue required />
          <label>Phone Number:</label>
          <input type="number" className="form-control" name="phonenumber" id="phonenumber" defaultValue required />
        </div>
        <button className="button-dsgn-custom pt-2 pb-2 pl-3 pr-3" type="submit">Submit</button>
      </form>
    </div>
  </div> */}
      {/* footer end */}
    </>
  )

}
