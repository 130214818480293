import { extend } from 'jquery'
import React ,{Component} from 'react'
class OPDApp extends Component{
constructor(props){
super(props)
}
render(){
    return(
        <>
<div>
  {/*?php include("head.php") ?*/}
  <link rel="stylesheet" href="assets/css/carousel.css" />
  <link rel="stylesheet" href="assets/css/mobile-carousel.css" />
  {/*?php include("nav.php") ?*/}
  {/* animsition-overlay start */}
  <main className="animsition-overlay" data-animsition-overlay={true} id="up">
    {/* page-head start */}
    {/*?php include("mobileapp-banner.php") ?*/}
    {/* page-head end */}
    <div id="down" className="light-bg-1 top-bottom-padding-120" data-midnight="black">
      <div className="container text-center">
        <h1 className="large-title-bold">OPD App</h1>
        <h1>OPD Is More Than An App For Finding Doctors.</h1>
      </div>
    </div>
    {/* flex-min-height-box start */}
    <div className="dark-bg-1 flex-min-height-box">
      {/* flex-min-height-inner start */}
      <div className="flex-min-height-inner">
        {/* flex-container start */}
        <div className="flex-container container project-content top-padding-60 bottom-padding-60">
          {/* column start */}
          <div className="six-columns">
            <div className="content-right-margin-20">
              <h2 className="text-color-4 title-style"><strong>Description:</strong></h2>
              <p className="p-style-medium text-color-3" style={{letterSpacing: 1}}>
                OPD is more than an app for finding doctors. Whether it’s booking doctor appointments, ordering medicines, scheduling diagnostic tests or having an online consultation with your doctor, OPD helps you find online medical services &amp; solutions to enable you to take better care of yourself.</p>
              <div className="top-margin-30">
                <p className="p-style-medium text-color-3" style={{marginBottom: 20}}><h4 className="text-color-4">Technology Stack App:</h4> Android Native</p>
                <p className="p-style-medium text-color-3" style={{marginBottom: 20}}><h4 className="text-color-4">Technology Stack APIs:</h4> PHP</p>
                <p className="p-style-medium text-color-3" style={{marginBottom: 20}}><h4 className="text-color-4">Admin Panel:</h4> Firebase</p>
                <p className="p-style-medium text-color-3" style={{marginBottom: 20}}><h4 className="text-color-4">Database: </h4> MySQl</p>
                <p className="p-style-medium text-color-3" style={{ marginBottom: 20 }}><h4 className="text-color-4">link:</h4>
                          <a href="https://hnhtechsolutions.com/project/OPD.php" target="_blank" className="links">https://hnhtechsolutions.com/project/OPD.php</a>
                        </p>
              </div>
            </div>
          </div>{/* column end */}
          {/* column start */}
          <div className="six-columns">
            <div className="content-left-margin-20">
              <div className="box1">
                <img src="assets/images/mobile-carousel/iphone-xx.webp" alt style={{width: '100%'}} />
                <div className="slider">
                  <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                      <div className="carousel-item" type="button" data-toggle="modal" data-target="#exampleModalCenter">
                        <img className="d-block w-100 i1" src="assets/images/project/opd/1.webp" alt="First slide" />
                      </div>
                      <div className="carousel-item" type="button" data-toggle="modal" data-target="#exampleModalCenter">
                        <img className="d-block w-100 i1" src="assets/images/project/opd/2.webp" alt="Second slide" />
                      </div>
                      <div className="carousel-item" type="button" data-toggle="modal" data-target="#exampleModalCenter">
                        <img className="d-block w-100 i1" src="assets/images/project/opd/3.webp" alt="Third slide" />
                      </div>
                      <div className="carousel-item" type="button" data-toggle="modal" data-target="#exampleModalCenter">
                        <img className="d-block w-100 i1" src="assets/images/project/opd/4.webp" alt="Third slide" />
                      </div>
                      <div className="carousel-item" type="button" data-toggle="modal" data-target="#exampleModalCenter">
                        <img className="d-block w-100 i1" src="assets/images/project/opd/5.webp" alt="Third slide" />
                      </div>
                      <div className="carousel-item" type="button" data-toggle="modal" data-target="#exampleModalCenter">
                        <img className="d-block w-100 i1" src="assets/images/project/opd/6.webp" alt="Third slide" />
                      </div>
                      <div className="carousel-item" type="button" data-toggle="modal" data-target="#exampleModalCenter">
                        <img className="d-block w-100 i1" src="assets/images/project/opd/7.webp" alt="Third slide" />
                      </div>
                      <div className="carousel-item active" type="button" data-toggle="modal" data-target="#exampleModalCenter">
                        <img className="d-block w-100 i1" src="assets/images/project/opd/8.webp" alt="Third slide" />
                      </div>
                    </div>
                    <a className="carousel-control-prev pointer-small" href="#carouselExampleControls" role="button" data-slide="prev">
                      <span className="carousel-control-prev-icon display-none" aria-hidden={true} />
                      <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next pointer-small" href="#carouselExampleControls" role="button" data-slide="next">
                      <span className="carousel-control-next-icon display-none" aria-hidden={true} />
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>{/* column end */}
        </div>{/* flex-container end */}
      </div>{/* flex-min-height-inner end */}
    </div>{/* flex-min-height-box end */}
    <div className="text-center top-bottom-padding-120 red-bg" data-midnight="black">
      <a href="360App" className="pointer-large animsition-link overlay-btn-box">
        <span className="overlay-btn" data-text="Next project">Next project</span>
      </a>
    </div>
  </main>{/* animsition-overlay end */}
  {/*?php include("footer.php") ?*/}
</div>
</>
    )
}
}
export default OPDApp