import React, { Component } from 'react'
export default class WebDS extends Component {
  render() {
    return (
      <>
        {/* page-head start */}
        <section id="up" className="page-head flex-min-height-box dark-bg-1">
          {/* page-head-bg */}
          <div className="page-head-bg-aman  overlay-loading2" style={{ backgroundImage: 'url(assets/images/backgrounds/wds.webp)' }} />
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* flex-container start */}
            <div className="container top-bottom-padding-120 flex-container response-999">
              {/* column start */}
              <div className="six-columns six-offset">
                <div className="content-left-margin-40">
                  <h2 className="large-title-bold">
                    <span className="load-title-fill tr-delay03" data-text="We are a">We are a</span><br />
                    <span className="load-title-fill tr-delay04" data-text="premier software">premier software</span><br />
                    <span className="load-title-fill tr-delay05" data-text="development firm!">&amp; development firm!</span>
                  </h2>
                  <p className="d-flex-wrap top-margin-20 text-color-4">
                    <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
                    <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
                    <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
                  </p> 
                </div>
              </div>{/* column end */}
            </div>{/* flex-container end */}
          </div>{/* flex-min-height-inner end */}
          {/* scroll-btn start */}
          <a href="#down" className="scroll-btn pointer-large">
            <div className="scroll-arrow-box">
              <span className="scroll-arrow" />
            </div>
            <div className="scroll-btn-flip-box">
              <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
            </div>
          </a>{/* scroll-btn end */}
          
        </section>{/* page-head end */}
        <div className="col-lg-12 col-xl-12 col-md-12">
            <h1 className="f-ebold">
              <div className="text-center top-bottom-padding-120 red-bg" data-midnight="black">
                <span className="large-title">SERVICES</span>
              </div>
            </h1>
          </div>

        {/* dark-bg-2 start */}
        {/* flex-min-height-box start */}
        {/* flex-min-height-box end */}
        <section id="down" className="light-bg-1" data-midnight="black">
      {/* container start */}
      <div className="container top-padding-120">
        {/* text-center start */}
        <div data-animation-container className="text-center">
          <h2 data-animation-child className="large-title text-height-10 text-color-1 overlay-anim-box2" data-animation="overlay-anim2">WHAT WE HAVE TO OFFER</h2><br />
          <p data-animation-child className="fade-anim-box tr-delay02 text-color-1 xsmall-title-oswald top-margin-5" data-animation="fade-anim">For clients that want custom mobile app development solution, hnhtechsolutions offers unique, compatible, and fully capable solutions. We prepare apps using the top development tools, like Android Studio, X Code, and others to ensure that the final solution fully conforms to your app requirement specification. Our mobile app development services have a wide spectrum and cover all mobile-based platforms.</p>
        </div>{/* text-center end */}
        {/* flex-container start */}
        <div className="flex-container response-999 top-padding-60">
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                <img className="hover-img" src="assets/images/backgrounds/gph.webp" alt="Digital products" style={{width: 200}} />
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Graphic Design & Video Editing</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">Our graphic designers will build a one-of-a-kind visual identity for you, giving it a distinct look.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                <img className="hover-img" src="assets/images/backgrounds/webd.webp" alt="Digital products" style={{width: 200}} />
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className=" text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim"> Website Design & Development </h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim"> Our web development services will help your firm improve product knowledge and client interaction.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                <img className="hover-img" src="assets/images/backgrounds/mobap.webp" alt="Digital products" style={{width: 200}} />
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim"> Mobile Application Development </h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">Our mobile apps are portable and simple to use, allowing a wider audience to use them.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          {/* column start */}
          
          {/* column end */}
          {/* column start */}
          
          {/* column end */}
          
        </div>{/* flex-container end */}
      </div>{/* container end */}
    </section>
      </>

    )
  }
}
