import React, { Component } from 'react'
import ClientLogo from '../component/ClientLogo'
// import Jumbotron from 'react-bootstrap/Jumbotron';

export default class Enterprises extends Component {
    render() {
        return (
            <>
                {/* page-head start */}
                <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
                    {/* page-head-bg */}
                    <div className=" page-head-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/web-development/enterprisessoftwere.webp)' }} />
                    {/* flex-min-height-inner start */}
                    <div className="flex-min-height-inner">
                        {/* container start */}
                        <div className="container top-bottom-padding-120">
                            <h2 className="overlay-loading2 medium-title red-color">ENTERPRISE</h2>
                            <h3 className="large-title-bold text-color-4">
                                <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">SOFTWERE DEVELOPMENT</span><br />
                            </h3>
                            <p className="d-flex-wrap top-margin-20 text-color-4">
                                <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
                                <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
                                <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
                            </p>
                        </div>{/* container end */}
                    </div>{/* flex-min-height-inner end */}
                    {/* scroll-btn start */}
                    <a href="#down" className="scroll-btn pointer-large">
                        <div className="scroll-arrow-box">
                            <span className="scroll-arrow" />
                        </div>
                        <div className="scroll-btn-flip-box">
                            <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
                        </div>
                    </a>{/* scroll-btn end */}
                </section>{/* page-head end */}
                {/* flex-min-height-box start */}
                <section id="down" className="dark-bg-1 flex-min-height-box">
                    {/* flex-min-height-inner start */}
                    <div className="flex-min-height-inner">
                        {/* container start */}
                        <div className="container small top-bottom-padding-120">
                            {/* flex-container start */}
                            <div data-animation-container className="flex-container">
                                {/* column start */}
                                <div className="twelve-columns text-center">
                                    <h2 className="large-title text-height-12">
                                        <span data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="Enterprise Software Development">Enterprise Software Development</span><br />
                                        <div className="content-right-margin-20">
                                            <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">We accept that when organizations are outfitted with the right programming, they can speed up their computerized change and accomplish goals quicker. Employ a group of prepared specialists to construct your undertaking arrangement.</p>
                                        </div>
                                    </h2>
                                    <h2 className="large-title text-height-20">
                                        <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">When constructed and carried out by specialists, endeavor programming mechanizes processes, helps functional productivity, changes your approaches to carrying on with work, and improves your association's nimbleness. Endeavor programming advancement permits organizations to make arrangements that are solid, versatile and custom-made to fit the modern requirements of a huge venture impeccably.</p>
                                        <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">At Concise Software, we convey undertaking answers for associations and establishments working across ventures like money, car, retail, and ecological insurance. We accept that when organizations are furnished with the right programming, they can speed up their advanced change and accomplish goals quicker. Enlist a group of prepared specialists to construct your undertaking arrangement.</p>
                                    </h2>
                                </div>{/* column end */}
                                {/* column start */}

                            </div>{/* flex-container end */}
                        </div>{/* container end */}
                    </div>{/* flex-min-height-inner end */}


                </section>{/* flex-min-height-box end */}
                {/* <div className="col-lg-12 col-xl-12 col-md-12">
            <h1 className="f-ebold">
              <div className="text-center top-bottom-padding-120 red-bg" data-midnight="black">
                <span className="large-title">Enterprise software development services</span>
              </div>
            </h1>
          </div> */}
                <section id="down" className="dark-bg-0 flex-min-height-box">
                    <div className='row'>
                        {/* flex-min-height-inner start */}
                        <div className="flex-min-height-inner">
                            {/* container start */}
                            <div className="container small top-bottom-padding-120">
                                {/* flex-container start */}
                                <div data-animation-container className="flex-container">
                                    {/* column start */}
                                    <div className="twelve-columns text-center">
                                        <h2 className="large-title text-height-12">
                                            <span data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="Enterprise software development services">Enterprise software development services</span><br />
                                            <div className="content-right-margin-20">
                                                <p data-animation-child className="p-style-medium text-color-1 fade-anim-box tr-delay02" data-animation="fade-anim">To make the most of innovative technologies, enterprises often team up with companies like Concise Software that specialize in enterprise software development. We have helped many large organizations to transform their business processes and can support you on this journey as well.</p>
                                            </div>
                                        </h2>
                                    </div>{/* column end */}
                                    {/* column start */}
                                </div>{/* flex-container end */}
                            </div>{/* container end */}
                        </div>{/* flex-min-height-inner end */}
                    </div>
                </section>
                {/* section start */}
                <section className="light-bg-0-aman bottom-padding-30 top-padding-120" data-midnight="black">
                    {/* flex-container start */}

                    {/* bottom-padding-90 start */}
                    <div className="bottom-padding-90">
                        {/* portfolio-content start */}
                        <div className="portfolio-content flex-min-height-box">
                            {/* portfolio-content-inner start */}
                            <div className="portfolio-content-inner flex-min-height-inner">
                                {/* flex-container start */}
                                <div className="flex-container container small">
                                    {/* column start */}
                                    <div data-animation-container className="four-columns">
                                        <div className="content-right-margin-40">
                                            <h3 className=" text-center   text-color-0">
                                                <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 " data-animation="overlay-anim2">Information the board administrations</span><br />
                                                {/* <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02" data-animation="overlay-anim2">Development</span><br /> */}
                                            </h3>
                                            <h6 className=" text-color-0-aman text-center">Stay aware of the new patterns in information the board on account of our devices that convey prescient investigation and astute robotization. Our top to bottom information on arising advances like information science, enormous information, blockchain,
                                                and progressed investigation will assist you with taking advantage of your information.</h6>
                                            <div data-animation-child className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05" data-animation="fade-anim">
                                                {/* <a href="customsoftwaredevelopment.php" className="arrow-btn pointer-large animsition-link">Read more</a> */}
                                            </div>
                                        </div>
                                    </div>{/* column end */}
                                    {/* column start */}
                                    <div data-animation-container className="four-columns">
                                        <div className="content-right-margin-40">
                                            <h3 className=" text-center   text-color-0">
                                                <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 " data-animation="overlay-anim2">Inheritance application modernization</span><br />
                                                {/* <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02" data-animation="overlay-anim2">Development</span><br /> */}
                                            </h3>
                                            <h6 className="text-color-0-aman text-center">Obsolete programming represents a huge security hazard, and it's frequently costly to keep up with. Our group can assist you with playing out a point by point specialized examination of your arrangement and afterward further develop it by utilizing the most recent apparatuses and innovations,
                                                making your business more dexterous and ready to contend available.</h6>
                                            <div data-animation-child className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05" data-animation="fade-anim">
                                                {/* <a href="customsoftwaredevelopment.php" className="arrow-btn pointer-large animsition-link">Read more</a> */}
                                            </div>
                                        </div>
                                    </div>{/* column end */}
                                    <div data-animation-container className="four-columns">
                                        <div className="content-right-margin-40">
                                            <h3 className=" text-center   text-color-0">
                                                <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 " data-animation="overlay-anim2">Enterprise mobility</span><br />
                                                {/* <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02" data-animation="overlay-anim2">Development</span><br /> */}
                                            </h3>
                                            <h6 className=" text-color-0-aman text-center">Our group has insight in giving associations arrangements that permit productive administration of portable applications, assets, and gadgets. Our product will assist with enhancing processes in a hurry and
                                                guarantee that your versatile applications support an enormous number of clients in the business climate.</h6>
                                            <div data-animation-child className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05" data-animation="fade-anim">
                                                {/* <a href="customsoftwaredevelopment.php" className="arrow-btn pointer-large animsition-link">Read more</a> */}
                                            </div>
                                        </div>
                                    </div>{/* column end */}
                                </div>{/* flex-container end */}
                            </div>{/* portfolio-content-inner end */}
                        </div>{/* portfolio-content end */}
                    </div>{/* bottom-padding-90 end */}
                    {/* bottom-padding-90 start */}
                    <div className="bottom-padding-90">
                        {/* portfolio-content start */}
                        <div className="portfolio-content flex-min-height-box">
                            {/* portfolio-content-inner start */}
                            <div className="portfolio-content-inner flex-min-height-inner">
                                {/* flex-container start */}
                                <div className="flex-container reverse container small">
                                    {/* flex-container start */}
                                    <div className="flex-container container small">
                                        {/* column start */}
                                        <div data-animation-container className="four-columns">
                                            <div className="content-right-margin-40">
                                                <h3 className=" text-center   text-color-0">
                                                    <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 " data-animation="overlay-anim2">Programming reconciliation administrations</span><br />
                                                    {/* <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02" data-animation="overlay-anim2">Development</span><br /> */}
                                                </h3>
                                                <h6 className="text-color-0-aman text-center">Further develop your product foundation utilizing very much planned structure microservices, solid APIs, and information reconciliation arrangements. Advance business system with our complete endeavor application
                                                    improvement administrations. Benefit from our incorporation answers for dominate your rivals.</h6>
                                                <div data-animation-child className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05" data-animation="fade-anim">
                                                    {/* <a href="customsoftwaredevelopment.php" className="arrow-btn pointer-large animsition-link">Read more</a> */}
                                                </div>
                                            </div>
                                        </div>{/* column end */}
                                        {/* column start */}
                                        <div data-animation-container className="four-columns">
                                            <div className="content-right-margin-40">
                                                <h3 className=" text-center   text-color-0">
                                                    <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 " data-animation="overlay-anim2">Custom programming Development</span><br />
                                                    {/* <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02" data-animation="overlay-anim2">Development</span><br /> */}
                                                </h3>
                                                <h6 className=" text-color-0-aman text-center">Construct a tailormade arrangement that upholds your association with versatile programming and work on each component of your business.
                                                    Benefit from our industry information in building, keeping up with, and scaling endeavor programming arrangements.</h6>
                                                <div data-animation-child className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05" data-animation="fade-anim">
                                                    {/* <a href="customsoftwaredevelopment.php" className="arrow-btn pointer-large animsition-link">Read more</a> */}
                                                </div>
                                            </div>
                                        </div>{/* column end */}
                                        <div data-animation-container className="four-columns">
                                            <div className="content-right-margin-40">
                                                <h3 className=" text-center   text-color-0">
                                                    <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01 " data-animation="overlay-anim2">Digital transformation services</span><br />
                                                    {/* <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02" data-animation="overlay-anim2">Development</span><br /> */}
                                                </h3>
                                                <h6 className=" text-color-0-aman text-center">We are a solid advanced change accomplice for some huge associations all over the planet. Exploit our ability in utilizing computerized advancements to
                                                    construct new or alter the current business cycles and meet the quickly changing prerequisites of your market.</h6>
                                                <div data-animation-child className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05" data-animation="fade-anim">
                                                    {/* <a href="customsoftwaredevelopment.php" className="arrow-btn pointer-large animsition-link">Read more</a> */}
                                                </div>
                                            </div>
                                        </div>{/* column end */}
                                    </div>{/* flex-container end */}
                                </div>{/* portfolio-content-inner end */}
                            </div>{/* portfolio-content-inner end */}
                        </div>{/* portfolio-content end */}
                    </div>{/* bottom-padding-90 end */}
                </section>{/* section end */}
                <ClientLogo></ClientLogo>
            </>

        )
    }
}
