import React, { Component } from 'react'
import ClientLogo from '../component/ClientLogo'

export default class Reactnative extends Component {
    render() {
        return (
            <>
  {/* page-head start */}
  <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
    {/* page-head-bg */}
    <div className="page-head-bg" style={{backgroundImage: 'url(assets/images/backgrounds/reactdevs.webp)'}} />
    <div className="bg-overlay" />
    {/* flex-min-height-inner start */}
    <div className="flex-min-height-inner">
    <div className="container top-bottom-padding-120">
              <h2 className="overlay-loading2 medium-title red-color">REACT NATIVE</h2>
              <h3 className="large-title-bold text-color-4-arz">
                <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">DEVELOPMENT</span><br />
              </h3>
              <p className="d-flex-wrap top-margin-20 text-color-5-fr">
                <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
              </p>
            </div>     {/* container start */}
    </div>{/* flex-min-height-inner end */}
    {/* scroll-btn start */}
    <a href="#down" className="scroll-btn pointer-large">
      <div className="scroll-arrow-box">
        <span className="scroll-arrow" />
      </div>
      <div className="scroll-btn-flip-box">
        <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
      </div>
    </a>{/* scroll-btn end */}
  </section>{/* page-head end */}
  {/* flex-min-height-box start */}
  <section id="down" className="light-bg-1 about-page">
    {/* <div className="container top-bottom-padding-120">
<h4 className>Team up with our front end designer to bring your thoughts into the real world, convey your image story, upgrade your visual character, and gain competitive edge on the lookout.

Our 10 years+ solid group of engineers, and coders utilize clean codes, advancement technique, most recent stages like Angular, Vue JS, Ember JS, to assemble client driven points of interaction to further develop your business development and fabricate a crowd of people for the since a long time ago run.</h4>
    </div> */}
  </section>{/* flex-min-height-box end */}
  {/* section start */}
  <section id="down" className="light-bg-1" data-midnight="black">
      {/* container start */}
      <div className="container top-padding-120">
        {/* text-center start */}
        <div data-animation-container className="text-center">
           <h2 data-animation-child className="large-title text-height-10 text-color-1 overlay-anim-box2" data-animation="overlay-anim2">Our React Native App Development Services</h2><br /> 
          <p data-animation-child className="fade-anim-box tr-delay02 text-color-1 xsmall-title-oswald top-margin-5" data-animation="fade-anim">Half and half turn of events, cross-stage application improvement, API combination, thus significantly more. Our first rate React Native engineers convey everything your applications need and your business requires. We offer protected, secure, extensible and durable portable applications to our worldwide customers.</p>
          <br/>
          <br/>
        </div>{/* text-center end */}
        {/* flex-container start */}
        <div className="flex-container response-999 top-padding-60">
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                 <img className="hover-img" src="assets/images/backgrounds/Cp.webp" alt="Digital products" style={{width: 200}} />
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Cross-Platform Development</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">Cross-platform application advancement is made simple with React Native. Our accomplished respond software engineers foster a local portable application for various stages that shares a solitary codebase, thusly bringing about less expense and faster turn of events.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                 <img className="hover-img" src="assets/images/backgrounds/Msl.webp" alt="Digital products" style={{width: 200}} /> 
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className=" text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Maintenance & Support</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">We take on a proactive methodology in refreshing your applications also, recognizing and fixing bugs, which permits you to continue to focus on your center business for better advancement and gains.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                 <img className="hover-img" src="assets/images/backgrounds/Api.webp" alt="Digital products" style={{width: 200}} /> 
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">API & App Integration</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">We can integrate your applications with a CRM solution or different API administrations. Furthermore, we will likewise integrate with your business environment to assist you with conveying the most ideal client experience.</p>
                <br />
                
              </div>
              
            </a>
          </div>
          {/* column end */}
          
        </div>{/* flex-container end */}
      </div>{/* container end */}
    </section>{/* section end */}

  {/* section start */}
  <section id="down" className="light-bg-1" data-midnight="black">
      {/* container start */}
      <div className="container top-padding-120">
        {/* text-center start */}
        <div data-animation-container className="text-center">
           <h2 data-animation-child className="large-title text-height-10 text-color-1 overlay-anim-box2" data-animation="overlay-anim2">Reasons To Choose React Native App Development</h2><br /> 
          <p data-animation-child className="fade-anim-box tr-delay02 text-color-1 xsmall-title-oswald top-margin-5" data-animation="fade-anim">Investigating the upsides of React Native advancement, a colossal number of associations have moved to this development. Following is the summary of benefits React Native application improvement offers.</p>
          <br/>
          <br/>
        </div>{/* text-center end */}
        {/* flex-container start */}
        <div className="flex-container response-999 top-padding-60">
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                 <img className="hover-img" src="assets/images/backgrounds/DT.webp" alt="Digital products" style={{width: 200}} />
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Devoted Team</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">Assuming you address an organization with a venture that necessities submitted thought, get a few data about committed groups. It's a pay-more only as costs arise month to month moving agreement.
<br/><ul>
<li><b> ✓ </b> No secret charges</li>
<li><b> ✓ </b>160+ Hours of ensured work</li>
<li><b> ✓ </b>Month to month charging (Monthly charging)</li>
<li><b> ✓ </b>Pay only for quantifiable work </li> </ul> </p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                 <img className="hover-img" src="assets/images/backgrounds/CA.webp" alt="Digital products" style={{width: 200}} /> 
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className=" text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Controlled Agile</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">The controlled deft commitment model suits best for the people who have a limited spending plan and still require some versatility against the advancing conditions.
                <br/><ul>
<li><b> ✓ </b> Optimal versatility</li>
<li><b> ✓ </b>Deft Team</li>
<li><b> ✓ </b>Little undertakings</li>
<li><b> ✓ </b>Limitless oversight over monetary arrangement </li> </ul> </p>

                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                 <img className="hover-img" src="assets/images/backgrounds/time-and-material.webp" alt="Digital products" style={{width: 200}} /> 
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Time and Material</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">Assuming you address an organization with indistinct undertakings and need continuing work, get some data about hourly. It's a pay-more only as costs arise hour insightful moving agreement.
                <br/><ul>
<li><b> ✓ </b> No secret costs</li>
<li><b> ✓ </b>Necessity based working hours</li>
<li><b> ✓ </b>Month to month charging</li>
<li><b> ✓ </b>Pay only for quantifiable work </li> </ul> </p>
                <br />
                
              </div>
              
            </a>
          </div>
          {/* column end */}
          
        </div>{/* flex-container end */}
      </div>{/* container end */}
    </section>{/* section end */}  


            
        {/* flex-container end */}
    <ClientLogo/>
</>

        )
    }
}
