import React, { useState } from 'react'
import axios from "axios";
import { NavLink } from 'react-router-dom';
import Footer from '../component/Footer';
import Portfolio from '../component/Portfolio'
import SwiperSlidee from '../component/SwiperSlidee'
import ClientLogo from '../component/ClientLogo'
export default function Home() {
  const [first_name, setfirst_name] = useState("");
  const [email, setemail] = useState("");
  const [phone_number, setphone_number] = useState("");
    const Submit = () => {
        if (first_name === "" || email === "" || phone_number === "") {
           alert('please fill the form')
        }
        const formData = new FormData();
        formData.append("name", first_name);
        formData.append("email", email);
        formData.append("phone_no", phone_number);
        axios.post("https://hnhbackend.hnhtechsolutions.com/api/main", formData, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                console.log("res", res.data);
                if (res.data) {
                  alert('success')

                } else {
                  alert('error')

                }
            });
    };
    const updateState = () => {
      first_name("")
      email("")
      phone_number("")
    }

  return (

    <>
      {/* page-head start */}
      <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
        {/* page-head-bg */}
        <video width="300"  src="./assets/video/Quantilyics.mp4" type="video.webm" muted loop autoPlay />
        {/* flex-min-height-inner start */}
        <div className="flex-min-height-inner">
          {/* container start */}
          <div className="container top-bottom-padding-120 " >
            <div className='row homehero'>

              <div className='six-column setmargin'>
                {/* <h1 className="overlay-loading2 medium-title red-color">HNH </h1> 
                   <h2 className="large-title-bold-arz text-color-4"><span className="overlay-loading2 overlay-light-bg-1 tr-delay01">TECH </span></h2> 
                   <h2 className="overlay-loading2 medium-title red-color">SOLUTION </h2>  */}
              </div>
              <div className='six-column setmargin02' >

                <div className="header-quote-form"
                  // img src="assets/images/backgrounds/top_shape.png"
                  style={{ width: '100% ,!important', borderTop: '10px solid #00A8E8', borderBottom: '10px solid #00A8E8' }}>
                  <div className="form-header-text red-color">
                    Fill out the form below and our <br />
                    expert will reach out to you

                  </div> <br />


                  <div>
                    <form>
                      <div className="form-group-home">
                        <input
                          type="text"
                          placeholder="Your name"
                          name="name"
                          className="formhome"
                          required
                          value={first_name}
                          onChange={(e) => { setfirst_name(e.target.value) }}

                        />
                      </div> <br />
                      <div className="form-group-home">
                        <input
                          type="email"
                          placeholder="Email"
                          name="email"
                          className="formhome"
                          required
                          value={email}

                          onChange={(e) => { setemail(e.target.value) }}
                        />
                      </div><br />
                      <div className="form-group-home">
                        <input
                          type="Number"
                          placeholder="Phone"
                          name="Phone"
                          className="formhome"
                          required
                          value={phone_number}

                          onChange={(e) => { setphone_number(e.target.value) }}
                        />
                      </div><br />
                      <div>
                        <button type="button" onClick={() => { Submit(); updateState() }} className='arrow-btn pointer-large border setformbtn' >
                          Get A Quote
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>


            </div>

          </div>{/* container end */}
        </div>{/* flex-min-height-inner end */}
        {/* scroll-btn start */}
        <a href="#down" className="scroll-btn pointer-large">
          <div className="scroll-arrow-box">
            <span className="scroll-arrow" />
          </div>
          <div className="scroll-btn-flip-box">
            <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
          </div>
        </a>{/* scroll-btn end */}
      </section>
      {/* page-head end */}
      <section id="down" className="home-slider bottom-padding-30 top-padding-120 " style={{ background: '#f5f5f5 !important' }} data-midnight="black">
        {/* container start */}
        <div data-animation-container className=" container small bottom-padding-60 text-center">
          <h1 data-animation-child className="large-title text-height-10 text-color-1 overlay-anim-box2" data-animation="overlay-anim2">Recent Work</h1><br />
          <h4 data-animation-child className="fade-anim-box tr-delay02 text-color-1 xsmall-title-oswald top-margin-5" data-animation="fade-anim">We build powerful digital solutions and experiences.</h4>
        </div>{/* container end */}
      </section>
      {/* dark-bg-2 start */}
      <section id="up" className="dark-bg-2 flex-min-height-box" style={{ display: 'none' }}>
        <div className="container">
          <div className="flex-container response-999 top-padding-60">
            {/* column start */}
            <div className="four-columns bottom-padding-60">
              <a data-animation-container href="360App" className="content-right-margin-20 hover-box pointer-large d-block light-bg-2 animated">
                <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box animated overlay-anim2" data-animation="overlay-anim2">
                  <img className="hover-img" src="assets/images/project/360/iport.webp" alt="Digital products" />
                </div>
                <div className="expertise content-padding-l-r-20 content-padding-bottom-20">
                  <h3 data-animation-child className="mrgn medium-title-oswald text-color-1 hover-content fade-anim-box tr-delay01 animated fade-anim" data-animation="fade-anim">360 Website and Mobile App</h3><br />
                  <p data-animation-child className="mrgn p-style-xsmall text-color-1 hover-content fade-anim-box tr-delay02 animated fade-anim" data-animation="fade-anim">An ecommerce mobile app aim at providing an immersive and unique experience that makes online shopping a much simpler and seamless process.</p>
                </div>
              </a>
            </div>{/* column end */}
            {/* column start */}
            <div className="four-columns bottom-padding-60">
              <a data-animation-container href="AmnayaApp" className="content-left-right-margin-10 hover-box pointer-large d-block light-bg-2 animated">
                <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box animated overlay-anim2" data-animation="overlay-anim2">
                  <img className="hover-img" src="assets/images/project/amnaya/iportfolio.webp" alt="Digital products" />
                </div>
                <div className="expertise content-padding-l-r-20 content-padding-bottom-20">
                  <h3 data-animation-child className="medium-title-oswald text-color-1 hover-content fade-anim-box tr-delay01 animated fade-anim" data-animation="fade-anim">Amnaya App</h3><br />
                  <p data-animation-child className="p-style-xsmall text-color-1 hover-content fade-anim-box tr-delay02 animated fade-anim" data-animation="fade-anim">A web and app base open content online botanical encyclopedia created through the collaborative effort of a community of users known as contributor, reader and admin</p>
                </div>
              </a>
            </div>{/* column end */}
            {/* column start */}
            <div className="four-columns bottom-padding-60">
              <a data-animation-container href="Americren" className="content-left-margin-20 hover-box pointer-large d-block light-bg-2 animated">
                <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box animated overlay-anim2" data-animation="overlay-anim2">
                  <img className="hover-img" src="assets/images/project/americren_app/iportfolio.webp" alt="Digital products" />
                </div>
                <div className="expertise content-padding-l-r-20 content-padding-bottom-20">
                  <h3 data-animation-child className="medium-title-oswald text-color-1 hover-content fade-anim-box tr-delay01 animated fade-anim" data-animation="fade-anim">Americren App</h3><br />
                  <p data-animation-child className="p-style-xsmall text-color-1 hover-content fade-anim-box tr-delay02 animated fade-anim" data-animation="fade-anim">The American Consumer Real Estate Network. Americren is the first and only web site dedicated with quickly and easily connecting the consumer with the top producing Realtor or Real Estate Agent in the consumer’s marketplace.</p>
                </div>
              </a>
            </div>{/* column end */}
            {/* column start */}
            <div className="four-columns bottom-padding-60">
              <a data-animation-container href="AmnayaWeb" className="content-left-right-margin-10 hover-box pointer-large d-block light-bg-2">
                <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box" data-animation="overlay-anim2">
                  <img className="hover-img" src="assets/images/project/amnaya/1.webp" alt="Digital products" />
                </div>
                <div className="expertise content-padding-l-r-20 content-padding-bottom-20">
                  <h3 data-animation-child className="medium-title-oswald text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Amnaya Web</h3><br />
                  <p data-animation-child className="p-style-xsmall text-color-1 hover-content fade-anim-box tr-delay02" data-animation="fade-anim">A web and app base open content online botanical encyclopedia created through the collaborative effort of a community of users known as contributor, reader and admin</p>
                </div>
              </a>
            </div>{/* column end */}
            {/* column start */}
            <div className="four-columns bottom-padding-60">
              <a data-animation-container href="Cyptogram" className="content-left-margin-20 hover-box pointer-large d-block light-bg-2">
                <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box" data-animation="overlay-anim2">
                  <img className="hover-img" src="assets/images/project/cypto/iportfolio.webp" alt="Digital products" />
                </div>
                <div className="expertise content-padding-l-r-20 content-padding-bottom-20">
                  <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Cryptogram </h3><br />
                  <p data-animation-child className="p-style-xsmall text-color-1 hover-content fade-anim-box tr-delay02" data-animation="fade-anim">Cryptogram is an app base project for crypto currencies trading and exchange where traders, financial experts and investors’ meets at a common platform, </p>
                </div>
              </a>
            </div>{/* column end */}
            <div className="four-columns bottom-padding-60">
              <a data-animation-container href="Drivementor" className="content-left-margin-20 hover-box pointer-large d-block light-bg-2">
                <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box" data-animation="overlay-anim2">
                  <img className="hover-img" src="assets/images/project/drive-mentor/1.webp" alt="Digital products" />
                </div>
                <div className="expertise content-padding-l-r-20 content-padding-bottom-20">
                  <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Drive Mentor</h3><br />
                  <p data-animation-child className="p-style-xsmall text-color-1 hover-content fade-anim-box tr-delay02" data-animation="fade-anim">DriveMentor is a professional website perfect for driving instructors or other training professionals. It is also a service booking web for finding driving instructors for all over the UK with trainee and trainer portals.</p>
                </div>
              </a>
            </div>{/* column end */}
            <div className="four-columns bottom-padding-60">
              <a data-animation-container href="Ezigo" className="content-left-margin-20 hover-box pointer-large d-block light-bg-2">
                <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box" data-animation="overlay-anim2">
                  <img className="hover-img" src="assets/images/project/ezigo/iportfolio.webp" alt="Digital products" />
                </div>
                <div className="expertise content-padding-l-r-20 content-padding-bottom-20">
                  <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Ezigo </h3><br />
                  <p data-animation-child className="p-style-xsmall text-color-1 hover-content fade-anim-box tr-delay02" data-animation="fade-anim">Ezigo is an app-based car service that connects people to rides. The Company designs an online booking platform that connects passengers with local drivers.</p>
                </div>
              </a>
            </div>
            {/* column end */}
            <div className="four-columns bottom-padding-60">
              <a data-animation-container href="SafetyWorkerApp" className="content-left-margin-20 hover-box pointer-large d-block light-bg-2">
                <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box" data-animation="overlay-anim2">
                  <img className="hover-img" src="assets/images/project/safety_worker/iportfolio.webp" alt="Digital products" />
                </div>
                <div className="expertise content-padding-l-r-20 content-padding-bottom-20">
                  <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Safety Worker App</h3><br />
                  <p data-animation-child className="p-style-xsmall text-color-1 hover-content fade-anim-box tr-delay02" data-animation="fade-anim">An online app for safety hazards at workplace specifically design with online alerts, warnings and safety measures while exploring mercury.</p>
                </div>
              </a>
            </div>
            <div className="four-columns bottom-padding-60">
              <a data-animation-container href="OPDApp" className="content-left-margin-20 hover-box pointer-large d-block light-bg-2">
                <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box" data-animation="overlay-anim2">
                  <img className="hover-img" src="assets/images/project/opd/iportfolio.webp" alt="Digital products" />
                </div>
                <div className="expertise content-padding-l-r-20 content-padding-bottom-20">
                  <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">OPD App</h3><br />
                  <p data-animation-child className="p-style-xsmall text-color-1 hover-content fade-anim-box tr-delay02" data-animation="fade-anim">OPD is more than an app for finding doctors. Whether it’s booking doctor appointments, ordering medicines, scheduling diagnostic tests or having an online consultation with your doctor, </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>


      <section className="for-container home-slider">

        <SwiperSlidee />
      </section>

      <section className="clientlogo">


        <ClientLogo></ClientLogo>


      </section>

      {/* <section className="dark-bg-1 top-bottom-padding-120"> */}
      {/* portfolio-slider2 start */}
      {/* <Portfolio/> */}
      {/* portfolio-slider2 end */}
      {/* </section> */}


      <section className="home-slider bottom-padding-30 top-padding-120 " style={{ background: '#f5f5f5 !important' }} data-midnight="black">
        <div data-animation-container className="container small bottom-padding-60 text-center">
          <h1 data-animation-child className="large-title text-height-10 text-color-1 overlay-anim-box2" data-animation="overlay-anim2">Why Quantilyticss</h1><br />
          <h4 data-animation-child className="fade-anim-box tr-delay02 text-color-1 xsmall-title-oswald top-margin-5" data-animation="fade-anim">We build powerful digital solutions and experiences.</h4>
        </div>
        {/* bottom-padding-90 start */}
        <div className="bottom-padding-90">
          {/* portfolio-content start */}
          <div className="portfolio-content flex-min-height-box">
            {/* portfolio-content-inner start */}
            <div className="portfolio-content-inner flex-min-height-inner">
              {/* flex-container start */}
              <div className="flex-container container small">
                {/* column start */}
                <div data-animation-container className="six-columns ">
                  <div className="content-right-margin-60">
                    <h3 className="title-style text-color-1">
                      <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01" data-animation="overlay-anim2">WEB PORTAL</span><br />
                    </h3>
                    <p className="small-title text-color-0 text-center">A Web Portal Is A Specially Designed Website That Brings Information From Diverse Sources, Like Emails, Online Forums And Search Engines, Together In A Uniform Way.  </p>
                  </div>
                </div>{/* column end */}
                {/* column start */}
                <div className="six-columns top-padding-60">
                  <a href="#" className=" portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link">
                    <div className="portfolio-mb portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2" data-animation="overlay-anim2" style={{ backgroundImage: 'url(assets/images/backgrounds/sideImage.jpg)' }} />
                  </a>
                </div>{/* column end */}
              </div>{/* flex-container end */}
            </div>{/* portfolio-content-inner end */}
          </div>{/* portfolio-content end */}
        </div>{/* bottom-padding-90 end */}
        {/* bottom-padding-90 start */}
        <div className="bottom-padding-90">
          {/* portfolio-content start */}
          <div className="portfolio-content flex-min-height-box">
            {/* portfolio-content-inner start */}
            <div className="portfolio-content-inner flex-min-height-inner">
              {/* flex-container start */}
              <div className="flex-container reverse container small">
                {/* column start */}
                <div className="six-columns top-padding-60">
                  <a href="#" className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link">
                    <div className="portfolio-mb portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2" data-animation="overlay-anim2" style={{ backgroundImage: 'url(assets/images/backgrounds/contman.webp)' }} />
                  </a>
                </div>{/* column end */}
                {/* column start */}
                <div data-animation-container className="six-columns">
                  <div className="content-left-margin-40">
                    <h3 className="title-style text-color-1">
                      <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01" data-animation="overlay-anim2">CONTENT MANAGEMENT SYSTEM</span><br />
                    </h3>
                    <p className="small-title text-color-0 text-center pset">Content Management Is The Process Of Organizing And Consolidating These Pieces Of Content (Text, Graphics, And Multimedia Clips) And Tagging Schemes(Xml, Html, Etc) In The Most Efficient Way And Storing Them Only On Etime In A Repository, Known As A Content Management System.</p>

                  </div>
                </div>{/* column end */}
              </div>{/* flex-container end */}
            </div>{/* portfolio-content-inner end */}
          </div>{/* portfolio-content end */}
        </div>{/* bottom-padding-90 end */}
        {/* bottom-padding-90 start */}
        <div className="">
          {/* portfolio-content start */}
          <div className="portfolio-content flex-min-height-box">
            {/* portfolio-content-inner start */}
            <div className="portfolio-content-inner flex-min-height-inner">
              {/* flex-container start */}
              <div className="flex-container container small">
                {/* column start */}
                <div data-animation-container className="six-columns">
                  <div className="content-right-margin-40">
                    <h3 className="title-style text-color-1">
                      <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01" data-animation="overlay-anim2">ONLINE PAYMENT SOLUTIONS</span><br />
                    </h3>
                    <p className="small-title text-color-0 text-center pset">The Payment Possibilities Company. No Contracts, No Cancellation Fees. A Full Range Of Solutions For Every Industry. Customize Your Solution &amp; Pricing Now. Free Terminal Options. No Long-term Contracts. </p>

                  </div>
                </div>{/* column end */}
                {/* column start */}
                <div className="six-columns top-padding-60">
                  <a href="ecommerce.php" className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link">
                    <div className="portfolio-mb portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2" data-animation="overlay-anim2" style={{ backgroundImage: 'url(assets/images/backgrounds/onlinepayment.webp)' }} />
                  </a>
                </div>{/* column end */}
              </div>{/* flex-container end */}
            </div>{/* portfolio-content-inner end */}
          </div>{/* portfolio-content end */}
        </div>{/* bottom-padding-90 end */}
      </section>

      {/* <section className="home-slider bottom-padding-30 top-padding-120 " style={{ background: '#f5f5f5 !important' }} data-midnight="black">
          <div data-animation-container className="container small bottom-padding-60 text-center">
            <h1 data-animation-child className="large-title text-height-10 text-color-1 overlay-anim-box2" data-animation="overlay-anim2">Why Quantilyticss</h1><br />
            <h4 data-animation-child className="fade-anim-box tr-delay02 text-color-1 xsmall-title-oswald top-margin-5" data-animation="fade-anim">We build powerful digital solutions and experiences.</h4>
            <div class="flex-container top-padding-50">
              <div class="six-columns top-padding-120  ">
                <img className="bottom-padding-50  right-padding-50 hover-img" src="assets\images\backgrounds\sideImage.jpg" alt="Side Image" />
                <img className="top-padding-50 hover-img " src="assets\images\backgrounds\onlinepayment.jpeg" alt="Side Image" />

              </div>
              <div class="six-columns">
                <div class="flex-container top-bottom-padding-40">
                  <div class="two-columns">
                    <h1 class="why-us-icon text-center right-padding-100 why-us-head"><i class="fa fa-signal red-color" aria-hidden="true"></i></h1>
                  </div>
                  <div class="seven-columns">
                    <h1 class="why-us-head">WEB PORTAL</h1>
                    <p class="why-us-content why-us-head2">A WEB PORTAL IS A SPECIALLY DESIGNED WEBSITE THAT BRINGS INFORMATION FROM DIVERSE SOURCES, LIKE EMAILS, ONLINE FORUMS AND SEARCH ENGINES, TOGETHER IN A UNIFORM WAY. USUALLY, EACH</p>
                  </div>
                  <div class="three-columns">
                  </div>
                </div>
                <div class="flex-container top-bottom-padding-40">
                  <div class="two-columns">
                    <h1 class="why-us-icon text-center right-padding-100 why-us-head"><i class="fa fa-graduation-cap red-color" aria-hidden="true"></i></h1>
                  </div>
                  <div class="seven-columns">
                    <h1 class="why-us-head">CONTENT MANAGEMENT</h1>
                    <p class="why-us-content why-us-head2 text-center pset">CONTENT MANAGEMENT IS THE PROCESS OF ORGANIZING AND CONSOLIDATING THESE PIECES OF CONTENT (TEXT, GRAPHICS, AND MULTIMEDIA CLIPS) AND TAGGING SCHEMES (XML, HTML, ETC,) IN THE MOST EFFICIENT WAY AND STORING THEM ONLY ONE TIME IN A REPOSITORY, KNOWN AS A CONTENT MANAGEMENT SYSTEM.</p>
                  </div>
                  <div class="three-columns">
                  </div>
                </div>
                <div class="flex-container top-bottom-padding-40">
                  <div class="two-columns">
                    <h1 class="why-us-icon text-center right-padding-100 why-us-head"><i class="fa fa-laptop red-color" aria-hidden="true"></i></h1>
                  </div>
                  <div class="seven-columns">
                    <h1 class="why-us-head">ONLINE PAYMENT SOLUTIONS</h1>
                    <p class="why-us-content why-us-head2">THE PAYMENT POSSIBILITIES COMPANY. NO CONTRACTS, NO CANCELLATION FEES. A FULL RANGE OF SOLUTIONS FOR EVERY INDUSTRY. CUSTOMIZE YOUR SOLUTION &amp; PRICING NOW. FREE TERMINAL OPTIONS. NO LONG-TERM CONTRACTS.</p>
                  </div>
                  <div class="three-columns">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      {/* <div className=" why-hnh-video video-content-bg why-video" style={{}}>
          <video className="video-why-hnh" src="./assets/video/why-hnh.webm" type="video.webm" muted loop controls autoPlay />
        </div> */}
      <section className="">
        <div className="container-fuild ">
          {/* column start */}
          {/* <div className="six-columns bottom-padding-60">
              <div data-animation-container className="content-right-margin-20 animated">
                <h3 className="title-style text-color-4">
                  <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1 tr-delay01 animated overlay-anim2" data-animation="overlay-anim2">Motivation That Drives Us</span><br />
                </h3>
                <ul className="list-dots">
                  <li>
                    <p data-animation-child className="p-letter-style text-color-4 fade-anim-box tr-delay04 animated fade-anim" data-animation="fade-anim">ONE EXTRA EFFORTS IN WHATEVER WE</p>
                  </li>
                  <li>
                    <p data-animation-child className="p-letter-style text-color-4 fade-anim-box tr-delay05 animated fade-anim" data-animation="fade-anim">UNDERTAKE—SEPARATES GOOD FROM</p>
                  </li>
                  <li>
                    <p data-animation-child className="p-letter-style text-color-4 fade-anim-box tr-delay06 animated fade-anim" data-animation="fade-anim">GREAT. WE UNDERSTAND THAT TO GET</p>
                  </li>
                  <li>
                    <p data-animation-child className="p-letter-style text-color-4 fade-anim-box tr-delay07 animated fade-anim" data-animation="fade-anim">WHAT WE NEVER HAD, WE MUST DOWHAT WE HAVE NEVER DONE.</p>
                  </li>
                </ul>
              </div>
            </div> */}
          {/* column end */}
          {/* column start */}
          {/* <div className="twelve-columns ">
            <a data-animation-container href="#" className="hover-box pointer-large d-block animated">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-1 team-img-box animated overlay-anim2" data-animation="overlay-anim2">
                <video src="./assets/video/hnh.webm" type="video.webm" width="100%" muted loop controls autoPlay />
              </div>
            </a>
          </div> */}
          {/* column end */}
          {/* column start */}
        </div>
        {/* flex-container end */}
      </section>

      <section className=" dark-bg-1">
        <div data-animation-container className="container small top-padding-10 bottom-padding-60 text-center">
          <h1 data-animation-child className="mp4-text large-title text-height-10 text-color-4 overlay-anim-box2" data-animation="overlay-anim2">Quantilyticss explain itself through animation</h1><br />
        </div>{/* container end */}
        <div className="container direction-responsive flex-min-height-box mt-5" style={{ marginTop: "15%" }}>
          <div className="four-columns">
            <div className="content-right-margin-20  bottom-padding-60">
              <video src="./assets/video/hnh3.webm" width="440" type="video.webm" muted loop autoPlay />
            </div>
          </div>
          <div className="four-columns">
            <div className="content-right-margin-20">
              <video src="./assets/video/hnh4.webm" width="440" type="video.webm" muted loop autoPlay />
            </div>
          </div>
          <div className="four-columns">
            <div className="content-right-margin-20">
              <video src="./assets/video/hnh5.webm" width="440" type="video.webm" muted loop autoPlay />
            </div>
          </div>
        </div>
      </section>{/* dark-bg-2 end */}
      {/* price-table end */}
      {/* <Footer/> */}
    </>
  )
}

