import React, { Component, useState, useEffect } from 'react'
// import TawkTo from 'tawkto-react';
// import Loader from './Loader';
const Head = () => {
    return (
        <div>
            <header className="fixed-header" style={{ minHeight: "137px" }}>
                {/* header-flex-box start */}
                <div className="header-flex-box">
                    {/* logo start */}
                    <a href="/" className="logo pointer-large animsition-link">
                        <div className="logo-img-box">
                            <img className="logo-white" src="../../assets/images/logo/qlogo.png" height="110px" alt="logo" />
                            <img className="logo-black" src="../../assets/images/logo/qlogo.png" height="110px" alt="logo" />

                        </div>
                    </a>{/* logo end */}
                    {/* menu-open start */}
                    <div className="menu-open pointer-large">


                        <span className="hamburger" />
                    </div>{/* menu-open end */}
                </div>
                {/* header-flex-box end */}
                {/* <ReactWhatsapp className='scrollbar-hidden' style={{ fontSize: '60px', marginTop: '78vh', padding: "25px" }} number="+1(647)660-1619" message="Hi" >
                    <i class="fab fa-whatsapp" style={{ color: '#e7edf0', backgroundColor: 'green', borderRadius: "60%", lineHeight: "0.86" }} aria-hidden="true"></i>
                </ReactWhatsapp> */}
            </header>

        </div>
    )
}
export default Head;