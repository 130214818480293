import React, { Component } from 'react'
import ClientLogo from '../component/ClientLogo'

export default class Vfx extends Component {
  render() {
    return (
      <>
        {/* page-head start */}
        <section id="up" className="page-head flex-min-height-box dark-bg-aman typewriter-home">
          {/* page-head-bg */}
          <div className="page-head-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/Art-of-VFX-logo.jpg)' }} />
          <div className="bg-overlay" />
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* container start */}
            <div className="container top-bottom-padding-120">
              <h2 className="overlay-loading2 medium-title red-color">VFX</h2>
              <h3 className="large-title-bold text-color-4">
                <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">VFX OF ARTIST</span><br />
              </h3>
              <p className="d-flex-wrap top-margin-20 text-color-4">
                <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
              </p>
            </div>{/* container end */}
          </div>{/* flex-min-height-inner end */}
          {/* scroll-btn start */}
          <a href="#down" className="scroll-btn pointer-large">
            <div className="scroll-arrow-box">
              <span className="scroll-arrow" />
            </div>
            <div className="scroll-btn-flip-box">
              <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
            </div>
          </a>{/* scroll-btn end */}
          {/* flex-min-height-box start */}

          <div className="col-lg-12 col-xl-12 col-md-12">
            <h1 className="f-ebold">
              <div className="text-center top-bottom-padding-120 red-bg" data-midnight="black">
                <span className="large-title">VFX ARTIST</span>
              </div>
            </h1>
          </div>

          {/* flex-min-height-box start */}
        </section>{/* page-head end */}
        <div className="col-lg-12 col-xl-12 col-md-12">
          <h1 className="f-ebold">
            <div className="text-center top-bottom-padding-120 red-bg" data-midnight="black">
              <span className="large-title">VFX ARTIST</span>
            </div>
          </h1>
        </div>
        <section id="down" className="dark-bg-1 flex-min-height-box ">
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* container start */}
            <div className="container small top-bottom-padding-120">
              {/* flex-container start */}
              <div data-animation-container className="flex-container">
                {/* column start */}
                <div className="twelve-columns text-center">
                  <h2 className="large-title text-height-12">
                    <span data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="Job Description">Job Description</span><br />
                    <div className="content-right-margin-20">
                      <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">VFX artists use software to create animations and special effects. They typically work on movies, television shows, or video games. This position is usually offered full time, but part-time positions are also available. When deadlines are tight, VFX artists may work late nights or on weekends. Since they use computer software, VFX artists work in an office environment, but they may also work at filming locations to help shoot scenes, and they may have to travel frequently for the job. To ensure maximum visual quality, they work closely with engineering and design teams. This career is well-suited to those who have a knack for visual designs.</p>
                    </div>
                  </h2>
                  <h2 className="large-title text-height-20">
                    <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="VFX Artist Duties, and , Responsibilities">VFX Artist Duties, and , Responsibilities</span>
                    <div className="content-right-margin-20">
                      <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">No two VFX artists are exactly alike, as specific skills vary from employer to employer. However, after analyzing online job postings, we identified several core duties and responsibilities common to the job:</p>
                    </div>
                  </h2>
                </div>{/* column end */}
                {/* column start */}

                <div className='four-columns top-padding-120'>
                  <div className='text-center'>
                    <h1 className="f-ebold theme-blue" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Create Visual Effects"> Create Visual Effects</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      VFX artists create realistic visual effects using particles, animation, sprites, materials, and
                      more.
                    </p>
                  </div>
                </div>
                <div className='four-columns top-padding-120'>
                  <div className='text-center'>
                    <h1 className="large-title text-height-20" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Create Motion Graphics"> Create Motion Graphics</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      Using software, VFX artists create visually engaging motion graphics and animations. This
                      sometimes
                      involves working with two- or three-dimensional models. The animations and graphics they create
                      are
                      stylistically consistent and enhance the visual style of their work.
                    </p>
                  </div>
                </div>
                <div className='four-columns top-padding-120'>
                  <div className='text-center'>
                    <h1 className="large-title text-height-20" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Tools of the Trade"> Tools of the Trade</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      VFX artists are familiar with the following tools:
                      <br />
                      Adobe software (After Effects, Photoshop, Illustrator, Premiere Pro)
                    </p>
                  </div>
                </div>
                <div className='container'>
                  <div className='row'>
                    <div className='six-columns top-padding-90'>
                      <div className='text-center'>
                        <h1 className="f-ebold theme-blue" id="linerText">
                          <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Operate Camera, Lights,and , Microphone">Operate Camera, Lights,and , Microphone</span>
                        </h1>
                        <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                          VFX artists create realistic visual effects using particles, animation, sprites, materials, and
                          more.
                        </p>
                      </div>
                    </div>
                    <div className='six-columns top-padding-90'>
                      <div className='text-center'>
                        <h1 className="large-title text-height-20" id="linerText">
                          <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Collaborate with Engineers and, Designers">Collaborate with Engineers and, Designers</span>
                        </h1>
                        <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                          Working within a team, VFX artists collaborate with engineers, designers, and team leads to
                          create visual assets.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>{/*end*/}
                <div className='six-columns top-padding-120'>
                  <div className='text-center'>
                    <h1 className="large-title text-height-20" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="VFX Artist Skills and Qualifications">VFX Artist Skills and Qualifications</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      VFX artists have a proven ability to create visual effects, and they have an eye for visuals and
                      art. Employers usually prefer VFX artists to have a degree in graphic arts or a similar field,
                      as well as one to three years of relevant experience. In addition, the following skills and
                      qualifications are essential to the job:
                    </p>
                    <br />
                    <ul className='text-left-aman'>
                      <li><strong>Animation experience-</strong>
                        <p>VFX artists have an eye for engaging visuals, and they have experience creating
                          high-quality images. They understand motion, animation, scaling, and timing</p></li>
                      <li><strong>Video production – </strong>
                        <p>VFX artists understand the technical aspects of video production, such as staging, sound,
                          and lighting</p></li>
                      <li><strong>Computer proficiency –</strong>
                        <p>VFX artists are comfortable using computer tools and software to create visual effects or
                          to enhance their work</p></li>
                      <li><strong>Communication skills –</strong>
                        <p>working with other team members requires strong communication and collaboration skills.
                          VFX artists discuss needs for projects and work with others to ensure goals are met and
                          that the final product is satisfactory</p></li>
                      <li><strong>Time management – </strong>
                        <p> VFX artists juggle deadlines and projects. Their time management skills help them maintain a clear and consistent schedule
                        </p></li>
                    </ul>
                  </div>
                </div>
                <div className='six-columns top-padding-120-aman'>
                  <div className='text-center'>
                    <h1 className="f-ebold theme-blue" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Helpful Resources">Helpful Resources</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      Do you want to learn more about being a VFX artist? We’ve gathered some helpful resources to get you started:
                    </p>
                    <ul className='text-left-aman'>
                      <li><strong>Story Structure and Development – </strong>
                        <p>Craig Caldwell outlines the essentials to a story, such as fundamental principles and structures for animators, designers, and artists, and universal patterns for stories and narratives. The book explains how these essential principles can be incorporated into to one’s work</p></li>
                      <li><strong>Adobe Photoshop for VFX Artists –</strong>
                        <p> VFX artists frequently use Adobe Photoshop in their work. Author Loopsie Schwartz guides readers through using Photoshop to create three-dimensional images by creating textures and background paintings</p></li>
                      <li><strong>The Art of VFX –</strong>
                        <p>this blog offers industry news for VFX artists. It contains interviews with visual effects artists who worked on major films and features trailers full of engaging visuals from video games and movies</p></li>
                      <li><strong>Communication skills –</strong>
                        <p>working with other team members requires strong communication and collaboration skills.
                          VFX artists discuss needs for projects and work with others to ensure goals are met and
                          that the final product is satisfactory</p></li>
                      <li><strong>Motionographer – </strong>
                        <p> serving as an inspiration to artists and designers, this blog features motion animation work from designers, freelancers, and students. It also contains a variety of stories and commentary from artists on issues and topics in the industry</p></li>
                      <li><strong>CG Channel – </strong>
                        <p> bringing together entertainment artists, this blog features artist profiles, reviews, and production coverage. Helpful articles guide readers through processes such as landing a job in the industry, creating three-dimensional VFX, and sculpting believable animation</p></li>
                    </ul>
                  </div>
                </div>
                <div className='six-columns top-padding-120 amanpadding'>
                  <div className='text-center'>
                    <h1 className="f-ebold theme-blue" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="VFX Artist Education and Training">VFX Artist Education and Training</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      Although there are no minimum educational requirements for this position, most VFX artists have an associate’s or bachelor’s degree in visual
                      design, animation, or art. They are familiar with animation software and art fundamentals, such as color theory, visual design, and composition. Employers prefer those with an impressive portfolio and one to three years of experience.
                    </p>
                  </div>
                </div>

                <div className='six-columns top-padding-120'>
                  <div className='text-center'>
                    <h1 className="f-ebold theme-blue" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="VFX Artist Resume Help">VFX Artist Resume Help</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      The average yearly salary for VFX artists, categorized by the Bureau of Labor Statistics as multimedia artists and animators, is $70,000. The lowest 10 percent of VFX artists earn less than $39,000; the highest 10 percent make over $123,000.
                      <br />
                      This profession is expected to grow 8 percent within the next 10 years, which is the average for all occupations. As demand increases for video games, movies, and television
                      series, there will also be a growing demand for VFX artists.
                    </p>
                  </div>
                </div>
                <div className='twovelve-columns padding-class-aman top-padding-120'>
                  <div className='text-center'>
                    <h1 className="f-ebold theme-blue" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text=" VFX Artist Resume Help"> VFX Artist Resume Help</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      Explore these related job titles from our database of hundreds of thousands of expert-approved resume samples:
                    </p>
                  </div>
                </div>

                {/* aman end */}
              </div>{/* flex-container end */}
            </div>{/* container end */}
          </div>{/* flex-min-height-inner end */}
        </section>{/* flex-min-height-box end */}
        <ClientLogo></ClientLogo>
      </>

    )
  }
}
