import React, { Component } from 'react'
import ClientLogo from '../component/ClientLogo'

export default class Frontend extends Component {
    render() {
        return (
            <>
  {/* page-head start */}
  <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
    {/* page-head-bg */}
    <div className="page-head-bg" style={{backgroundImage: 'url(assets/images/backgrounds/frontenddev1.webp)'}} />
    <div className="bg-overlay" />
    {/* flex-min-height-inner start */}
    <div className="flex-min-height-inner">
    <div className="container top-bottom-padding-120">
              <h2 className="overlay-loading2 medium-title red-color">FRONTEND</h2>
              <h3 className="large-title-bold text-color-4-arz">
                <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">DEVELOPMENT</span><br />
              </h3>
              <p className="d-flex-wrap top-margin-20 text-color-5-fr">
                <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
              </p>
            </div>     {/* container start */}
    </div>{/* flex-min-height-inner end */}
    {/* scroll-btn start */}
    <a href="#down" className="scroll-btn pointer-large">
      <div className="scroll-arrow-box">
        <span className="scroll-arrow" />
      </div>
      <div className="scroll-btn-flip-box">
        <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
      </div>
    </a>{/* scroll-btn end */}
  </section>{/* page-head end */}
  {/* flex-min-height-box start */}
  <section id="down" className="light-bg-1 about-page">
    {/* <div className="container top-bottom-padding-120">
<h4 className>Team up with our front end designer to bring your thoughts into the real world, convey your image story, upgrade your visual character, and gain competitive edge on the lookout.

Our 10 years+ solid group of engineers, and coders utilize clean codes, advancement technique, most recent stages like Angular, Vue JS, Ember JS, to assemble client driven points of interaction to further develop your business development and fabricate a crowd of people for the since a long time ago run.</h4>
    </div> */}
  </section>{/* flex-min-height-box end */}
  {/* section start */}
  <section id="down" className="light-bg-1" data-midnight="black">
      {/* container start */}
      <div className="container top-padding-120">
        {/* text-center start */}
        <div data-animation-container className="text-center">
           <h2 data-animation-child className="large-title text-height-10 text-color-1 overlay-anim-box2" data-animation="overlay-anim2">FRONTEND DEVELOPMENT</h2><br /> 
          <p data-animation-child className="fade-anim-box tr-delay02 text-color-1 xsmall-title-oswald top-margin-5" data-animation="fade-anim">Team up with our front end designer to bring your thoughts into the real world, convey your image story, upgrade your visual character, and gain competitive edge on the lookout.Our 10 years+ solid group of engineers, and coders utilize clean codes, advancement technique, most recent stages like Angular, Vue JS, Ember JS, to assemble client driven points of interaction to further develop your business development and fabricate a crowd of people for the since a long time ago run.</p>
          <br/>
          <br/>
          <h2 data-animation-child className="large-title text-height-10 text-color-1 overlay-anim-box2" data-animation="overlay-anim2">WHAT WE DO</h2>
        </div>{/* text-center end */}
        {/* flex-container start */}
        <div className="flex-container response-999 top-padding-60">
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                {/* <img className="hover-img" src="assets/images/backgrounds/and.webp" alt="Digital products" style={{width: 200}} /> */}
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Front End Architecture & Design</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">We make an effective and maintainable work process utilizing spry procedure, programming practices to work on nature of frontend code.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                {/* <img className="hover-img" src="assets/images/backgrounds/apple.webp" alt="Digital products" style={{width: 200}} /> */}
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className=" text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Non-responsive to Responsive Conversion</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">We can assist you with moving your site to a responsive plan to make the locales dynamic.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                {/* <img className="hover-img" src="assets/images/backgrounds/all-aa.webp" alt="Digital products" style={{width: 200}} /> */}
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">UI/ UX Development</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">We provide an all-in-one UX solution for designing websites, mobile apps, that can create visually-stimulating experiences for the end users.</p>
                <br />
                
              </div>
              
            </a>
          </div>
          <p className="small-title centre">Our profoundly experienced Creative Designers and Consultants can assist your business with making rich client experience
Our multidisciplinary group of UI/UX specialists, plan advisors, brand tactician, and visual fashioners fuse out-of-the-crate plan thoughts, and follow best practices to convey the best client focused items and administrations.</p>
          {/* column end */}
          
        </div>{/* flex-container end */}
      </div>{/* container end */}
    </section>{/* section end */}


    {/* another page for icons */}
    <div className="flex-container response-999 top-padding-60">
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                 <img className="hover-img" src="assets/images/backgrounds/html.webp" alt="Digital products" style={{width: 200}} /> 
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">HTML</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">It shapes the fundamental structure square of a site and trains the program on how they can show the hidden substance.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                 <img className="hover-img" src="assets/images/backgrounds/CSS Ic.webp" alt="Digital products" style={{width: 200}} /> 
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className=" text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">CSS</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">It decides the size, text style, colors and other static components of the frontend. It offers various styles to organize the HTML parts on the program.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                 <img className="hover-img" src="assets/images/backgrounds/Bootstarp IC.webp" alt="Digital products" style={{width: 200}} /> 
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Bootstrap</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">It comprises of CSS-and JS-based plan layouts required for making responsive, versatile first front-end web improvement.</p>
                <br />
                
              </div>
              
            </a>
          </div>
          {/* column end */}
          
        </div>
        <div className="flex-container response-999 top-padding-60">
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                 <img className="hover-img" src="assets/images/backgrounds/JavaSc.webp" alt="Digital products" style={{width: 200}} /> 
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">JavaScript</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">It is the most famous programming language of the web which is utilized by a few sites for prearranging the website pages.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                 <img className="hover-img" src="assets/images/backgrounds/Rjs.webp" alt="Digital products" style={{width: 200}} /> 
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className=" text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">ReactJs</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">It is a java prearranging language for making customer side UIs and is utilized as a base for creating single page applications.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          {/* column start */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                 <img className="hover-img" src="assets/images/backgrounds/JQ.webp" alt="Digital products" style={{width: 200}} /> 
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">JQuery</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">It is a javascript library intended for making things like HTML report crossing and control, occasion dealing with more straightforward.</p>
                <br />
                
              </div>
              
            </a>
          </div>
          {/* column end */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                 <img className="hover-img" src="assets/images/backgrounds/Nodejs IC.webp" alt="Digital products" style={{width: 200}} /> 
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">NodeJs</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">It is an open source, back-end javascript run-time environment used for building non-blocking, event-driven servers.</p>
                <br />
                
              </div>
              
            </a>
          </div>
          {/* column end */}
          <div className="four-columns bottom-padding-60">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
              <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                 <img className="hover-img" src="assets/images/backgrounds/Angular IC.webp" alt="Digital products" style={{width: 200}} /> 
              </div>
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Angular</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">It is a JS library for building client-side user interfaces, and can be used as a base in the development of single-page applications or full-fledged mobile apps.</p>
                <br />
                
              </div>
              
            </a>
          </div>
          {/* column end */}
        </div>    
        {/* flex-container end */}
    <ClientLogo/>
</>

        )
    }
}
