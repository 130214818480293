import React from 'react'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
const prgress = () => {
    const swiper = this;
    // const interleaveOffset = ;
    for (const i = 0; i < 5; i++) {
        const slideProgress = swiper.slides[i].progress;
        const innerOffset = swiper.width * 0.5;
        const innerTranslate = slideProgress * innerOffset;
        swiper.slides[i].querySelector(".slide-bg").style.transform =
            "translate3d(" + innerTranslate + "px, 0, 0)";
        console.log("function")
    }
}
const touchstart = () => {

}
export default function SwiperSlidee() {
    return (
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            speed={1500}
            autoplay={{ delay: 5000 }}
            watchSlidesProgress={true}
            on={() => { prgress() }}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
            navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            }}

        >
            
            <SwiperSlide className="flex-min-height-box home-slide">
                {/* slide-bg */}
                <div className="img-algn slide-bg" style={{ backgroundImage: 'url(assets/images/project/360/iport.webp)' }} />
                {/* home-slider-content start */}
                <div className="home-slider-content flex-min-height-inner dark-bg-2">
                    {/* flex-container start */}
                    <div className="container top-bottom-padding-120 flex-container response-999">
                        {/* column start */}
                        <div className="six-columns six-offset">
                            <div className="content-left-margin-40">
                                <h2 className="large-title-bold">
                                    <span className="slider-overlay2 medium-title red-color" data-text="360 App">360 App</span><br />
                                </h2>
                                <h3>
                                    <span className="slider-overlay2  red-color" data-text="E-commerce">E-commerce</span>
                                </h3>
                                <h4>
                                    <span className="slider-overlay2  red-color" data-text="Established in Pakistan in 2016.">Established in Germany in 2016.</span>
                                </h4>
                                <p className="prtflio p-style-bold-up text-height-20 d-flex-wrap text-color-4 text-respons-slider">
                                    An ecommerce mobile app aim at providing an immersive and unique experience
                                    that makes online shopping a much simpler and seamless process. At the same time, it
                                    includes
                                    features that drive sales and boost revenues for your business.
                                </p>
                                <div className="slider-fade slider-tr-delay07 top-margin-30">
                                    <div className="border-btn-box border-btn-red pointer-large">
                                        <div className="border-btn-inner">
                                            <a href="/360App" className="border-btn" data-text="VIEW PROJECT">VIEW PROJECT</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* column end */}
                    </div>{/* flex-container end */}
                </div>{/* home-slider-content end */}
            </SwiperSlide>
            <SwiperSlide className="flex-min-height-box home-slide">
                {/* slide-bg */}
                <div className="img-algn slide-bg" style={{ backgroundImage: 'url(assets/images/project/americren_app/iportfolio.webp)' }} />
                {/* home-slider-content start */}
                <div className="home-slider-content flex-min-height-inner dark-bg-2">
                    {/* flex-container start */}
                    <div className="container top-bottom-padding-120 flex-container response-999">
                        {/* column start */}
                        <div className="six-columns six-offset">
                            <div className="content-left-margin-40">
                                <h2 className="large-title-bold">
                                    <span className="slider-overlay2 medium-title red-color" data-text="Americren App">Americren App</span><br />
                                </h2>
                                <h3>
                                    <span className="slider-overlay2  red-color" data-text="Real-estate App">Real-estate App</span>
                                </h3>
                                <h4>
                                    <span className="slider-overlay2  red-color" data-text="Established in Pakistan in 2016.">Established in 2019.</span>
                                </h4>
                                <p className="prtflio p-style-bold-up text-height-20 d-flex-wrap text-color-4 text-respons-slider">
                                    The American Consumer Real Estate Network. Americren is the first and only web
                                    site dedicated with quickly and easily connecting the consumer with the top producing
                                    Realtor or Real Estate Agent in the consumer’s marketplace.
                                </p>
                                <div className="slider-fade slider-tr-delay07 top-margin-30">
                                    <div className="border-btn-box border-btn-red pointer-large">
                                        <div className="border-btn-inner">
                                            <a href="/Americren" className="border-btn" data-text="VIEW PROJECT">VIEW PROJECT</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* column end */}
                    </div>{/* flex-container end */}
                </div>{/* home-slider-content end */}
            </SwiperSlide>
            <SwiperSlide className="flex-min-height-box home-slide">
                {/* slide-bg */}
                <div className="img-algn slide-bg" style={{ backgroundImage: 'url(assets/images/project/amnaya/iportfolio.webp)' }} />
                {/* home-slider-content start */}
                <div className="home-slider-content flex-min-height-inner dark-bg-2">
                    {/* flex-container start */}
                    <div className="container top-bottom-padding-120 flex-container response-999">
                        {/* column start */}
                        <div className="six-columns six-offset">
                            <div className="content-left-margin-40">
                                <h2 className="large-title-bold">
                                    <span className="slider-overlay2 medium-title red-color" data-text="Amnaya App">Amnaya App</span><br />
                                </h2>
                                <h3>
                                    <span className="slider-overlay2  red-color" data-text="Plant Management and information sharing network">Plant Management and information sharing network</span>
                                </h3>
                                <h4>
                                    <span className="slider-overlay2  red-color" data-text="Established in Pakistan in 2016.">Established in 2019.</span>
                                </h4>
                                <p className="prtflio p-style-bold-up text-height-20 d-flex-wrap text-color-4 text-respons-slider">
                                    A web and app base open content online botanical encyclopedia created through
                                    the collaborative effort of a community of users known as contributor, reader and admin.
                                    Anyone registered on the site can create an article for publication; registration is
                                    required to edit articles. (a Wikipedia type project).
                                </p>
                                <div className="slider-fade slider-tr-delay07 top-margin-30">
                                    <div className="border-btn-box border-btn-red pointer-large">
                                        <div className="border-btn-inner">
                                            <a href="/AmnayaApp" className="border-btn" data-text="VIEW PROJECT">VIEW PROJECT</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* column end */}
                    </div>{/* flex-container end */}
                </div>{/* home-slider-content end */}

            </SwiperSlide>
            <SwiperSlide className="flex-min-height-box home-slide">
                {/* slide-bg */}
                <div className="img-algn slide-bg" style={{ backgroundImage: 'url(assets/images/project/amnaya/1.webp)' }} />
                {/* home-slider-content start */}
                <div className="home-slider-content flex-min-height-inner dark-bg-2">
                    {/* flex-container start */}
                    <div className="container top-bottom-padding-120 flex-container response-999">
                        {/* column start */}
                        <div className="six-columns six-offset">
                            <div className="content-left-margin-40">
                                <h2 className="large-title-bold">
                                    <span className="slider-overlay2 medium-title red-color" data-text="Amnaya Web">Amnaya Web</span><br />
                                </h2>
                                <h3>
                                    <span className="slider-overlay2  red-color" data-text="Plant Management and information sharing network">Plant Management and information sharing network</span>
                                </h3>
                                <h4>
                                    <span className="slider-overlay2  red-color" data-text="Established in Pakistan in 2016.">Established in 2019.</span>
                                </h4>
                                <p className="prtflio p-style-bold-up text-height-20 d-flex-wrap text-color-4 text-respons-slider">
                                    A web and app base open content online botanical encyclopedia created through
                                    the collaborative effort of a community of users known as contributor, reader and admin.
                                    Anyone registered on the site can create an article for publication; registration is
                                    required to edit articles. (a Wikipedia type project).
                                </p>
                                <div className="slider-fade slider-tr-delay07 top-margin-30">
                                    <div className="border-btn-box border-btn-red pointer-large">
                                        <div className="border-btn-inner">
                                            <a href="/Amnayaweb" className="border-btn" data-text="VIEW PROJECT">VIEW PROJECT</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* column end */}
                    </div>{/* flex-container end */}
                </div>{/* home-slider-content end */}
            </SwiperSlide>
            <SwiperSlide className="flex-min-height-box home-slide">
                {/* slide-bg */}
                <div className="img-algn slide-bg" style={{ backgroundImage: 'url(assets/images/project/ezigo/iportfolio.webp)' }} />
                {/* home-slider-content start */}
                <div className="home-slider-content flex-min-height-inner dark-bg-2">
                    {/* flex-container start */}
                    <div className="container top-bottom-padding-120 flex-container response-999">
                        {/* column start */}
                        <div className="six-columns six-offset">
                            <div className="content-left-margin-40">
                                <h2 className="large-title-bold">
                                    <span className="slider-overlay2 medium-title red-color" data-text="Ezigo">Ezigo</span><br />
                                </h2>
                                <h3>
                                    <span className="slider-overlay2  red-color" data-text="Rides and Courier service app(UBER CONCEPT)">Rides and Courier service app(UBER CONCEPT)</span>
                                </h3>
                                <h4>
                                    <span className="slider-overlay2  red-color" data-text="Established in 2019.">Established in 2019.</span>
                                </h4>
                                <p className="prtflio p-style-bold-up text-height-20 d-flex-wrap text-color-4 text-respons-slider">
                                    Ezigo is an app-based car service that connects people to rides. The Company
                                    designs an online booking platform that connects passengers with local drivers. A
                                    comprehensive transportation network also including, logistics and cash on delivery
                                    payments, parcels and payments efficiently
                                </p>
                                <div className="slider-fade slider-tr-delay07 top-margin-30">
                                    <div className="border-btn-box border-btn-red pointer-large">
                                        <div className="border-btn-inner">
                                            <a href="/Ezigo" className="border-btn" data-text="VIEW PROJECT">VIEW PROJECT</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* column end */}
                    </div>{/* flex-container end */}
                </div>{/* home-slider-content end */}

            </SwiperSlide>
            <SwiperSlide className="flex-min-height-box home-slide">
                {/* slide-bg */}
                <div className="img-algn slide-bg" style={{ backgroundImage: 'url(assets/images/project/safety_worker/iportfolio.webp)' }} />
                {/* home-slider-content start */}
                <div className="home-slider-content flex-min-height-inner dark-bg-2">
                    {/* flex-container start */}
                    <div className="container top-bottom-padding-120 flex-container response-999">
                        {/* column start */}
                        <div className="six-columns six-offset">
                            <div className="content-left-margin-40">
                                <h2 className="large-title-bold">
                                    <span className="slider-overlay2 medium-title red-color" data-text="Safety Worker App">Safety Worker App</span><br />
                                </h2>
                                <h3>
                                    <span className="slider-overlay2  red-color" data-text="Safety hazards alert app">Safety hazards alert app</span>
                                </h3>
                                <h4>
                                    <span className="slider-overlay2  red-color" data-text="Established in 2019.">Established in 2019.</span>
                                </h4>
                                <p className="prtflio p-style-bold-up text-height-20 d-flex-wrap text-color-4 text-respons-slider">
                                    An online app for safety hazards at workplace specifically design with online
                                    alerts, warnings and safety measures while exploring mercury.
                                </p>
                                <div className="slider-fade slider-tr-delay07 top-margin-30">
                                    <div className="border-btn-box border-btn-red pointer-large">
                                        <div className="border-btn-inner">
                                            <a href="/SafetyWorkerApp" className="border-btn" data-text="VIEW PROJECT">VIEW PROJECT</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* column end */}
                    </div>{/* flex-container end */}
                </div>{/* home-slider-content end */}

            </SwiperSlide>
            <SwiperSlide className="flex-min-height-box home-slide">
                <div className="img-algn slide-bg" style={{ backgroundImage: 'url(assets/images/project/drive-mentor/1.webp)' }} />
                <div className="home-slider-content flex-min-height-inner dark-bg-2">
                    <div className="container top-bottom-padding-120 flex-container response-999">
                        <div className="six-columns six-offset">
                            <div className="content-left-margin-40">
                                <h2 className="large-title-bold">
                                    <span className="slider-overlay2 medium-title red-color" data-text="DRIVE MENTOR">DRIVE MENTOR</span><br />
                                </h2>
                                <h3>
                                    <span className="slider-overlay2  red-color" data-text="Learn defensive driving at Drive Mentor's Driving School.">Learn defensive driving at Drive Mentor's Driving School. </span>
                                </h3>
                                <h4>
                                    <span className="slider-overlay2  red-color" data-text="Established in Pakistan in 2016.">Established in Pakistan in 2016.</span>
                                </h4>
                                <p className="prtflio p-style-bold-up text-height-20 d-flex-wrap text-color-4 text-respons-slider">
                                    <span className="slider-overlay2 slider-tr-delay01">At Our Driving School, our goal is to create
                                        <strong>confident, safe &amp; knowledgeable</strong>, drivers who succeed. We offer programs for
                                        both new drivers &amp; experienced ones!
                                        competitive space.</span>
                                </p>
                                <div className="slider-fade slider-tr-delay07 top-margin-30">
                                    <div className="border-btn-box border-btn-red pointer-large">
                                        <div className="border-btn-inner">
                                            <a href="/DriveMentor" className="border-btn" data-text="VIEW PROJECT">VIEW PROJECT</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </SwiperSlide>
            {/* swiper-button-next start */}
            <div className="swiper-button-next">
                <div className="slider-arrow-next-box">
                    <span className="slider-arrow-next" />
                </div>
            </div>{/* swiper-button-next end */}
            {/* swiper-button-prev start */}
            <div className=" swiper-button-prev">
                <div className="slider-arrow-prev-box">
                    <span className="slider-arrow-prev" />
                </div>
            </div>

            {/* swiper-button-prev end */}
            {/* swiper-pagination */}
            {/*        <div class="swiper-pagination"></div>*/}
        </Swiper>

    )
}
