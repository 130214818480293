import React, {Component} from 'react'

class Ionic extends Component{
constructor(props){

  super(props)


}
render(){
  return(
    <>

<div>
  <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
  {/*?php include("head.php") ?*/}
  {/*?php include("nav.php") ?*/}
  {/* animsition-overlay start */}
  <main className="animsition-overlay" data-animsition-overlay={true} id="up">
    {/* page-head start */}
    <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
      {/* page-head-bg */}
      <div className="page-head-bg" style={{backgroundImage: 'url(assets/images/backgrounds/Header-ionic.webp)'}} />
      {/* flex-min-height-inner start */}
      <div className="flex-min-height-inner">
        {/* container start */}
        <div className="container top-bottom-padding-120">
          <h2 className="overlay-loading2 medium-title red-color">ionic</h2>
          <h3 className="large-title-bold text-color-4">
            <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">DEVELOPMENT</span><br />
          </h3>
          <p className="d-flex-wrap top-margin-20 text-color-4">
            <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
            <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
            <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
          </p>
        </div>{/* container end */}
      </div>{/* flex-min-height-inner end */}
      {/* scroll-btn start */}
      <a href="#down" className="scroll-btn pointer-large">
        <div className="scroll-arrow-box">
          <span className="scroll-arrow" />
        </div>
        <div className="scroll-btn-flip-box">
          <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
        </div>
      </a>{/* scroll-btn end */}
    </section>{/* page-head end */}
    {/* flex-min-height-box start */}
    <section id="down" className="light-bg-1" data-midnight="black">
      {/* container start */}
      <div className="container bottom-padding-60 top-padding-60">
        {/* text-center start */}
        <div data-animation-container className="text-center">
          <p className="medium-title text-color-0">Developing Useful</p>
          <h2 data-animation-child className="large-title text-height-10 text-color-1 overlay-anim-box2" data-animation="overlay-anim2">Hybrid Ionic Mobile Apps
          </h2><br />
          <p data-animation-child className="fade-anim-box tr-delay02 text-color-1 top-margin-5 bottom-padding-30" data-animation="fade-anim">Building hybrid mobile apps is easy with the use of Ionic platform. Our experienced team of developers are fully aware of Ionic app development and often use it to create winning hybrid mobile apps. Hybrid apps are a great way to control costs and cover all mobile platform avenues. Here is some key information on Ionic mobile app development:</p>
        </div>{/* text-center end */}
        {/* flex-container start */}
        <div className="flex-container">
          {/* column start */}
          <div className="four-columns">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2 top-bottom-padding-60">
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Why Choose Us?</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">hnhtechsolutions offers excellent benefits that encourage our clients to place hybrid app development projects with us. We provide timely delivery and create hybrid apps that follow the best development practices as suggested by Google and Apple. With our experienced team at work, your hybrid app projects are always in the hands of winning professionals.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
          {/* column start */}
          <div className="eight-columns">
            <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2 top-bottom-padding-60">
              <div className="expertise content-padding-l-r-20 content-padding-bottom-20">
                <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Why Choose ionic apps?</h3><br />
                <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">Ionic is an open-source framework to develop hybrid mobile apps. The underlying technology of Ionic is AngularJS and Apache Cordova. Ionic provides an efficient environment to develop mobile apps with commonplace web development tools like CSS, HTML5, and JavaScript. The focus remains on creating common components that create a visually pleasing and functionally satisfying hybrid app.</p>
                <br />
              </div>
            </a>
          </div>
          {/* column end */}
        </div>{/* flex-container end */}
        <div className="text-center top-margin-30 top-aman">
          <img src="./assets/images/backgrounds/AND+APPle%20Mob.webp" style={{margin: 'auto', width: '70%'}} alt />
          <p className="small-title">Not sure which platform you like? Let our experts understand your app idea and then suggest the ideal path to a successfully developed custom app. We ensure that you can achieve the best customized solution with the help from our top industry experts.</p>
        </div>
      </div>{/* container end */}
    </section>{/* section end */}
    <section className="dark-bg-1">
      <div className="top-bottom-padding-30 text-center">
        <p data-animation-child className="medium-title text-color-4">TURNING COMPLEX IDEAS INTO</p>
        <br />
        <h3 data-animation-child className="large-title-bold text-color-4 ">Simple, Graceful &amp; Functional Mobile App Solutions</h3><br />
      </div>
      <div className="container small bottom-padding-120">
        <div className="comments-form-box">
          <form className="comments-form">
            <div className="flex-container">
              <div className="four-columns">
                <input type="text" className="comment-form-control pointer-small" placeholder="Your Name" />
              </div>
              <div className="four-columns">
                <input type="email" className="comment-form-control pointer-small" placeholder="Your Email" />
              </div>
              <div className="four-columns">
                <input type="text" className="comment-form-control pointer-small" placeholder="Phone" />
              </div>
              <div className="twelve-columns">
                <textarea className="comment-form-control pointer-small" placeholder="Comment" defaultValue={""} />
              </div>
            </div>
            <button className="arrow-btn-box pointer-large">
              <span className="arrow-btn">Submit Information</span>
            </button>
          </form>
        </div>{/* comments-form-box end */}
      </div>
    </section>
  </main>{/* animsition-overlay end */}
  {/* footer start */}
  {/*?php include("footer.php") ?*/}
</div>
</>
)
}
}
export default Ionic