import React, { useState } from 'react'
import axios from "axios";
const CompanyCareer = () => {
  // validation
  const [nameerror, setnameerror] = useState(false)
  const [phoneerror, setphoneerror] = useState(false)
  const [emailerror, setemailerror] = useState(false)
  const [resumeerror, setresumeerror] = useState(false)
  const [error, seterror] = useState(false)
  const [success, setsuccess] = useState(false)
  // validation


  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone_no, setphone_no] = useState("");
  const [resume, setresume] = useState("");
  // const file = (event.target.files[0]);
  const Submit = () => {
    if (name === "") {
      setnameerror(true)
    } else if (email === "") {
      setemailerror(true)
    } else if (phone_no === "") {
      setphoneerror(true)
    } else if (resume === "") {
      setresumeerror(true)
    } else {
      const formdata = new FormData;
      formdata.append('name', name)
      formdata.append('email', email)
      formdata.append('phone_no', phone_no)
      formdata.append('resume', resume)
      axios.post("https://hnhbackend.hnhtechsolutions.com/api/resume", formdata, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => {
          console.log("res", res.data);
          if (res.data) {
            setresumeerror(false)
            setphoneerror(false)
            setemailerror(false)
            setnameerror(false)
            setsuccess(true)

          } else {
            seterror(true)
          }
        })
    }
  };
  const updateState = () => {
    setname("")
    setemail("")
    setphone_no("")
    setresume("")
  }

  return (
    <>
      <div>
        <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
        <main className="animsition-overlay" data-animsition-overlay="true" id='up'>

          {/* page-head start */}
          <section id="up" className="page-head flex-min-height-box dark-bg-2">
            {/* page-head-bg */}
            <div className="page-head-bg overlay-loading2" style={{ backgroundImage: 'url(assets/images/backgrounds/Campany-profile.jpg)' }} />
            {/* flex-min-height-inner start */}
            <div className="flex-min-height-inner">
              {/* flex-container start */}
              <div className="container top-bottom-padding-120 flex-container response-999">
                {/* column start */}
                <div className="six-columns six-offset">
                  <div className="content-left-margin-40">
                    <h2 className="overlay-loading2 tr-delay03 medium-title red-color">Welcome Quantilytics</h2>
                    <h3 className="large-title-bold text-color-4">
                      <span className="overlay-loading2 overlay-light-bg-1 tr-delay04">Best Solutions</span><br />
                      <span className="overlay-loading2 overlay-light-bg-1 tr-delay05">&amp; ideas for</span><br />
                      <span className="overlay-loading2 overlay-light-bg-1 tr-delay06">You Business</span>
                    </h3>
                    <p className="d-flex-wrap top-margin-20 text-color-4">
                      <span className="small-title-oswald text-height-20 fade-loading tr-delay07 top-margin-10">Creative team</span>
                      <span className="small-title-oswald text-height-20 fade-loading tr-delay08 top-margin-10">Innovation ideas</span>
                      <span className="small-title-oswald text-height-20 fade-loading tr-delay09 top-margin-10">Best services</span>
                    </p>
                  </div>
                </div>{/* column end */}
              </div>{/* flex-container end */}
            </div>{/* flex-min-height-inner end */}
            {/* scroll-btn start */}
            <a href="#down" className="scroll-btn pointer-large">
              <div className="scroll-arrow-box">
                <span className="scroll-arrow" />
              </div>
              <div className="scroll-btn-flip-box">
                <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
              </div>
            </a>{/* scroll-btn end */}
          </section>{/* page-head end */}
          {/* flex-min-height-box start */}
          <section id="down" className="light-bg-1" data-midnight="black">
            {/* container start */}
            <div className="container bottom-padding-60 top-padding-120">
              {/* text-center start */}
              <div data-animation-container className="text-center">
                <h2 data-animation-child className="large-title text-height-10 text-color-1 overlay-anim-box2" data-animation="overlay-anim2">Revolution</h2><br />
                <p data-animation-child className="fade-anim-box tr-delay02 text-color-1 xsmall-title-oswald top-margin-5" data-animation="fade-anim">We are a team of digital gurus with the ever-present eagerness to take up challenges and deliver the digital revolution beyond expectations without compromising on fun and the little good-things in life like morning sunshine and a good cup of coffee and daily gossip.</p>
              </div>{/* text-center end */}
              {/* flex-container start */}
              <div className="flex-container response-999 top-padding-60">
                {/* column start */}
                <div className="four-columns bottom-padding-60">
                  <a data-animation-container href="#" className="content-right-margin-20 hover-box pointer-large d-block light-bg-2">
                    <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30 red-bg" data-animation="overlay-anim2">
                      <img className="hover-img" src="assets/images/backgrounds/blockchain.webp" alt="Digital products" style={{ width: 100 }} />
                    </div>
                    <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                      <h3 data-animation-child className="large-title-bold text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">Block-Chain Developer</h3><br />
                      <h4 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">1 year(s)</h4>
                      <br />
                      <i className="hover-content fa fa-caret-down align-middle" style={{ fontSize: 20 }} />
                    </div>
                  </a>
                </div>
                {/* column end */}
                {/* column start */}
                <div className="four-columns bottom-padding-60">
                  <a data-animation-container href="#" className="content-right-margin-20 hover-box pointer-large d-block light-bg-2">
                    <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30 red-bg" data-animation="overlay-anim2">
                      <img className="hover-img" src="assets/images/backgrounds/php1.webp" alt="Digital products" style={{ width: 100 }} />
                    </div>
                    <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                      <h3 data-animation-child className="large-title-bold text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">PHP Developer</h3><br />
                      <h4 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">3 year(s)</h4>
                      <br />
                      <i className="hover-content fa fa-caret-down align-middle" style={{ fontSize: 20 }} />
                    </div>
                  </a>
                </div>
                {/* column end */}
                {/* column start */}
                <div className="four-columns bottom-padding-60">
                  <a data-animation-container href="#" className="content-right-margin-20 hover-box pointer-large d-block light-bg-2">
                    <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30 red-bg" data-animation="overlay-anim2">
                      <img className="hover-img" src="assets/images/backgrounds/reactnative.webp" alt="Digital products" style={{ width: 100 }} />
                    </div>
                    <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                      <h3 data-animation-child className="large-title-bold text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">React Native Developer</h3><br />
                      <h4 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">2 year(s)</h4>
                      <br />
                      <i className="hover-content fa fa-caret-down align-middle" style={{ fontSize: 20 }} />
                    </div>
                  </a>
                </div>
                {/* column end */}
              </div>{/* flex-container end */}
            </div>{/* container end */}
          </section>{/* section end */}
          <div className="text-center top-bottom-padding-120 red-bg" data-midnight="black">
            <a href="#" className>
              <span className="large-title">SHARE, ORGANIZE, &amp; COLLABORATE WITH JUST FEW MOUSE CLICKS</span>
            </a>
          </div>
          {/* section start */}
          <section className="flex-min-height-box top-bottom-padding-120">
            {/* flex-container start */}
            <div className="container ">
              <div className="text-center">
                <h3 className="overlay-loading2 tr-delay03 medium-title red-color">STILL COULDN'T FIND WHAT YOU ARE LOOKING FOR?</h3>
                <br />
                <br />

                <div className='six-column setmargin02' >
                  <div className="header-quote-formed"
                    img src="assets/images/backgrounds/top_shape.png"
                    style={{ width: '100% ,!important', borderTop: '10px solid #00A8E8', borderBottom: '10px solid #00A8E8' }}>
                    <div className="form-header-text red-color">
                      Send us your CV &amp; We’ll get in touch
                    </div> <br />
                    {error ? <div className='alert alert-danger' role="alert">Invalid Data!</div> : null}
                    {success ? <div className='alert alert-success' role="alert">Thank You For Resume!</div> : null}
                    <div>
                      <form>
                        <div className="form-group-home">
                          <input
                            type="text"
                            placeholder="Your name"
                            name="name"
                            className="formhomed"
                            required
                            value={name}
                            onChange={(e) => { setname(e.target.value) }}

                          />
                          {nameerror ? <div className='alert alert-danger' role="alert">Name Is required!</div> : null}
                        </div> <br />
                        <div className="form-group-home">
                          <input
                            type="email"
                            placeholder="Email"
                            name="email"
                            className="formhomed"
                            required
                            value={email}
                            onChange={(e) => { setemail(e.target.value) }}
                          />
                          {emailerror ? <div className='alert alert-danger' role="alert">Email Is required!</div> : null}
                        </div><br />
                        <div className="form-group-home">
                          <input
                            type="Number"
                            placeholder="Phone"
                            name="Phone"
                            className="formhomed"
                            required
                            value={phone_no}
                            onChange={(e) => { setphone_no(e.target.value) }}
                          />
                          {phoneerror ? <div className='alert alert-danger' role="alert">Phone Is required!</div> : null}
                        </div><br />
                        <div className="form-group-home image-upload">
                          <label for="file-input" className="formhomed">
                            Upload A CV
                          </label>
                          <input id="file-input"
                            // value={resume}
                            onChange={(e) => { setresume(e.target.files[0]) }}
                            type="file" />
                        </div>
                        {resumeerror ? <div className='alert alert-danger' role="alert">cv Is required!</div> : null}
                        <div>
                          <button type="button" onClick={() => { Submit(); updateState() }} className='arrow-btn pointer-large border setformedbtn' >
                            Send
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>{/* flex-container end */}
          </section>{/* section end */}
        </main > {/* animsition-overlay end */}
      </div >
    </>



  )
}
export default CompanyCareer