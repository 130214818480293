import React, { Component } from 'react'
import ClientLogo from '../component/ClientLogo'

export default class Digital extends Component {
  render() {
    return (
      <>
        {/* page-head start */}
        <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
          {/* page-head-bg */}
          <div className="page-head-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/digital-marketing.jpg)' }} />
          <div className="bg-overlay" />
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* container start */}
            <div className="container top-bottom-padding-120">
              <h2 className="overlay-loading2 medium-title red-color">Digital</h2>
              <h3 className="large-title-bold text-color-4">
                <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">Marketing</span><br />
              </h3>
              <p className="d-flex-wrap top-margin-20 text-color-4">
                <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
              </p>
            </div>{/* container end */}
          </div>{/* flex-min-height-inner end */}
          {/* scroll-btn start */}
          <a href="#down" className="scroll-btn pointer-large">
            <div className="scroll-arrow-box">
              <span className="scroll-arrow" />
            </div>
            <div className="scroll-btn-flip-box">
              <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
            </div>
          </a>{/* scroll-btn end */}
        </section>{/* page-head end */}
        {/* section start */}
        <section id="down" className="dark-bg-1 top-bottom-padding-120">
          {/* container start */}
          <div className="container text-center" data-animation-container>
            <h2 className="large-title title-fill" data-animation="title-fill-anim" data-text="SHARE, ORGANIZE, &amp; COLLABORATE WITH JUST FEW MOUSE CLICKS
                  Services">
              SHARE, ORGANIZE, &amp; COLLABORATE WITH JUST FEW MOUSE CLICKS
              Services
              {/* <div className=" top-padding-40 text-center"> */}
              {/* <h1 className>SHARE, ORGANIZE, &amp; COLLABORATE WITH JUST FEW MOUSE CLICKS
                  Services</h1> */}
              {/* </div> */}
            </h2>
            <div className="progress-box" style={{ width: "40%", margin: "20px auto", borderRadius: "5px" }}>
              <div className="progress-zero" data-progress="63%">
                <div className="progress-full" data-animation-child data-animation="slide-progress" />
                <div className="progress-full progress-full-red" data-animation-child data-animation="slide-progress" data-animation-delay="200ms" />
              </div>
            </div>
          </div>
          {/* container end */}
          {/* top-padding-90 start */}
          <div className="top-padding-50">
            {/* container start */}
            <div className="container">
              <div className="services-bg" style={{ backgroundImage: "url(assets/images/backgrounds/digital-marketing.webp)" }} />
            </div>
            {/* container end */}
            {/* flex-container start */}
            <div className="container small top-padding-60 flex-container response-999 services-content">
              <div className="flex-container container">


                <div className="six-columns">
                  <div className="content-left-margin-20">
                    <h2>
                      <span className="platform-heading tr-delay08 text-center" data-text="Digital Marketing Services Company In The Digital Age">
                        Digital Marketing Services Company In The Digital Age
                      </span>
                    </h2>
                    <p className="p-style-large text-color-5 fade-anim-box tr-delay04" data-animation="fade-anim">
                      Leverage the skills of our company’s digital marketing services capable of delivering outstanding marketing strategies. We help our clients to engage, convert, and retain customers in the digital world through various digital marketing components such as inbound marketing, SEO, SEM, social media marketing, and more.
                    </p>
                  </div>
                </div>
                <div className="six-columns">
                  {/* container start */}
                  <div className="container" data-animation-container>
                    <div>
                      <h2 className="medium-title">
                        <span className="platform-heading tr-delay08 text-center" data-text=" Marketing Services
                        In The Digital Age">
                          Marketing Services
                          In The Digital Age
                        </span>
                      </h2>
                      <p className="p-style-large text-color-5 fade-anim-box tr-delay04" data-animation="fade-anim">Our digital development experts deliver products and services that shorten your time-to-market. With years of experience in mobile application development and web development services, our solutions bring satisfaction to our clients and joy to their customers. Experience the power of our agile software development services to get you the results you expect and deserve.
                        Affiliate
                        Marketing
                        Native
                        Advertising
                        Marketing
                        Automation
                      </p>
                    </div>
                  </div>{/* container end */}
                </div>
              </div>

              {/* column end */}
            </div>
            {/* flex-container end */}
          </div>
          {/* top-padding-90 end */}
        </section>
        {/* section end */}
        {/* flex-min-height-box start */}
        <section id="down" className="light-bg-1 about-page">
          <div className="flex-container container">
            <div className="six-columns">
              <img src="assets/images/backgrounds/Inbound-1.jpg" alt="" />
            </div>
            <div className="six-columns text-center">
              <h1>Inbound Marketing</h1>
              <p className="p-style-large text-color-0  padding-class fade-anim-box tr-delay04" data-animation="fade-anim">Let your business grow with the help of our inbound marketing service. We boost your brand awareness and strategically funnel your digital traffic to deliver the results you desire. With our carefully drafted content on all digital platforms, you can convert leads into loyal customers with total ease. Attract, engage, nurture, and convert leads into the legion of loyal followers.</p>
            </div>
          </div>
        </section>{/* flex-min-height-box end */}
        <ClientLogo></ClientLogo>
      </>

    )
  }
}
