import React, { Component } from 'react'

export default class WebDev extends Component {
  render() {
    return (
      <>
        {/* page-head start */}
        <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
          {/* page-head-bg */}
          <div className="web-dev-head page-head-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/development.webp)' }} />
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* container start */}
            <div className="container top-bottom-padding-120">
              <h2 className="overlay-loading2 medium-title red-color">WEB</h2>
              <h3 className="large-title-bold text-color-4">
                <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">DEVELOPMENT</span><br />
              </h3>
              <p className="d-flex-wrap top-margin-20 text-color-4">
                <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
              </p>
            </div>{/* container end */}
          </div>{/* flex-min-height-inner end */}
          {/* scroll-btn start */}
          <a href="#down" className="scroll-btn pointer-large">
            <div className="scroll-arrow-box">
              <span className="scroll-arrow" />
            </div>
            <div className="scroll-btn-flip-box">
              <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
            </div>
          </a>{/* scroll-btn end */}
        </section>{/* page-head end */}
        {/* flex-min-height-box start */}
        <section id="down" className="dark-bg-1 flex-min-height-box">
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* container start */}
            <div className="container small top-bottom-padding-120">
              {/* flex-container start */}
              <div data-animation-container className="flex-container">
                {/* column start */}
                <div className="twelve-columns text-center">
                  <h2 className="large-title text-height-12">
                    <span data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="We are Creative">We are Creative</span><br />
                    <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="We are Quantilyticss">We are Quantilyticss</span>
                  </h2>
                </div>{/* column end */}
                {/* column start */}
                <div className="six-columns">
                  <div className="content-right-margin-20">
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">Web improvement is the work engaged with building up a site for the Internet (World Wide Web) or an intranet (a private network).[1] Web advancement can go from building up a basic single static page of plain content to complex online web applications (web applications), electronic organizations, and informal organization administrations. A progressively far reaching rundown of errands to which web improvement generally alludes, may incorporate web building, website architecture, web content advancement, customer contact, customer side/server-side scripting, web server and system security arrangement, and web based business improvement. </p>
                  </div>
                </div>{/* column end */}
                {/* column start */}
                <div className="six-columns">
                  <div className="content-left-margin-20">
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay03" data-animation="fade-anim">Among web experts, "web advancement" ordinarily alludes to the primary non-structure parts of building sites: composing markup and coding.[2] Web improvement may utilize content administration frameworks (CMS) to roll out substance improvements simpler and accessible with essential specialized aptitudes.</p>
                  </div>
                </div>{/* column end */}
              </div>{/* flex-container end */}
            </div>{/* container end */}
          </div>{/* flex-min-height-inner end */}
        </section>{/* flex-min-height-box end */}
        {/* section start */}
        <section className="light-bg-1 bottom-padding-30 top-padding-120" data-midnight="black">
          {/* bottom-padding-90 start */}
          <div className="bottom-padding-90">
            {/* portfolio-content start */}
            <div className="portfolio-content flex-min-height-box">
              {/* portfolio-content-inner start */}
              <div className="portfolio-content-inner flex-min-height-inner">
                {/* flex-container start */}
                <div className="flex-container container small">
                  {/* column start */}
                  <div data-animation-container className="six-columns">
                    <div className="content-right-margin-40">
                      <h3 className="title-style text-color-1">
                        <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01" data-animation="overlay-anim2">Custom Software</span><br />
                        <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02" data-animation="overlay-anim2">Development</span><br />
                      </h3>
                      <h5 className="small-title text-color-0">Our Software Development Services Are Flexibly Designed </h5>
                      <div data-animation-child className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05" data-animation="fade-anim">
                        <a href="Customsoftweredev" className="arrow-btn pointer-large animsition-link">Read more</a>
                      </div>
                    </div>
                  </div>{/* column end */}
                  {/* column start */}
                  <div className="six-columns top-padding-60">
                    <a href="Customsoftweredev" className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link">
                      <div className="portfolio-mb portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2" data-animation="overlay-anim2" style={{ backgroundImage: 'url(assets/images/backgrounds/web-development/web-1.jpg)' }} />
                    </a>
                  </div>{/* column end */}
                </div>{/* flex-container end */}
              </div>{/* portfolio-content-inner end */}
            </div>{/* portfolio-content end */}
          </div>{/* bottom-padding-90 end */}
          {/* bottom-padding-90 start */}
          <div className="bottom-padding-90">
            {/* portfolio-content start */}
            <div className="portfolio-content flex-min-height-box">
              {/* portfolio-content-inner start */}
              <div className="portfolio-content-inner flex-min-height-inner">
                {/* flex-container start */}
                <div className="flex-container reverse container small">
                  {/* column start */}
                  <div className="six-columns top-padding-60">
                    <a href="Enterprises" className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link">
                      <div className="portfolio-mb portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2" data-animation="overlay-anim2" style={{ backgroundImage: 'url(assets/images/backgrounds/web-development/web-2.jpg)' }} />
                    </a>
                  </div>{/* column end */}
                  {/* column start */}
                  <div data-animation-container className="six-columns">
                    <div className="content-left-margin-40">
                      <h3 className="title-style text-color-1">
                        <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01" data-animation="overlay-anim2">Enterprise</span><br />
                        <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02" data-animation="overlay-anim2">Software</span><br />
                      </h3>
                      <h5 className="small-title text-color-0">Do You Need An Efficient It Infrastructure For Your Business?</h5>
                      <div data-animation-child className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05" data-animation="fade-anim">
                        <a href="Enterprises" className="arrow-btn pointer-large animsition-link">Read more</a>
                      </div>
                    </div>
                  </div>{/* column end */}
                </div>{/* flex-container end */}
              </div>{/* portfolio-content-inner end */}
            </div>{/* portfolio-content end */}
          </div>{/* bottom-padding-90 end */}
          {/* bottom-padding-90 start */}
          <div className="bottom-padding-90">
            {/* portfolio-content start */}
            <div className="portfolio-content flex-min-height-box">
              {/* portfolio-content-inner start */}
              <div className="portfolio-content-inner flex-min-height-inner">
                {/* flex-container start */}
                <div className="flex-container container small">
                  {/* column start */}
                  <div data-animation-container className="six-columns">
                    <div className="content-right-margin-40">
                      <h3 className="title-style text-color-1">
                        <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01" data-animation="overlay-anim2">Front-End</span><br />
                        <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02" data-animation="overlay-anim2">Development</span><br />
                      </h3>
                      <h5 className="small-title text-color-0">Reactjs &amp; Angular Are Popular Javascript-based Frameworks </h5>
                      <div data-animation-child className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05" data-animation="fade-anim">
                        <a href="/Frontend" className="arrow-btn pointer-large animsition-link">Read more</a>
                      </div>
                    </div>
                  </div>{/* column end */}
                  {/* column start */}
                  <div className="six-columns top-padding-60">
                    <a href="frontendservice.php" className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link">
                      <div className="portfolio-mb portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2" data-animation="overlay-anim2" style={{ backgroundImage: 'url(assets/images/backgrounds/web-development/web-3.jpg)' }} />
                    </a>
                  </div>{/* column end */}
                </div>{/* flex-container end */}
              </div>{/* portfolio-content-inner end */}
            </div>{/* portfolio-content end */}
          </div>{/* bottom-padding-90 end */}
          {/* bottom-padding-90 start */}
          <div className="bottom-padding-90">
            {/* portfolio-content start */}
            <div className="portfolio-content flex-min-height-box">
              {/* portfolio-content-inner start */}
              <div className="portfolio-content-inner flex-min-height-inner">
                {/* flex-container start */}
                <div className="flex-container reverse container small">
                  {/* column start */}
                  <div className="six-columns top-padding-60">
                    <a href="UiUxds" className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link">
                      <div className="portfolio-mb portfolio-content-bg hover-img overlay-anim-box2 overlay-dark-bg-2" data-animation="overlay-anim2" style={{ backgroundImage: 'url(assets/images/backgrounds/web-development/web-4.jpg)' }} />
                    </a>
                  </div>{/* column end */}
                  {/* column start */}
                  <div data-animation-container className="six-columns">
                    <div className="content-left-margin-40">
                      <h3 className="title-style text-color-1">
                        <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01" data-animation="overlay-anim2">UI/UX</span><br />
                        <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02" data-animation="overlay-anim2">Design</span><br />
                      </h3>
                      <h5 className="small-title text-color-0">Quntilytics Takes Care Of Your Ui/ux Design</h5>
                      <div data-animation-child className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05" data-animation="fade-anim">
                        <a href="UiUxds" className="arrow-btn pointer-large animsition-link">Read more</a>
                      </div>
                    </div>
                  </div>{/* column end */}
                </div>{/* flex-container end */}
              </div>{/* portfolio-content-inner end */}
            </div>{/* portfolio-content end */}
          </div>{/* bottom-padding-90 end */}
        </section>{/* section end */}
      </>

    )
  }
}
