import React, { Component } from 'react'
import ClientLogo from '../component/ClientLogo'

export default class Effective extends Component {
    render() {
        return (
            <>
            {/* page-head start */}
            <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
              {/* page-head-bg */}
              <div className="page-head-bg" style={{backgroundImage: 'url(assets/images/backgrounds/effective.jpg)'}} />
              <div className="bg-overlay" />
              {/* flex-min-height-inner start */}
              <div className="flex-min-height-inner">
                {/* container start */}
                <div className="container top-bottom-padding-120">
              <h2 className="overlay-loading2 medium-title red-color">EFFECTIVE</h2>
              <h3 className="large-title-bold text-color-4">
                <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">SOLUTIONS</span><br />
              </h3>
              <p className="d-flex-wrap top-margin-20 text-color-4 ">
                <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
              </p>
            </div>
              </div>{/* flex-min-height-inner end */}
              {/* scroll-btn start */}
              <a href="#down" className="scroll-btn pointer-large">
                <div className="scroll-arrow-box">
                  <span className="scroll-arrow" />
                </div>
                <div className="scroll-btn-flip-box">
                  <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
                </div>
              </a>{/* scroll-btn end */}
            </section>{/* page-head end */}
            {/* flex-min-height-box start */}
            <section id="down" className="light-bg-1 about-page">
              {/* flex-min-height-inner start */}
              <div className="top-bottom-padding-30-arsal">
                {/* container start */}
                {/* new */}
                <section id="down" className="dark-bg-1 flex-min-height-box">
          {/* flex-min-height-inner start */}
          {/* flex-min-height-inner end */}
        </section>

        <section id="down" className="dark-bg-1 top-bottom-padding-120">
          {/* container start */}
          
          <div className="container text-center" data-animation-container>
          <div className="twelve-columns text-center">
                  <h2 className="large-title text-height-12">
                    <span data-animation-child className="title-fill" data-animation="title-fill-anim" data-text=" Organizational Effectiveness
                      With Our Business Efficiency Solutions"> Organizational Effectiveness
                      With Our Business Efficiency Solutions</span><br />
                    <div className="content-right-margin-20">
                      <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">We offer the ideal digital development services that offer effective solutions for every personal and professional need. Our experienced professionals employ proven development methodologies that result in assuring the quality of the delivered digital solutions. With our experience, we always deliver on our promise!</p>
                    </div>
                  </h2>
                </div>
            <h2 className="large-title title-fill" data-animation="title-fill-anim" data-text="SHARE, ORGANIZE, &amp; COLLABORATE WITH JUST FEW MOUSE CLICKS
                  Services">
              SHARE, ORGANIZE, &amp; COLLABORATE WITH JUST FEW MOUSE CLICKS
              Services
              {/* <div className=" top-padding-40 text-center"> */}
              {/* <h1 className>SHARE, ORGANIZE, &amp; COLLABORATE WITH JUST FEW MOUSE CLICKS
                  Services</h1> */}
              {/* </div> */}
            </h2>
            <div className="progress-box" style={{ width: "40%", margin: "20px auto", borderRadius: "5px" }}>
              <div className="progress-zero" data-progress="63%">
                <div className="progress-full" data-animation-child data-animation="slide-progress" />
                <div className="progress-full progress-full-red" data-animation-child data-animation="slide-progress" data-animation-delay="200ms" />
              </div>
            </div>
          </div>
          {/* container end */}
          {/* top-padding-90 start */}
          <div className="top-padding-50">
            {/* container start */}
            <div className="container">
              <div className="services-bg" style={{backgroundImage: 'url(assets/images/backgrounds/effective-eff-sol.webp)'}} />
              <div className="twelve-columns text-center">
                  <h2 className="large-title text-height-12">
                    <span data-animation-child className="title-fill" data-animation="title-fill-anim" data-text=" EFFECTIVE Solutions">EFFECTIVE Solutions</span><br />
                    <div className="content-right-margin-20">
                      <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">Our digital development experts deliver products and services that shorten your time-to-market. With years of experience in mobile application development and web development services, our solutions bring satisfaction to our clients and joy to their customers. Experience the power of our agile software development services to get you the results you expect and deserve.</p>
                    </div>  
                  </h2>
                </div>
            </div>
            <div className="six-columns">
                  <div className="content-left-margin-20">
                    <h1>
                      <span className="platform-heading tr-delay08 text-center" data-text="Effective Solutions">
                      Effective Solutions
                      </span>
                    </h1>
                    <p className="p-style-large text-color-5 fade-anim-box tr-delay04" data-animation="fade-anim">
                    Our digital development experts deliver products and services that shorten your time-to-market. With years of experience in mobile application development and web development services, our solutions bring satisfaction to our clients and joy to their customers. Experience the power of our agile software development services to get you the results you expect and deserve.
                    </p>
                  </div>
                </div>
            </div>
          {/* top-padding-90 end */}
        </section>
              </div>{/* flex-min-height-inner end */}
              <div className="container">
                {/* flex-min-height-inner start */}
                <div className="top-bottom-padding-30">
                  {/* container start */}
                  <div className="container" data-animation-container>
                    
                  </div>{/* container end */}
                </div>{/* flex-min-height-inner end */}
              </div>
            </section>{/* flex-min-height-box end */}
            <section id="down" className="light-bg-1 about-page">
        <div className="flex-container container">
              <div className="six-columns">
                <img src="assets/images/backgrounds/Bip-1.jpg" alt="" />
              </div>
            <div className="six-columns text-center">
              <h1 className='busines'>Business Intelligence Implementation</h1>
              <p className="p-style-large text-color-0  padding-class fade-anim-box1 tr-delay04" data-animation="fade-anim1">Today, most of the manual business management processes have been digitized. However, many companies are not reaping real benefits from digitization because they don’t have business management software designed that exactly correspond to their business SOPs. hnhtechsolutions provides customized business intelligence software for performance evaluations, data sharing, resource allocation, time-line management, project health indications etc., they all are available on demand.</p>
             </div>
        </div>
            </section>
            <ClientLogo></ClientLogo>
          </>
          
        )
    }
}
