import React, { Component } from 'react'
class AndroidApp extends Component{
    constructor(props){
        super (props)

}
render(){
    return (

    <>
<div>
  <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
  <style dangerouslySetInnerHTML={{__html: "\n    .client-count {\n        width: 179px;\n        border: 2px solid #00a8e8;\n        text-align: center;\n        border-radius: 6px;\n        padding: 5px 0;\n        color: #00a8e8;\n        font-weight: bold;\n        font-size: 22px;\n    }\n    #expert-ios {\n        padding: 100px 0 50px 0;\n        background: linear-gradient(to right, #f2f2f2 50%, #ffffff 50%);\n    }\n" }} />
  <main className="animsition-overlay" data-animsition-overlay={true} id='up'>
    <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
      {/* page-head-bg */}
      <div className="page-head-bg" style={{backgroundImage: 'url(assets/images/backgrounds/jose-fontano-9rClrXXi7ys-unsplash.webp)'}} />
      <div className="bg-overlay" />
      {/* flex-min-height-inner start */}
      <div className="flex-min-height-inner">
        {/* container start */}
        <div className="container top-bottom-padding-120">
          <h2 className="overlay-loading2 medium-title red-color">Android App</h2>
          <h3 className="large-title-bold text-color-4">
            <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">DEVELOPMENT Services</span><br />
          </h3>
          <p className="d-flex-wrap top-margin-20 text-color-4">
            <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
            <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
            <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
          </p>
        </div>{/* container end */}
      </div>{/* flex-min-height-inner end */}
      {/* scroll-btn start */}
      <a href="#down" className="scroll-btn pointer-large">
        <div className="scroll-arrow-box">
          <span className="scroll-arrow" />
        </div>
        <div className="scroll-btn-flip-box">
          <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
        </div>
      </a>{/* scroll-btn end */}
    </section>{/* page-head end */}
    {/* flex-min-height-box start */}
    <section id="down" className="light-bg-1" data-midnight="black">
      {/* container start */}
      <section id="expert-ios">
        <div className="flex-container container bottom-padding-60 top-padding-60">
          <div className="six-columns">
            {/* text-center start */}
            <div data-animation-container className>
              <h3 className="text-color-0">Custom Developed Android Apps</h3>
              <h1 data-animation-child className=" text-height-10 text-color-1 overlay-anim-box2" data-animation="overlay-anim2">Designed Around Your Unique Needs</h1><br />
              <p data-animation-child className="fade-anim-box tr-delay02 text-color-1 " data-animation="fade-anim">Looking for a winning Android developer? Quntilytics is an ideal answer! We develop Android apps and have an experience that spans over a decade. Our Business Analysts perform the required research on all app development queries, ensuring that we understand the business context and unique requirements of our valued clients. Once in line, our expert developers can quickly flesh an Android app from your business idea. We always ensure that we exceed your expectations with apps that have breathtaking UI, efficient coding and the ideal app support that you need for successful operations.</p>
            </div>{/* text-center end */}
            <div data-animation-container className="top-margin-30">
              <h2 data-animation-child className=" text-height-10 text-color-1 overlay-anim-box2" data-animation="overlay-anim2">Why Choose Quantilytics</h2><br />
              <p data-animation-child className="fade-anim-box tr-delay02 text-color-1 s" data-animation="fade-anim">Want to know why you should choose Quntilytics for Android app development? Here are the top winning reasons:</p>
              <ul className="list-dots">
                <li>
                  <p data-animation-child className="text-color-1 fade-anim-box tr-delay03 animated fade-anim top-margin-5" data-animation="fade-anim">We have an experience of over a decade</p>
                </li>
                <li>
                  <p data-animation-child className="text-color-1 fade-anim-box tr-delay04 animated fade-anim top-margin-5" data-animation="fade-anim">Our app development follows agile management principles</p>
                </li>
                <li>
                  <p data-animation-child className="text-color-1 fade-anim-box tr-delay04 animated fade-anim top-margin-5" data-animation="fade-anim">Creating a visually pleasing UI/UX is a given</p>
                </li>
                <li>
                  <p data-animation-child className="text-color-1 fade-anim-box tr-delay04 animated fade-anim top-margin-5" data-animation="fade-anim">Promised quality is always delivered</p>
                </li>
                <li>
                  <p data-animation-child className="text-color-1 fade-anim-box tr-delay04 animated fade-anim top-margin-5" data-animation="fade-anim">Top of the line support features</p>
                </li>
              </ul>
            </div>{/* text-center end */}
            <p className="client-count">100+ Clients</p>
          </div>
          <div className="six-columns">
            {/* text-center start */}
            <div data-animation-container className="text-center" style={{padding: 10}}>
              <img src="assets/images/backgrounds/ios-expert3.webp" alt style={{margin: 'auto', width: '70%'}} />
            </div>{/* text-center end */}
          </div>
        </div>
      </section>
    </section>{/* section end */}
    <section className="dark-bg-1">
      <div className="top-bottom-padding-30 text-center">
        <h3 data-animation-child className="medium-title text-color-4">TURNING COMPLEX IDEAS INTO</h3>
        <br />
        <h3 data-animation-child className="large-title-bold text-color-4 ">Simple, Graceful &amp; Functional Mobile App Solutions</h3><br />
      </div>
      <div className="container small bottom-padding-120">
        <div className="comments-form-box">
          <form className="comments-form">
            <div className="flex-container">
              <div className="four-columns">
                <input type="text" className="comment-form-control pointer-small" placeholder="Your Name" />
              </div>
              <div className="four-columns">
                <input type="email" className="comment-form-control pointer-small" placeholder="Your Email" />
              </div>
              <div className="four-columns">
                <input type="text" className="comment-form-control pointer-small" placeholder="Phone" />
              </div>
              <div className="twelve-columns">
                <textarea className="comment-form-control pointer-small" placeholder="Comment" defaultValue={""} />
              </div>
            </div>
            <button className="arrow-btn-box pointer-large">
              <span className="arrow-btn">Submit Information</span>
            </button>
          </form>
        </div>{/* comments-form-box end */}
      </div>
    </section>
  </main>{/* animsition-overlay end */}
  {/* footer start */}
  {/*?php include("footer.php") ?*/}
</div>

</>
)
}
}
export default AndroidApp