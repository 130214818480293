import React, { Component } from 'react'
// import { NavLink } from 'react-router-dom';
// import Footer from '../component/Footer';
export default class Cmsdevelopment extends Component {
    render() {
        return (
            <>

                {/* page-head start */}
                <section id="up" className="page-head flex-min-height-box dark-bg-1 ">
                    {/* page-head-bg */}
                    <div className="page-head-bg overlay-loading2 imgsetaman" style={{ backgroundImage: 'url(assets/images/backgrounds/CSD.webp)' }} />
                    {/* flex-min-height-inner start */}
                    <div className="flex-min-height-inner">
                        {/* flex-container start */}
                        <div className="container top-bottom-padding-120 flex-container response-999">
                            {/* column start */}
                            <div className="six-columns six-offset">
                                <div className="content-left-margin-40">
                                    <h2 className="large-title-bold">
                                        <span className="load-title-fill tr-delay03" data-text="CUSTOM">CUSTOM</span><br />
                                        <h3 className="large-title-bold text-color-4">
                                            <span className="load-title-fill tr-delay03" data-text="SOFTWERE DEVELOPMENT">SOFTWERE DEVELOPMENT</span>  </h3>
                                        <p className="d-flex-wrap top-margin-20 text-color-4">
                                            <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
                                            <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
                                            <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
                                        </p>
                                    </h2>
                                </div>
                            </div>{/* column end */}
                        </div>{/* flex-container end */}
                    </div>{/* flex-min-height-inner end */}
                    {/* scroll-btn start */}
                    <a href="#down" className="scroll-btn pointer-large">
                        <div className="scroll-arrow-box">
                            <span className="scroll-arrow" />
                        </div>
                        <div className="scroll-btn-flip-box">
                            <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
                        </div>
                    </a>{/* scroll-btn end */}
                </section>{/* page-head end */}
                <section id="down" className="dark-bg-1 top-bottom-padding-120">
                    {/* container start */}
                    <div className="container text-center">
                        <h2 className="medium-title title-fill" data-animation="title-fill-anim" data-text="CUSTOM SOFTWARE DEVELOPMENT">CUSTOM SOFTWARE DEVELOPMENT</h2>
                        <p data-animation-child className="text-color-4 p-style-large ">
                            Our master programming engineers give a
                            first rate custom programming improvement
                            administration to the world's driving endeavors and
                            organizations across enterprises. We offer
                            a framework structure that guarantees excess,
                            progression, and support of the necessary quality norms
                            in the conveyance processes.</p>
                    </div>{/* container end */}
                    {/* top-padding-90 start */}
                    <div className="top-padding-90">
                        {/* container start */}
                        <div className="container">
                            <div className="services-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/customsoftdev.jpg)' }} />
                        </div>{/* container end */}
                        {/* flex-container start */}
                        <div className="container small top-padding-60 flex-container response-999 services-content">
                            {/* column start */}
                            <div className="twovelve-columns">
                                <div data-animation-container className="right-padding-50 text-center">
                                    <h2 className="medium-title title-fill" data-animation="title-fill-anim" data-text="ABOUT CUSTOM SOFTWARE DEVELOPMENT">ABOUT CUSTOM SOFTWARE DEVELOPMENT</h2> 
                                    <h2 className="medium-title title-fill" data-animation="title-fill-anim" data-text="SERVICES AT INTELLECTSOFT"> SERVICES AT INTELLECTSOFT</h2>
                                        </div>
                                    </div>{/* column end */}
                                    <br/>
                                        {/* column start */}
                            <div className="twovelve-columns">
                                <div className="content-left-margin-20">
                                    <p className="p-style-large text-color-5 fade-anim-box tr-delay04 text-center" data-animation="fade-anim">As a custom programming advancement organization, we offer a wide scope of mechanical programming improvement administrations across a scope of business spaces to meet your particular business needs.</p>
                                            </div>
                                        </div>{/* column end */}
                                    </div>{/* flex-container end */}
                                </div>{/* top-padding-90 end */}
                                    {/* top-padding-90 start */}
                                    <div className="top-padding-90">
                                        {/* container start */}
                        <div className="container">
                                            <div className="services-bg" style={{ backgroundImage: 'url(assets/images/services/customsof.jpeg)' }} />
                                        </div>{/* container end */}
                                            {/* flex-container start */}
                                            <div className="container small top-padding-60 flex-container response-999 services-content">
                                                {/* column start */}
                            <div className="twovelve-columns">
                                <div data-animation-container className="">
                                    {/* <h2 data-animation-child className="small-title-oswald red-color overlay-anim-box2 content-right-padding-60" data-animation="overlay-anim2">Services</h2> */}
                                    <div className='text-center'>

                                        <h3 className="title-stylee1 text-color-4 margin-left-355px">  
                                            <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1 tr-delay01" data-animation="overlay-anim2">New Software &amp; Application Development </span>
                                        </h3>
                                    </div>
                                                    </div>
                                                </div>{/* column end */}
                                                    {/* column start */}
                            <div className="twovelve-columns">
                                <div className="content-left-margin-20">
                                    <p className="p-style-large text-color-5 fade-anim-box tr-delay04 text-center" data-animation="fade-anim">All good products were once a simple idea. We value your ideas and transform them into breadth taking products. At Quantilytics, we offer end-to-end new product development services that help you painlessly go through various stages necessary to transform an idea to a winning product. We also offer state-of-the-art DevOps solutions that automate and monitor your software development and associated operations at all steps 24/7. We turn ideas into reality, don’t let your idea fade away.</p>
                                                        </div>
                                                    </div>{/* column end */}
                                                </div>{/* flex-container end */}
                                            </div>{/* top-padding-90 end */}
                                            </section>{/* section end */}
            </>
                                    )
    }
}