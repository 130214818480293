import React, { Component } from 'react'
// import { NavLink } from 'react-router-dom';
// import Footer from '../component/Footer';
export default class Cmsdevelopment extends Component {
    render() {
        return (
            <>

                {/* page-head start */}
                <section id="up" className="page-head flex-min-height-box dark-bg-1 ">
                    {/* page-head-bg */}
                    <div className="page-head-bg overlay-loading2 imgsetaman" style={{ backgroundImage: 'url(assets/images/backgrounds/cms.jpeg)' }} />
                    {/* flex-min-height-inner start */}
                    <div className="flex-min-height-inner">
                        {/* flex-container start */}
                        <div className="container top-bottom-padding-120 flex-container response-999">
                            {/* column start */}
                            <div className="six-columns six-offset">
                                <div className="content-left-margin-40">
                                    <h2 className="large-title-bold">
                                        <span className="load-title-fill tr-delay03" data-text="CMS">CMS</span><br />
                                        <h3 className="large-title-bold text-color-4">
                                            <span className="load-title-fill tr-delay03" data-text="DEVELOPMENT">DEVELOPMENT</span>  </h3>
                                        <p className="d-flex-wrap top-margin-20 text-color-4">
                                            <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
                                            <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
                                            <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
                                        </p>
                                    </h2>
                                </div>
                            </div>{/* column end */}
                        </div>{/* flex-container end */}
                    </div>{/* flex-min-height-inner end */}
                    {/* scroll-btn start */}
                    <a href="#down" className="scroll-btn pointer-large">
                        <div className="scroll-arrow-box">
                            <span className="scroll-arrow" />
                        </div>
                        <div className="scroll-btn-flip-box">
                            <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
                        </div>
                    </a>{/* scroll-btn end */}
                </section>{/* page-head end */}
                {/* flex-min-height-box start */}
                <section id="down" className="dark-bg-1 flex-min-height-box">
                    {/* flex-min-height-inner start */}
                    <div className="flex-min-height-inner">
                        {/* container start */}
                        <div className="container small top-bottom-padding-60">
                            {/* flex-container start */}
                            <div data-animation-container className="flex-container">
                                {/* column start */}
                                <div className="twelve-columns text-center">
                                    <h1 className="large-title text-height-12">
                                        <span data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="Ideal Content Management System">Ideal Content Management System</span><br />
                                        <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Solutions that Empower Your Business">Solutions that Empower Your Business</span>
                                    </h1>
                                </div>{/* column end */}
                                {/* column start */}
                                <div className="12-columns">
                                    <div className="content-left-margin-20">
                                        <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay03 text-center" data-animation="fade-anim">
                                            We convey CMS arrangements that genuinely motivate our customers and assist them with dealing with their resources in a helpful way. Our designers make profoundly fit CMS arrangements that are ideally suited for upgrading your authoritative effectiveness. We can convey both conventional frameworks just as cloud-based arrangements. Select us as your CMS Solutions
                                            supplier and you will see that we can resolve all your substance the board issues.</p>
                                    </div>
                                </div>{/* column end */}
                            </div>{/* flex-container end */}
                        </div>{/* container end */}
                    </div>{/* flex-min-height-inner end */}
                </section>{/* flex-min-height-box end */}
                {/* section start */}
                <section className="light-bg-1 bottom-padding-0 top-padding-120" data-midnight="black">
                    {/* bottom-padding-90 start */}
                    <div className="bottom-padding-90">
                        {/* portfolio-content start */}
                        <div className="portfolio-content flex-min-height-box">
                            {/* portfolio-content-inner start */}
                            <div className="portfolio-content-inner flex-min-height-inner">
                                {/* flex-container start */}
                                <div className="flex-container container small">
                                    {/* column start */}
                                    <div data-animation-container className="six-columns ">
                                        <div className="content-right-margin-60">
                                            <h3 className="title-style text-color-1">
                                                <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01" data-animation="overlay-anim2">Cloud</span><br />
                                                <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02" data-animation="overlay-anim2"> CMS Development</span><br />
                                            </h3>
                                            <h5 className="small-title text-color-0">We can foster the ideal cloud-based CMS frameworks that permit your workers and clients to associate with your business from any actual area. Our accomplished CMS engineers utilize the best web CMS stages to foster one of a kind arrangements that settle your business issues.</h5>
                                            <div data-animation-child className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05" data-animation="fade-anim">
                                            </div>
                                        </div>
                                    </div>{/* column end */}
                                    {/* column start */}
                                    <div className="six-columns top-padding-60">
                                        <a href="#" className=" portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link">
                                            <div className="portfolio-mb portfolio-content-bg-aman hover-img overlay-anim-box2 overlay-dark-bg-2" data-animation="overlay-anim2" style={{ backgroundImage: 'url(assets/images/backgrounds/web-design/cloudcms.webp)' }} />
                                        </a>
                                    </div>{/* column end */}
                                </div>{/* flex-container end */}
                            </div>{/* portfolio-content-inner end */}
                        </div>{/* portfolio-content end */}
                    </div>{/* bottom-padding-90 end */}
                    {/* bottom-padding-90 start */}
                    <div className="bottom-padding-90">
                        {/* portfolio-content start */}
                        <div className="portfolio-content flex-min-height-box">
                            {/* portfolio-content-inner start */}
                            <div className="portfolio-content-inner flex-min-height-inner">
                                {/* flex-container start */}
                                <div className="flex-container reverse container small">
                                    {/* column start */}
                                    <div className="six-columns top-padding-60">
                                        <a href="#" className="portfolio-content-bg-box pointer-large hover-box hidden-box animsition-link">
                                            <div className="portfolio-mb portfolio-content-bg-aman hover-img overlay-anim-box2 overlay-dark-bg-2 pset" data-animation="overlay-anim2" style={{ backgroundImage: 'url(assets/images/backgrounds/web-design/onsitecms.webp)' }} />
                                        </a>
                                    </div>{/* column end */}
                                    {/* column start */}
                                    <div data-animation-container className="six-columns">
                                        <div className="content-left-margin-40">
                                            <h3 className="title-style text-color-1">
                                                <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay01" data-animation="overlay-anim2">Onsite</span><br />
                                                <span data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 tr-delay02" data-animation="overlay-anim2">CMS Deployment</span><br />
                                            </h3>
                                            <h5 className="small-title text-color-0">We can likewise utilize an assortment of CMS apparatuses including data set administration programming to create nearby CMS arrangements. Utilizing programming arrangements that are housed in your office upgrade your security and permits your data to stay secure in a controlled space. Our accomplished designers are consistently prepared to send a total nearby CMS arrangement!</h5>
                                            <div data-animation-child className="arrow-btn-box top-margin-30 fade-anim-box tr-delay05" data-animation="fade-anim">
                                            </div>
                                        </div>
                                    </div>{/* column end */}
                                </div>{/* flex-container end */}
                            </div>{/* portfolio-content-inner end */}
                        </div>{/* portfolio-content end */}
                    </div>{/* bottom-padding-90 end */}
                    {/* bottom-padding-90 start */}
                    <div>
                    </div>{/* bottom-padding-90 end */}
                </section>{/* section end */}
                {/* <Footer/> */}

            </>
        )
    }
}
