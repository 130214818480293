import React ,{Component} from 'react'
class TradeDay extends Component{

  constructor (props){
super (props)
  }
render(){
  return(
    <>
<div>
  <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
  {/*?php include("head.php") ?*/}
  <link rel="stylesheet" href="assets/css/carousel.css" />
  <link rel="stylesheet" href="assets/css/laptop-carousel.css" />
  {/*?php include("nav.php") ?*/}
  {/* animsition-overlay start */}
  <main className="animsition-overlay" data-animsition-overlay={true} id="up">
    {/* page-head start */}
    {/*?php include("websitesapp-banner.php") ?*/}
    {/* page-head end */}
    <div id="down" className="light-bg-1 top-bottom-padding-120" data-midnight="black">
      <div className="container text-center">
        <h1 className="large-title-bold">Trade Day</h1>
        <h1>Trade Day is one of the popular cryptocurrency exchanges</h1>
      </div>
    </div>
    {/* flex-min-height-box start */}
    <div className="dark-bg-1 flex-min-height-box">
      {/* flex-min-height-inner start */}
      <div className="flex-min-height-inner">
        {/* flex-container start */}
        <div className="flex-container container project-content top-padding-60 bottom-padding-60">
          {/* column start */}
          <div className="six-columns">
            <div className="content-right-margin-20">
              <h2 className="text-color-4 title-style"><strong>Description:</strong></h2>
              <p className="p-style-medium text-color-3" style={{letterSpacing: 1}}>
                Trade Day is one of the popular cryptocurrency exchanges that offers trading in more than 45 virtual coins including Bitcoin (BTC), Ethereum (ETH), Litecoin (LTC)
              </p>
              <ul className="list-dots">
                <li>
                  <p data-animation-child className="p-style-medium text-color-3 fade-anim-box tr-delay03 animated fade-anim" data-animation="fade-anim">Trade Day is an exchange where users can trade crypto currencies. They support many of the most commonly traded crypto currencies.</p>
                </li>
                <li>
                  <p data-animation-child className="p-style-medium text-color-3 fade-anim-box tr-delay04 animated fade-anim" data-animation="fade-anim">Trade Day provides a crypto wallet for its traders, where they can store their electronic funds.</p>
                </li>
                <li>
                  <p data-animation-child className="p-style-medium text-color-3 fade-anim-box tr-delay04 animated fade-anim" data-animation="fade-anim">The exchange also has supporting programs that help traders make investment decisions.</p>
                </li>
              </ul>
              <div className="top-margin-30">
                <p className="p-style-medium text-color-3" style={{marginBottom: 20}}><h4 className="text-color-4">Technology Stack APIs:</h4> NodeJS</p>
                <p className="p-style-medium text-color-3" style={{marginBottom: 20}}><h4 className="text-color-4">Admin Panel:</h4> NodeJS, HTML/Jquery</p>
                <p className="p-style-medium text-color-3" style={{marginBottom: 20}}><h4 className="text-color-4">Database:</h4> MySQl</p>
                <p className="p-style-medium text-color-3" style={{marginBottom: 20}}><h4 className="text-color-4">Category:</h4> Online trading exchange of Cryptocurrencies</p>
              </div>
            </div>
          </div>{/* column end */}
          {/* column start */}
          <div className="six-columns">
            <div className="content-left-margin-20">
              <div className="box1">
                <img src="assets/images/laptop-carousel/laptop2.webp" alt style={{width: '100% !important'}} />
                <div className="slider">
                  <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                      <div className="carousel-item" type="button" data-toggle="modal" data-target="#exampleModalCenter">
                        <img className="d-block w-100 i1" src="assets/images/project/trade_day/1.webp" alt="First slide" />
                      </div>
                      <div className="carousel-item" type="button" data-toggle="modal" data-target="#exampleModalCenter">
                        <img className="d-block w-100 i1" src="assets/images/project/trade_day/2.webp" alt="Second slide" />
                      </div>
                      <div className="carousel-item active" type="button" data-toggle="modal" data-target="#exampleModalCenter">
                        <img className="d-block w-100 i1" src="assets/images/project/trade_day/3.webp" alt="Third slide" />
                      </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden={true} />
                      <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden={true} />
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>{/* column end */}
        </div>{/* flex-container end */}
      </div>{/* flex-min-height-inner end */}
    </div>{/* flex-min-height-box end */}
    <div style={{position: 'relative'}}>
      <img src="assets/images/backgrounds/kevin-bhagat-zNRITe8NPqY-unsplash.webp" alt="title" />
      <div className="bg-overlay" />
    </div>
    <div className="text-center top-bottom-padding-120 red-bg" data-midnight="black">
      <a href="JeepsApp" cJassName="pointer-large animsition-link overlay-btn-box">
        <span className="overlay-btn" data-text="Next project">Next project</span>
      </a>
    </div>
  </main>{/* animsition-overlay end */}
  {/*?php include("footer.php") ?*/}
</div>
</>
)
}
}
export default TradeDay