import React from 'react'
export default function ClientLogo() {
    return (
        <section className="dark-bg-2">
  {/* container start */}
  <div className="flex-container container top-bottom-padding-120">
    {/* medium-title start */}
    <h2 data-animation-container className="medium-title">
      <span data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="Our Clients">Our Clients</span><br />
      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Our Confidence is Powered By">Our Confidence is Powered By</span><br />
      <span data-animation-child className="title-fill tr-delay02" data-animation="title-fill-anim" data-text />
    </h2>{/* medium-title end */}
    {/* client-list start */}
    <ul className="client-list d-flex-wrap top-padding-60">
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/360.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/3602.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/Americren.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/Americren2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/Aquila.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/Aquila2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/betHoney.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/betHoney2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/burgerism.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/burgerism2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/Career.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/Career2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/chatit.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/chatit2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/Cornic.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/Cornic2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/Delish.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/Delish2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/Denal.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/Denal2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/draftingexpert.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/draftingexpert2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/dodelivery.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/dodelivery2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/funbiz.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/funbiz2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/GoDiva-PNG2.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/GoDiva-PNG.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/InflucenaZone.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/InflucenaZone2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/JKS.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/JKS2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/KL.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/KL2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/leaf.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/leaf2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/Lernovate.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/Lernovate2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/logodesigning.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/logodesigning2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/mitada.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/mitada2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/Plant.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/Plant2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/skilledwritng.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/skilledwritng2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/spring.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/spring2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/Stately.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/Stately2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/stockiest.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/stockiest2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/SUDS.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/SUDS2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/Tabdeeli.webp" alt="Brand" className="hover-opac-img" />
             <img src="assets/images/clients/Tabdeeli2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/techwaysolution.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/techwaysolution2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/THDS.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/THDS2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/tradeday.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/tradeday2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/walkers.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/walkers2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>

<li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/5th gear marketing.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/5th gear marketing2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/benquet.webp" alt="Brand" className="hover-opac-img" />
             <img src="assets/images/clients/benquet2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/bidpoint.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/bidpoint2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/bookkeeping.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/bookkeeping2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/digitallearning.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/digitallearning2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/e2 immigration.webp" alt="Brand" className="hover-opac-img" />
             <img src="assets/images/clients/e2 immigration2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/Emech.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/Emech2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/Influcena Zone.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/Influcena Zone2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/justright.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/justright2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/kkf.webp" alt="Brand" className="hover-opac-img" />
             <img src="assets/images/clients/kkf2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/mobileapp.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/mobileapp2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/quantilytics.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/quantilytics2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/Research proposal.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/Research proposal2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/theshoppix.webp" alt="Brand" className="hover-opac-img" />
             <img src="assets/images/clients/theshoppix2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/virtualtriage.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/virtualtriage2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/walkers.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/walkers2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/Webdevelopment.webp" alt="Brand" className="hover-opac-img" />
            <img src="assets/images/clients/Webdevelopment2.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
      <li>
        <a href="javascript:void(0)" className="pointer-large d-block">
          <div className="brand-box">
            <img src="assets/images/clients/whiteboard2.webp" alt="Brand" className="hover-opac-img" />
             <img src="assets/images/clients/whiteboard.webp" alt="Brand" className="opac-img" />
          </div>
        </a>
      </li>
     
      <li className="empty-spot-box">
        <a data-animation-container href="javascript:void(0)" className="pointer-large p-style-bold-up empty-spot d-block">
          <span data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="This spot">This spot</span>
          <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Awaits">Awaits</span>
          <span data-animation-child className="title-fill tr-delay02" data-animation="title-fill-anim" data-text="You">You</span>
        </a>
      </li>
       




    </ul>{/* client-list end */}
  </div>{/* container end */}
</section>

    )
}
