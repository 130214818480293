import React, { useState } from 'react';
import axios from "axios";
const MobileAppDevelopment = () => {

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phoneno, setPhoneNo] = useState();
  const [comment, setComment] = useState();


  const handleChange = (event) => {
    setComment(event.target.value);
  };

  const Submitted = () => {
    if (name == "" || email == "" ||phoneno == "") {
    alert("Fill up the Form please!");
    } else {
    let formData = new FormData(); //formdata object

    formData.append("name", name); //append the values with key, value pair
    formData.append("email", email);
    formData.append("phone_no", phoneno);
    formData.append("comment", comment);

    axios
      .post("https://hnhbackend.hnhtechsolutions.com/api/mobile", formData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((res) => {
        console.log("res", res);

      });
      setName("")
      setEmail("")
      setPhoneNo("")
      setComment("")
    }
  };

  return (
    <>
      <div>
        <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
        {/* animsition-overlay start */}
        <main className="animsition-overlay" data-animsition-overlay={true} id="up">
          {/* page-head start */}
          <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
            {/* page-head-bg */}
            <div className="page-head-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/kaitlyn-baker-vZJdYl5JVXY-unsplash.webp)' }} />
            {/* flex-min-height-inner start */}
            <div className="flex-min-height-inner">
              {/* container start */}
              <div className="container top-bottom-padding-120">
                <h2 className="overlay-loading2 medium-title red-color">Mobile App</h2>
                <h3 className="large-title-bold text-color-4">
                  <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">DEVELOPMENT</span><br />
                </h3>
                <p className="d-flex-wrap top-margin-20 text-color-4">
                  <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
                  <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
                  <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
                </p>
              </div>{/* container end */}
            </div>{/* flex-min-height-inner end */}
            {/* scroll-btn start */}
            <a href="#down" className="scroll-btn pointer-large">
              <div className="scroll-arrow-box">
                <span className="scroll-arrow" />
              </div>
              <div className="scroll-btn-flip-box">
                <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
              </div>
            </a>{/* scroll-btn end */}
          </section>{/* page-head end */}
          {/* flex-min-height-box start */}
          {/* section start */}
          <section id="down" className="light-bg-1" data-midnight="black">
            {/* container start */}
            <div className="container top-padding-120">
              {/* text-center start */}
              <div data-animation-container className="text-center">
                <h2 data-animation-child className="large-title text-height-10 text-color-1 overlay-anim-box2" data-animation="overlay-anim2">Mobile Apps Design and Development
                  Around Your Unique Needs...</h2><br />
                <p data-animation-child className="fade-anim-box tr-delay02 text-color-1 xsmall-title-oswald top-margin-5" data-animation="fade-anim">For clients that want custom mobile app development solution, hnhtechsolutions offers unique, compatible, and fully capable solutions. We prepare apps using the top development tools, like Android Studio, X Code, and others to ensure that the final solution fully conforms to your app requirement specification. Our mobile app development services have a wide spectrum and cover all mobile-based platforms.</p>
              </div>{/* text-center end */}
              {/* flex-container start */}
              <div className="flex-container response-999 top-padding-60">
                {/* column start */}
                <div className="four-columns bottom-padding-60">
                  <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
                    <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                      <img className="hover-img" src="assets/images/backgrounds/and.webp" alt="Digital products" style={{ width: 200 }} />
                    </div>
                    <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                      <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">ANDROID</h3><br />
                      <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">A custom Android App is powerful and can tackle all your problems. Our team can develop a tailor-made Android app for you which is efficient, runs fast, and is compatible with a variety of devices with the Android operating system.</p>
                      <br />
                    </div>
                  </a>
                </div>
                {/* column end */}
                {/* column start */}
                <div className="four-columns bottom-padding-60">
                  <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
                    <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                      <img className="hover-img" src="assets/images/backgrounds/apple.webp" alt="Digital products" style={{ width: 200 }} />
                    </div>
                    <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                      <h3 data-animation-child className=" text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">iOS</h3><br />
                      <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">iOS is a strong mobile platform. Let us create the ideal iOS app for you that will deliver extraordinary performance, all the while ensuring that the users enjoy an awesome UI/UX.</p>
                      <br />
                    </div>
                  </a>
                </div>
                {/* column end */}
                {/* column start */}
                <div className="four-columns bottom-padding-60">
                  <a data-animation-container href="#" className="h-100 content-right-margin-20 hover-box pointer-large d-block light-bg-2">
                    <div data-animation-child className="overlay-anim-box2 overlay-dark-bg-2 expertise-img-box text-center top-bottom-padding-30" data-animation="overlay-anim2">
                      <img className="hover-img" src="assets/images/backgrounds/all-aa.webp" alt="Digital products" style={{ width: 200 }} />
                    </div>
                    <div className="expertise content-padding-l-r-20 content-padding-bottom-20 text-center">
                      <h3 data-animation-child className="text-color-1 hover-content fade-anim-box tr-delay01" data-animation="fade-anim">CROSS-PLATFORM</h3><br />
                      <p data-animation-child className="small-title text-color-1 hover-content fade-anim-box tr-delay02 top-margin-30" data-animation="fade-anim">If you are looking to lower your development costs, use our cross-platform app development services to prepare hybrid apps that work smartly on all mobile platforms.</p>
                      <br />
                    </div>
                  </a>
                </div>
                {/* column end */}
                <div className="twelve-columns text-center top-aman">
                  <img src="./assets/images/backgrounds/AND+APPle%20Mob.webp" style={{ margin: 'auto', width: '70%' }} alt />
                  <p className="small-title">Not sure which platform you like? Let our experts understand your app idea and then suggest the ideal path to a successfully developed custom app. We ensure that you can achieve the best customized solution with the help from our top industry experts.</p>
                </div>
              </div>{/* flex-container end */}
            </div>{/* container end */}
          </section>{/* section end */}
          <section className="dark-bg-1">
            <div className="top-bottom-padding-30 text-center">
              <h2 data-animation-child className="medium-title text-color-4">TURNING COMPLEX IDEAS INTO</h2>
              <br />
              <h3 data-animation-child className="large-title-bold text-color-4 ">Simple, Graceful &amp; Functional Mobile App </h3>
              <h3 data-animation-child className="large-title-bold text-color-4 spaceaman">Solutions</h3><br />
            </div>
            <div className="container small bottom-padding-120">
              <div className="comments-form-box">
                <form className="comments-form">
                  <div className="flex-container">
                    <div className="four-columns">
                      <input type="text" className="comment-form-control pointer-small" onChange={e => setName(e.target.value)} placeholder="Your Name" />
                    </div>
                    <div className="four-columns">
                      <input type="email" className="comment-form-control pointer-small" onChange={e => setEmail(e.target.value)} placeholder="Your Email" />
                    </div>
                    <div className="four-columns">
                      <input type="number" className="comment-form-control pointer-small" onChange={e => setPhoneNo(e.target.value)} placeholder="Phone" />
                    </div>
                    <div className="twelve-columns">
                      <textarea className="comment-form-control pointer-small"  onChange={handleChange} placeholder="Comment" />
                    </div>
                  </div>
                  <button className="arrow-btn-box pointer-large" type='button' onClick={Submitted}>
                    <span className="arrow-btn">Submit Information</span>
                  </button>
                </form>
              </div>{/* comments-form-box end */}
            </div>
          </section>
        </main>{/* animsition-overlay end */}
        {/* footer start */}
        {/*?php include("footer.php") ?*/}
      </div>
    </>);
}
export default MobileAppDevelopment