import React, { Component } from 'react'
import ClientLogo from '../component/ClientLogo'
import Footer from '../component/Footer'

export default class ContentWriting extends Component {
  render() {
    return (
      <>
        {/* page-head start */}
        <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
          {/* page-head-bg */}
          <div className="page-head-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/content-w.jpg)' }} />
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* container start */}
            <div className="mrgn-btm container top-bottom-padding-120">
              <h2 className="overlay-loading2 medium-title red-color">CONTENT</h2>
              <h3 className="large-title-bold text-color-4">
                <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">WRITING</span><br />
              </h3>
              <p className="d-flex-wrap top-margin-20 text-color-4">
                <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
              </p>
            </div>{/* container end */}
          </div>{/* flex-min-height-inner end */}
          {/* scroll-btn start */}
          <a href="#down" className="scroll-btn pointer-large">
            <div className="scroll-arrow-box">
              <span className="scroll-arrow" />
            </div>
            <div className="scroll-btn-flip-box">
              <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
            </div>
          </a>{/* scroll-btn end */}
        </section>{/* page-head end */}
        {/* flex-min-height-box start */}
        {/* flex-min-height-box start */}
        <section id="down" className="dark-bg-1 flex-min-height-box">
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* container start */}
            <div className="container small top-bottom-padding-120">
              {/* flex-container start */}
              <div data-animation-container className="flex-container">
                {/* column start */}
                <div className="twelve-columns text-center">
                  <h2 className="large-title text-height-12">
                    <span data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="Content Writing  Website">Content Writing  Website</span><br />
                    <div className="content-right-margin-20">
                      <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">Kindly read the following terms &amp; conditions thoroughly before using our services. By utilizing the site as a part of any way, including, however not constrained to, browsing, or engaging with the site or sharing information or different materials to the site, you consent to be bound by these Terms &amp; Conditions.</p>
                    </div>
                  </h2>
                </div>{/* column end */}
                {/* column start */}
                <div className='three-columns top-padding-120 left-padding-60'>
                  <div className='text-center'>
                    <h1 className="f-ebold theme-blue" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text=" Property Rights">  Property Rights</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      The Website and material, content and software is the property of Qunantilytics and are under copyright.
                    </p>
                  </div>
                </div>
                <div className='three-columns top-padding-120 left-padding-60'>
                  <div className='text-center'>
                    <h1 className="large-title text-height-20" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text=" Links to Other Sites">  Links to Other Sites</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      Any links found on this website other than websites that are associated with Qunantilytics, may not be controlled by us and we take no responsibility for those websites. We request you to read their terms before engaging with such websites.
                    </p>
                  </div>
                </div>
                <div className='three-columns top-padding-120 left-padding-60'>
                  <div className='text-center'>
                    <h1 className="large-title text-height-20" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text=" Changes to this Agreement">  Changes to this Agreement</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      Qunantilytics reserves the right to make any changes in the terms &amp; conditions, which will be updated on the website.
                    </p>
                  </div>
                </div>
                <div className='six-columns top-padding-90 left-padding-60'>
                  <div className='text-center'>
                    <h1 className="f-ebold theme-blue" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text=" Communication"> Communication</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      We can be contacted through our Chat Feature, Contact Form, and General Information Form. Quantilytics ensures a response within 24-48 hours, usually through phone or email (in which the domain name will be consistent info@hnhtechsolutions.com).
                    </p>
                  </div>
                </div>

                <div className='six-columns top-padding-90 left-padding-60'>
                  <div className='text-center'>
                    <h1 className="large-title text-height-20" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Refund Policy">Refund Policy</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      Each Refund Case will be subject to audit and a percentage of the total amount will be applicable NOT 100%.
                      – Customer is eligible for a refund if the service provided does not meet service standards/requirements.
                      – If the mutually agreed deadline has not been met.
                      – Customer is eligible for a Refund within 7 days of the order delivery date.
                    </p>
                  </div>
                </div>

                <div className='twovelve-columns top-padding-90 '>
                  <div className='text-center'>
                    <h1 className="large-title text-height-20" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Our Guarantee  ">Our Guarantee </span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      In the event that you are unsatisfied with our services you are required to contact us ASAP and present your concerns to the chief editor. If your concerns are justified, we will continue working and make sure you are satisfied. Only if your case in point is valid, if not, minor charges may apply.
                      It would be ideal if you audit this Agreement occasionally for changes. On the off chance that you don’t consent to any statement of this Agreement or any progressions to this Agreement, don’t utilize, or keep visiting the site or instantly stop using the website. In the event that you have any
                      inquiries concerning this Agreement, please visit our contact us page and send us your concerns.
                    </p>
                  </div>
                </div>
                {/*end*/}
                <div className='six-columns top-padding-120'>
                  <div className='text-center'>
                    <h1 className="large-title text-height-20" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Information Collected">Information Collected</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      content writing Writing collects two kinds of information
                      a) Information provided by user (name, email, etc) – this information is used for contact purposes.
                      b) Technical information such as IP, browser used etc – this information is used to improve user experience.
                    </p>
                  </div>
                </div>
                <div className='six-columns top-padding-120-aman'>
                  <div className='text-center'>
                    <h1 className="f-ebold theme-blue" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Information Used">Information Used</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      The information you provide is used for contact purposes ONLY. Through phone or email.
                    </p>
                  </div>
                </div>
                <div className='six-columns top-padding-120 amanpadding'>
                  <div className='text-center'>
                    <h1 className="f-ebold theme-blue" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Data Protection">Data Protection</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      We actualize an assortment of efforts to establish safety to keep up the security of your personal information. All data is encrypted and is under the control of Qunantilytics. Your information is directly sent to our secured servers.
                    </p>
                  </div>
                </div>
                <div className='six-columns  top-padding-120 amanpadding'>
                  <div className='text-center'>
                    <h1 className="f-ebold theme-blue" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="Changes to Policy"> Changes to Policy</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      Qunantilytics reserves the rights to make modifications, to this policy at any future date as decided by Qunantilytics. Updated policies in future may be found on this same page and stakeholders/users will be informed through email. The last update had taken place on 3rd January, 2016.
                    </p>
                  </div>
                </div>
                <div className='twovelve-columns  top-padding-120'>
                  <div className='text-center'>
                    <h1 className="f-ebold theme-blue" id="linerText">
                      <span data-animation-child className="title-fill tr-delay01" data-animation="title-fill-anim" data-text="COPPA (Children’s Online Privacy Protection Act)">COPPA (Children’s Online Privacy Protection Act)</span>
                    </h1>
                    <p data-animation-child className="p-style-medium text-color-5 fade-anim-box tr-delay02" data-animation="fade-anim">
                      Content Writing and its services are directed only to individuals above the age of 13. Any information received by a minor, by default, is removed from our systems.
                    </p>
                  </div>
                </div>
                {/* aman end */}
              </div>{/* flex-container end */}
            </div>{/* container end */}
          </div>{/* flex-min-height-inner end */}
        </section>{/* flex-min-height-box end */}
        <ClientLogo></ClientLogo>
        {/* <Footer/> */}
      </>

    )
  }
}
