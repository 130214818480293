import React, { Component } from 'react'

 class Sweattfury extends Component {
     constructor(props){
         super(props)
         
     }
    render() {
        return (
            <>            


<div>
  {/* <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
  {/*?php include("head.php") ?*/}
  <link rel="stylesheet" href="assets/css/carousel.css" />
  <link rel="stylesheet" href="assets/css/laptop-carousel.css" />
  {/*?php include("nav.php") ?*/}
  {/* animsition-overlay start */}
  <main className="animsition-overlay" data-animsition-overlay={true} id="up">
    {/* page-head start */}
    {/*?php include("websitesapp-banner.php") ?*/}
    {/* page-head end */}
    <div id="down" className="light-bg-1 top-bottom-padding-120" data-midnight="black">
      <div className="container text-center">
        <h1 className="large-title-bold">SWEAT FURRY</h1>
        <h1>Sweat Furry Is Online Fitness App Designed For Real People</h1>
      </div>
    </div>
    {/* flex-min-height-box start */}
    <div className="dark-bg-1 flex-min-height-box">
      {/* flex-min-height-inner2 start */}
      <div className="flex-min-height-inner2">
        {/* flex-container start */}
        <div className="flex-container container project-content top-padding-60 bottom-padding-60">
          {/* column start */}
          <div className="six-columns">
            <div className="content-right-margin-20">
              <h2 className="text-color-4 title-style"><strong>Description:</strong></h2>
              <p className="p-style-medium text-color-3" style={{letterSpacing: 1}}>
              Sweatfury is online fitness app designed for real people—whether you’re a busy 
mom in need of a quick morning energy boost, or a traveler looking to relax with a 
yoga nidra before sleep. Work up a sweat with an advanced sweatfury flow, or brush 
up on the basics in our accessible beginner classes </p>
            </div>
          </div>
          {/* column start */}
          <div className="six-columns">
            <div className="content-left-margin-20">
              <div className="box1">
                <img src="assets/images/laptop-carousel/laptop2.webp" alt style={{width: '100% !important'}} />
                <div className="slider">
                  <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                      <div className="carousel-item" type="button" data-toggle="modal" data-target="#exampleModalCenter">
                        <img className="d-block w-100 i1" src="assets/images/project/sweat/1.jpg" alt="First slide" />
                      </div>
                      <div className="carousel-item" type="button" data-toggle="modal" data-target="#exampleModalCenter">
                        <img className="d-block w-100 i1" src="assets/images/project/sweat/2.jpg" alt="Second slide" />
                      </div>
                      <div className="carousel-item active carousel-item-left" type="button" data-toggle="modal" data-target="#exampleModalCenter">
                        <img className="d-block w-100 i1" src="assets/images/project/sweat/3.jpg" alt="Third slide" />
                      </div>
                      <div className="carousel-item carousel-item-next carousel-item-left" type="button" data-toggle="modal" data-target="#exampleModalCenter">
                        <img className="d-block w-100 i1" src="assets/images/project/sweat/1.jpg" alt="Third slide" />
                      </div>
                    </div>
                    <a className="carousel-control-prev pointer-small" href="#carouselExampleControls" role="button" data-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden={true} />
                      <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next pointer-small" href="#carouselExampleControls" role="button" data-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden={true} />
                      <span className="sr-only">Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>{/* column end */}
        </div>{/* flex-container end */}
      </div>{/* flex-min-height-inner2 end */}
    </div>{/* flex-min-height-box end */}
     {/* ARSALAN*/}
     <div className="dark-bg-1 flex-min-height-box">
              {/* flex-min-height-inner2 start */}
              <div className="flex-min-height-inner2">
                {/* flex-container start */}
                <div className="flex-container container project-content top-padding-60 bottom-padding-60">
                  {/* column start */}
                  <div className="four-columns">
                    <div>
                      <p className="p-style-medium text-color-3" style={{ marginBottom: 20 }}><h4 className="text-color-4">Technology Stack App:</h4> React Native</p>
                    </div>
                  </div>
                  {/* column start */}
                  <div className="four-columns">
                    <div>
                      <p className="p-style-medium text-color-3" style={{ marginBottom: 20 }}><h4 className="text-color-4">Technology Stack APIs:</h4> NodeJS, ExpressJS</p>
                    </div>
                  </div>{/* column end */}
                  {/* column start */}
                  <div className="four-columns">
                    <div>
                      <p className="p-style-medium text-color-3" style={{ marginBottom: 20 }}><h4 className="text-color-4">Admin Panel:</h4> NodeJS, HTML/Jquery</p>
                    </div>
                  </div>{/* column end */}
                  {/* column start */}
                  <div className="four-columns">
                    <div>
                      <p className="p-style-medium text-color-3" style={{ marginBottom: 20 }}><h4 className="text-color-4">Database:</h4> MongoDB</p>
                    </div>
                  </div>{/* column end */}
                  {/* column start */}
                  <div className="four-columns">
                    <div>
                      <p className="p-style-medium text-color-3" style={{ marginBottom: 20 }}><h4 className="text-color-4">Third Party: </h4> Google Place Api, Google Map Api, Geolocation</p>
                    </div>
                  </div>{/* column end */}
                  {/* column start */}
                  <div className="four-columns">
                    <div>
                      <p className="p-style-medium text-color-3" style={{ marginBottom: 20 }}><h4 className="text-color-4">Category:</h4> Plant Management and information sharing network</p>
                    </div>
                  </div>{/* column end */}
                  {/* column start */}
                  <div className="four-columns">
                    <div>
                    <p className="p-style-medium text-color-3" style={{ marginBottom: 20 }}><h4 className="text-color-4">link:</h4>
                          <a href="http://sweatfury.hnhtechsolutions.com/" target="_blank" className="links">http://sweatfury.hnhtechsolutions.com/</a>
                        </p>
                    </div>
                  </div>{/* column end */}
                </div>{/* flex-container end */}
              </div>{/* flex-min-height-inner2 end */}
            </div>{/* flex-min-height-box end */}
    <div className="text-center top-bottom-padding-120 red-bg" data-midnight="black">

      <a href="/Hidraulicos" className="pointer-large animsition-link overlay-btn-box">
        <span className="overlay-btn" data-text="Next project">Next project</span>
      </a>
    </div>
  </main>{/* animsition-overlay end */}
  {/*?php include("footer.php") ?*/}
</div>
 </>
            
 )
 }
 }
 export default Sweattfury