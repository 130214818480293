import { extend } from "jquery";
import React, { Component } from "react";
class CompanyAbout extends Component{
constructor(props){
    super(props)
}
render(){
    return(
        <>
        <div>
  <meta httpEquiv="content-type" content="text/html;charset=UTF-8" />
  {/*?php include("head.php") ?*/}
  <style dangerouslySetInnerHTML={{__html: "\n    .mid-text {\n        transform: rotate(270deg);\n        transform-origin: bottom center 0;\n        margin-top: 50px;\n        font-size: 140px;\n        color: #fff;\n        font-weight: 900;\n        font-family: 'Montserrat', sans-serif;\n        line-height: 0.5;\n        text-align: center;\n        /* padding-bottom: 150px; */\n        margin-bottom: 120px;\n    }\n\n    .company-values {\n        background: linear-gradient(to right, #00252f 50%, #f5f5f5 50%);\n    }\n    @media (max-width: 950px){\n        #shiva {\n            font-size: 100px !important;\n        }\n    }\n    @media (min-width: 768px) and (max-width: 800px){\n        #shiva {\n            font-size: 80px !important;\n        }\n    }\n    .counter2\n    {\n        position: relative;\n        background: #00a8e8e0;\n        text-align: center;\n        -moz-border-radius: 50px;\n        -webkit-border-radius: 50%;\n        border-radius: 50%;\n        color: #ffffff;\n        display: inline-block;\n        font-size: 54px;\n        font-weight: 900;\n        width: 250px;\n        height: 250px;\n    }\n    .counter1\n    {\n        text-align: center;\n        -moz-border-radius: 50px;\n        -webkit-border-radius: 50px;\n        color: #00A8E8;\n        display: inline-block;\n        font-size: 120px;\n        font-weight: 900;\n    }\n    .counter1 p{\n        text-align:center;\n        color: white;\n        font-size: 16px;\n        margin-top: 10px;\n        font-weight: 400;\n    }\n    .counter2 p{\n        text-align:center;\n        color: white;\n        font-size: 16px;\n        margin-top: 10px;\n        font-weight: 400;\n    }\n\n\n" }} />
  {/*?php include("nav.php") ?*/}
  {/* animsition-overlay start */}
  <main className="animsition-overlay" data-animsition-overlay={true} id="up">

    {/* page-head start */}
    <section id="up" className="page-head flex-min-height-box dark-bg-2">
      {/* page-head-bg */}
      <div className="page-head-bg overlay-loading2" style={{backgroundImage: 'url(assets/images/backgrounds/Campany-profile.jpg)'}} />
      {/* flex-min-height-inner start */}
      <div className="flex-min-height-inner">
        {/* flex-container start */}
        <div className="container top-bottom-padding-120 flex-container response-999">
          {/* column start */}
          <div className="six-columns six-offset">
            <div className="content-left-margin-40">
              <h2 className="overlay-loading2 tr-delay03 medium-title red-color">Welcome Quantilytics</h2>
              <h3 className="large-title-bold text-color-4">
                <span className="overlay-loading2 overlay-light-bg-1 tr-delay04">Best Solutions</span><br />
                <span className="overlay-loading2 overlay-light-bg-1 tr-delay05">&amp; ideas for</span><br />
                <span className="overlay-loading2 overlay-light-bg-1 tr-delay06">You Business</span>
              </h3>
              <p className="d-flex-wrap top-margin-20 text-color-4">
                <span className="small-title-oswald text-height-20 fade-loading tr-delay07 top-margin-10">Creative team</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay08 top-margin-10">Innovation ideas</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay09 top-margin-10">Best services</span>
              </p>
            </div>
          </div>{/* column end */}
        </div>{/* flex-container end */}
      </div>{/* flex-min-height-inner end */}
      {/* scroll-btn start */}
      <a href="#down" className="scroll-btn pointer-large">
        <div className="scroll-arrow-box">
          <span className="scroll-arrow" />
        </div>
        <div className="scroll-btn-flip-box">
          <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
        </div>
      </a>{/* scroll-btn end */}
    </section>{/* page-head end */}
    {/* flex-min-height-box start */}
    <section id="down" className="dark-bg-1 flex-min-height-box about-page">
      {/* flex-min-height-inner start */}
      <div className="flex-min-height-inner">
        {/* container start */}
        <div className="container top-bottom-padding-60">
          {/* flex-container start */}
          <div data-animation-container className="flex-container">
            {/* column start */}
            <div className="four-columns">
              <div className="content-right-margin-20">
                <h2 data-animation-child className="title-style title-fill" data-animation="title-fill-anim" data-text="Our Mission">Our Mission</h2>
              </div>
            </div>
            <div className="eight-columns">
              <div className="content-left-margin-10">
                <p data-animation-child className="p-style-large text-color-5 fade-anim-box tr-delay01" data-animation="fade-anim">To offer reliable and innovative software-based business solutions that strategically help our clients’ build competitive advantage by leveraging cutting edge technology. </p>
              </div>
            </div>{/* column end */}
            {/* column start */}
            {/* column end */}
          </div>
          {/* flex-container end */}
        </div>{/* container end */}
      </div>{/* flex-min-height-inner end */}
    </section>{/* flex-min-height-box end */}
    <div className="text-center top-bottom-padding-120 red-bg" data-midnight="black">
      <a href="#" className>
        <span className="large-title">SHARE, ORGANIZE, &amp; COLLABORATE WITH JUST FEW MOUSE CLICKS</span>
      </a>
    </div>
    {/* section start */}
    <section className="dark-bg-2 company-values flex-min-height-box top-bottom-padding-120">
      {/* flex-container start */}
      <div className="flex-container container ">
        {/* column start */}
        <div className="four-columns bottom-padding-60">
          <div data-animation-container className="content-right-margin-20 team-title-box">
            <h2 data-animation-child className="overlay-anim-box2 red-color" data-animation="overlay-anim2">Motivation That Drives Us</h2>
            <p className="small-title-oswald text-color-4">
              <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1 tr-delay01" data-animation="overlay-anim2">One extra efforts in whatever we</span><br />
              <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1 tr-delay02" data-animation="overlay-anim2">undertake—separates good from</span><br />
              <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1 tr-delay03" data-animation="overlay-anim2">great. We understand that to get</span>
              <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1 tr-delay03" data-animation="overlay-anim2">what we never had, we must do</span>
              <span data-animation-child className="overlay-anim-box2 overlay-light-bg-1 tr-delay03" data-animation="overlay-anim2">what we have never done.</span>
            </p>
          </div>
        </div>{/* column end */}
        {/* column start */}
        <div className="two-columns">
          <div className="mid-text">About</div>
        </div>{/* column end */}
        {/* column start */}
        <div className="three-columns">
          <img src="./assets/images/backgrounds/company-process.webp" alt />
        </div>
      </div>{/* flex-container end */}
    </section>{/* section end */}
    <section className="dark-bg-2 flex-min-height-box top-bottom-padding-120">
      {/* flex-container start */}
      <div data-animation-container className="container small bottom-padding-60">
      <div className="text-center">
          <h2 data-animation-child className="large-title text-color-4 overlay-anim-box2" data-animation="overlay-anim2">OUR PROCESS</h2>
        </div>
        <div className="top-margin-20">
          <h3 data-animation-child className="small-title-oswald text-color-4 text-center text-height-20 fade-anim-box tr-delay01" data-animation="fade-anim">Hnh Uses The Best Principle Of Agile Software Management To Deliver The Ideal Software Applications To Our Clients. Our Development Process Starts From Smart Ideas That Our Customers Bring To Us For A Digital Transformation. We Then Generate The Specific Software Requirements And Assign An Experienced Team To Undertake Each Project.</h3>
          <h3 data-animation-child className="small-title-oswald text-color-4 text-center text-height-20 fade-anim-box tr-delay01" data-animation="fade-anim">Their Teamwork Ensures That The Software Project Remains On Course And Achieves The Intended Targets Within The Allotted Timelines. Our Experienced Professionals Deliver An Enhanced User Experience Which Allows Your Software To Achieve The Required Market Success. With Our Tested Process At Work, We Ensure That Our Customers Always Enjoy The Perfectly Produced Software Apps.</h3></div>
        <br/>
        <div className="text-center">
          <h2 data-animation-child className="large-title text-color-4 overlay-anim-box2" data-animation="overlay-anim2">Engagement Module</h2>
        </div>
        <div className="top-margin-30">
          <h3 data-animation-child className="small-title-oswald text-color-4 text-center text-height-20 fade-anim-box tr-delay01" data-animation="fade-anim">At HNH every client is special, to cater to your specific business needs and provide you the most efficient business solutions at affordable price we offer multiple, scalable, and flexible engagement models to meet your diverse needs. Our engagement models flexibility provide you the best value for your money by delivering projects on-time, within budget, and with promised Quantilytics&gt;
          </h3></div>
        <div className="flex-container top-margin-30">
          <div className="four-columns text-center">
            <div id="shiva" className="counter1 texr-center">
              <span className="count">150</span>%
              <p>Efficient Budget Allocation</p>
            </div>
          </div>
          <div className="four-columns text-center">
            <div id="shiva" className="counter1">
              <span className="count">4</span>x
              <p>Faster than the Market</p>
            </div>
          </div>
          <div className="four-columns text-center">
            <div id="shiva" className="counter1">
              <span className="count">1054</span>
              <p>Coding Years Saved</p>
            </div>
          </div>
        </div>
      </div>{/* flex-container end */}
    </section>{/* section end */}
    {/* light-bg-2 start */}
    <div className="light-bg-2 top-bottom-padding-120" data-midnight="black">
      {/* testimonials-slider start */}
      <div data-animation-container className="container small bottom-padding-60">
        <div className="bottom-margin-30 text-center">
          <h2 data-animation-child className="large-title text-color-0 overlay-anim-box2" data-animation="overlay-anim2">Our Experience</h2>
        </div>
        <div className="flex-container top-margin-30">
          <div className="four-columns text-center">
            <div id="shiva" className="counter2" style={{left: 90}}>
              <div style={{paddingTop: 65}}>
                <span className="count">18</span>+
                <p>Years Experience</p>
              </div>
            </div>
          </div>
          <div className="four-columns text-center">
            <div id="shiva" className="counter2">
              <div style={{paddingTop: 65}}>
                <span className="count">800</span>+
                <p>Project Delivered</p>
              </div>
            </div>
          </div>
          <div className="four-columns text-center">
            <div id="shiva" className="counter2" style={{right: 90}}>
              <div style={{paddingTop: 65}}>
                <span className="count">400</span>+
                <p>Satisfied Clients</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-container top-margin-30">
          <div className="four-columns margin-left-60 ">
          <h3 data-animation-child className="small-title-oswald text-color-0 text-left text-height-20 fade-anim-box tr-delay01" data-animation="fade-anim">We have an experience of over a decade and our  experienced software developers can take on any digital challenge. Not only can we take care of traditional mobile app development projects, but we can also work on novel technological concepts that may require an implementation of artificial intelligence. Our experience covers all the important industrial applications.</h3>
          </div>
          <div className="four-columns margin-left-70">
          <h3 data-animation-child className="small-title-oswald text-color-0 text-left text-height-20 fade-anim-box tr-delay01" data-animation="fade-anim">
              We can create apps that use digital payment systems comprising of Blockchain technology solutions. We have experience producing apps that employ AI for its functionality. We ensure that you can enjoy smart apps that provide the ideal IoT functionality for your personal and professional devices. With our digital development services, we continue to change various industries.
            </h3>
          </div>
          <div className="four-columns margin-left-80">
          <h3 data-animation-child className="small-title-oswald text-color-0  text-left text-height-20 fade-anim-box tr-delay01" data-animation="fade-anim">
              We are also aware of the latest industry trends and follow them to improve our software development services. Work with us if you want to develop an application that employs augment (AR) and virtual reality (VR). With HNH by your side, you can count on a timely delivery, efficient functionality, and constant improvements.
            </h3>
          </div>
        </div>
      </div>{/* flex-container end */}
    </div>{/* light-bg-2 end */}
  </main>{/* animsition-overlay end */}
  {/* footer start */}
  {/*?php include("footer.php") ?*/}
  {/* Mirrored from www.nurseitmukaytegin.com/envato-templates/Quantilytics/about.html by HTTrack Website Copier/3.x [XR&CO'2014], Tue, 03 Mar 2020 15:52:54 GMT */}
</div>

        
        
        
        </>
    )
}
}
export default CompanyAbout