import React, { Component } from 'react'
import ClientLogo from '../component/ClientLogo'

export default class Seo extends Component {
  render() {
    return (
      <>
        {/* page-head start */}
        <section id="up" className="page-head flex-min-height-box dark-bg-2 typewriter-home">
          {/* page-head-bg */}
          <div className="page-head-bg" style={{ backgroundImage: 'url(assets/images/backgrounds/seoimg.jpg)' }} />
          <div className="bg-overlay" />
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
          <div className="container top-bottom-padding-120">
              {/* <h2 className="overlay-loading2 medium-title red-color">WEB</h2> */}
              <h3 className="large-title-bold text-color-blue">
                <span className="overlay-loading2 overlay-light-bg-1 tr-delay01">SEO</span><br />
              </h3>
              <p className="d-flex-wrap top-margin-20 text-color-4">
                <span className="small-title-oswald text-height-20 fade-loading tr-delay04 top-margin-10">Creative team</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay05 top-margin-10">Innovation ideas</span>
                <span className="small-title-oswald text-height-20 fade-loading tr-delay06 top-margin-10">Best services</span>
              </p>
            </div> {/* container start */}
          </div>{/* flex-min-height-inner end */}
          {/* scroll-btn start */}
          <a href="#down" className="scroll-btn pointer-large">
            <div className="scroll-arrow-box">
              <span className="scroll-arrow" />
            </div>
            <div className="scroll-btn-flip-box">
              <span className="scroll-btn-flip" data-text="Scroll">Scroll</span>
            </div>
          </a>{/* scroll-btn end */}
        </section>{/* page-head end */}
        <section className="dark-bg-1" style={{ marginBottom: "10px" }}>
          {/* container start */}
          <div data-animation-container className="container bottom-padding-60 top-padding-60">
            <div className="text-center">
              <h2 data-animation-child className="medium-title text-color-4 overlay-anim-box2" data-animation="overlay-anim2">Leading SEO Company in UK</h2>
            </div>
            <div data-animation-container className="flex-container top-margin-30">
              <div className="twovelve-columns" style={{ margin: 'auto', textAlign: 'center' }}>
                <img src="./assets/images/backgrounds/seo.jpg" style={{ width: '400% !important',fontFamily:"Times New Roman, Times, serif",fontWeight:"300" }} /><br />
                {/* <h4 data-animation-child className=" small-title text-color-0 top-margin-10">Qualified Lead Generation for Your Business</h4> */}
              </div>
              {/* <div className="three-columns" style={{margin: 'auto', textAlign: 'center'}}>
          <img src="./assets/images/backgrounds/supermarket.webp" style={{width: '15% !important'}} /><br />
          <h4 data-animation-child className=" small-title text-color-0 top-margin-10">Growth Hacking Strategies to Boost Your Sales</h4>
        </div> */}
              {/* <div className="three-columns" style={{margin: 'auto', textAlign: 'center'}}>
          <img src="./assets/images/backgrounds/pin.webp" style={{width: '15% !important'}} /><br />
          <h4 data-animation-child className="small-title text-color-0 top-margin-10">Drive Targeted Traffic to Your Website with Effective Marketing</h4>
        </div> */}
              {/* <div className="three-columns" style={{margin: 'auto', textAlign: 'center'}}>
          <img src="./assets/images/backgrounds/graphic.webp" style={{width: '15% !important'}} /><br />
          <h4 data-animation-child className=" small-title text-color-0 top-margin-10">KPI Based SEO Services to Quantify Our Accomplishments</h4>
        </div> */}
            </div>
          </div>{/* container end */}
          {/* about-slider start */}
        </section>
        {/* flex-min-height-box start */}
        <section id="down" className="dark-bg-1 flex-min-height-box about-page" style={{marginBottom:"10px"}}>
          {/* flex-min-height-inner start */}
          <div className="flex-min-height-inner">
            {/* container start */}
            <div className="container small top-bottom-padding-120">

              {/* flex-container start */}
              <div data-animation-container className="flex-container">
                <div className="text-center" style={{ paddingBottom: "50px",paddingLeft:"480px",fontFamily:"Times New Roman, Times, serif" }}>
                  <h2 data-animation-container style={{fontFamily:"Times New Roman, Times, serif",fontWeight:"300" }}className="medium-title text-color-4 overlay-anim-box2" data-animation="overlay-anim2">OPTIMAIZING</h2>
                </div>
                {/* column start */}
                <img src="assets/images/backgrounds/seo-before-after.gif" alt="" />
              </div>{/* flex-container end */}
            </div>{/* container end */}
          </div>{/* flex-min-height-inner end */}
        </section>{/* flex-min-height-box end */}
        <ClientLogo></ClientLogo>
      </>

    )
  }
}
